import React, { useEffect, useState } from 'react'
import { Dialog } from 'material-ui'

import { SidebarNavigator } from 'components/Sidebar'

import { useAuth } from 'hooks/useAuth'
import { firebaseStorage, firestoreDatabase } from 'services/firebase'
import { Iugu } from 'services/iugu'

import './styles.scss'

export function RecipesExtract() {
    const { user } = useAuth()

    const [financialExtract, setFinancialExtract] = useState()

    useEffect(() => {
        firestoreDatabase.collection('store').doc(user.storeId).get().then(storeResponse => {
            const storeData = storeResponse.data()
            Iugu.getFinancialExtract(storeData.iugu.live_api_token, 2022).then(resp => {
                console.log(resp)
                setFinancialExtract(resp)
            })
        })
    }, [user.storeId])


    function sanitizeDescription(description) {
        if (!description) return ''

        if (description === 'Pedido de saque') return description

        // "Pagamento recebido: Invoice #FDD6468AA3A04B3F91F26AF3CBC75917"
        // retorna: Pagamento recebido
        if (description.includes(': Invoice #')) return description.split(': Invoice #')[0]
        
        // "description": "Tarifas - Estorno - Fatura #3413B6475EC14FED964AA51BFC2A3C9F",
        // retorna: Tarifas - Estorno
        if (description.includes('Fatura #')) return 'Tarifas - Estorno'

    }

    return (
        <div id="RecipesExtract">
            <div id="financialTable" >

                <header>
                    <h4>Extrato financeiro</h4>
                </header>

                <div className="row tableHeader">
                    <p>Id do Pedido</p>
                    <p>Descrição</p>
                    <p>Comprador</p>
                    <p>Data</p>
                    <p>Valor</p>
                    <p>Saldo</p>
                    <p>Tipo</p>
                </div>

                {financialExtract?.transactions.map(data => (
                    <div className="row" key={data.description}>
                        <p>{data.reference.substring(0, 5)}</p>
                        <p>{sanitizeDescription(data.description)}</p>
                        <p>{data.payer_name ? data.payer_name.split(' ')[0] + ' ' + data.payer_name.split(' ')[2][0] : '-'}</p>
                        <p>{data.entry_date}</p>
                        <p>{data.amount.includes('-') && <div className="redCircle"></div>}{data.amount}</p>
                        <p>{data.balance}</p>
                        <p>{data.type === 'debit' ? 'Débito' : 'Crédito'}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
