import React, { useState, useEffect } from 'react'

import { firestoreDatabase } from 'services/firebase'
import { useAuth } from 'hooks/useAuth'
import {Iugu} from 'services/iugu'

import {MetricCard} from './MetricCard'
import {FinancialTable} from './financialTable'

import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin'
import { CardContainer } from 'Layouts/CardContainer'
import { HeaderAdmin } from 'components/HeaderAdmin'

import './styles.scss'

export function AdminPanel() {
	const { user } = useAuth()

    const [datesOptions, setDatesOptions] = useState([])
    const [selectedDate, setSelectedDate] = useState()

    const [metrics, setMetrics] = useState({
        totalAccount: 0,
        totalPups: 0,
        totalPost: 0,
        totalLikes: 0,
        totalComments: 0,
        totalStartPurchase: 0,
        totalCompletedPurchase: 0,
        totalRefunds: 0,
        totalPurchasesCanceledByManager: 0,
        totalPurchasesExpired: 0,
    })

    useEffect(() => {
        
        async function getMetrics() {
            const metricsNames = [
                'totalAccount',
                'totalPups',
                'totalPost',
                'totalLikes',
                'totalComments',
                'totalStartPurchase',
                'totalCompletedPurchase',
                'totalRefunds',
                'totalPurchasesCanceledByManager',
                'totalPurchasesExpired',
            ]
    
            const day = new Date(Number(selectedDate)).getDate().toString()
    
            const metricsPromises = metricsNames.map((metricName) => firestoreDatabase.collection("metrics").doc(day).collection(metricName).get());
            
            const responses = await Promise.all(metricsPromises);
        
            const metrics = responses.map((response, index) => {
                let count = 0;
                
                response.forEach((doc) => {
                    count = count + doc.data().count;
                });
        
                const metricName = metricsNames[index]
    
                return {[metricName]: count};
            });
            
            const metricsObj = metrics.reduce((acc, metric) => {return {...acc, ...metric} } )
            console.log("metricsObj: ", metricsObj);
            setMetrics(metricsObj)
        }

        getMetrics()
    }, [selectedDate])

    useEffect(() => {
        console.log('selectedDate> ', selectedDate, new Date(Number(selectedDate)).getDate().toString())
    }, [selectedDate])

    const [financialExtract, setFinancialExtract] = useState([])

    useEffect(() => {
        const thisDay = new Date(Number(selectedDate)).getDate()
        const thisMonth = new Date(Number(selectedDate)).getMonth() + 1
        const thisYear = new Date(Number(selectedDate)).getFullYear()

        console.log('getFinancialExtract: ', user.iugu.live_api_token, 2022, thisYear, thisMonth, thisDay)

        Iugu.getFinancialExtract(user.iugu.live_api_token, 2022, 3).then(resp => {
            console.log('getFinancialExtract resp', resp)
            if (resp) {
                if (resp.transactions) {
                    setFinancialExtract(resp.transactions)
                }
            }
        })
    }, [user.iugu.live_api_token, selectedDate])


    useEffect(() => {
        const dateOptions = []
        const dateNow = new Date()
        
        setSelectedDate(dateNow.getTime())
        
        dateOptions.push({
            label: dateNow.toISOString().substring(5, 10).split('-').reverse().join('/'),
            value: new Date(dateNow).getTime()
        })

        for (let i = 0; i < 30; i++) {
            const temp = new Date(dateNow.setDate(dateNow.getDate() - 1))

            dateOptions.push({
                    label: temp.toISOString().substring(5, 10).split('-').reverse().join('/'),
                    value: new Date(dateNow).getTime()
                })
        }

        setDatesOptions(dateOptions)
    }, [])


    return (
        <LayoutAdmin mainId="AdminPanel">
            
            <HeaderAdmin />

            <div className="rowContainer" style={{marginTop: 50}}>
                <MetricCard type="positive" label={'Usuários cadastrados'} metric={metrics.totalAccount} />
                <MetricCard type="positive" label={'Pups cadastrados'} metric={metrics.totalPups} />
                <MetricCard type="positive" label={'Publicações'} metric={metrics.totalPost} />
                <MetricCard type="positive" label={'Total de likes'} metric={metrics.totalLikes} />
            </div>

            <div className="rowContainer">
                <MetricCard type="neutral" label={'Total de comentários'} metric={metrics.totalComments} />
                <MetricCard type="neutral" label={'Compras iniciadas'} metric={metrics.totalStartPurchase} />
                <MetricCard type="neutral" label={'Compras completadas'} metric={metrics.totalCompletedPurchase} />
                <MetricCard type="neutral" label={'Total de reembolsos'} metric={metrics.totalRefunds} />
            </div>

            <div className="rowContainer">
                <MetricCard type="negative" label={'Pedidos cancelados'} metric={metrics.totalPurchasesCanceledByManager} />
                <MetricCard type="negative" label={'Pedidos expirados'} metric={metrics.totalPurchasesExpired} />
            </div>
            
            <CardContainer size="large" style={{marginTop: 50}}>
                <FinancialTable financialExtract={financialExtract} />
            </CardContainer>

        </LayoutAdmin>
    )
}
