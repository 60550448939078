import { Table } from 'components/Table'
import React from 'react'
import { DataTable } from '../../../components/DataTable'

import './financialTable.scss'


const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
})


function sanitizeDescription(description) {
    if (!description) return ''

    if (description === 'Pedido de saque') return description

    // "Pagamento recebido: Invoice #FDD6468AA3A04B3F91F26AF3CBC75917"
    // retorna: Pagamento recebido
    if (description.includes(': Invoice #')) return description.split(': Invoice #')[0]
    
    // "description": "Tarifas - Estorno - Fatura #3413B6475EC14FED964AA51BFC2A3C9F",
    // retorna: Tarifas - Estorno
    if (description.includes('Fatura #')) return 'Tarifas - Estorno'

    if (description.length > 27) {
        return description.substring(0, 25) + '...'
    }

    return description
}


function financialDataFormatter(data) {
    const {reference, description, payer_name, entry_date, amount, balance, type}  = data

    const dataFormatted = {}
    
    dataFormatted.id = Math.random().toString().replace('0.', '')
    dataFormatted.reference = reference.substring(0, 5)
    dataFormatted.description = sanitizeDescription(description)
    dataFormatted.payer_name = payer_name //? payer_name.split(' ')[0] + ' ' + payer_name.split(' ')[2][0] : '-'
    dataFormatted.entry_date = entry_date
    dataFormatted.amount = data.amount // formatter.format(Number(data.amount.replace(' BRL', '')))
    dataFormatted.amountNegative = !!data.amount.includes('-')
    dataFormatted.balance = balance
    dataFormatted.type = type === 'debit' ? 'Débito' : 'Crédito'
    
    return dataFormatted
}

const dataList = [
    // {key: 'id', label: 'Id do Pedido', type: 'image'},
    {key: 'reference', label: 'Referência'},
    {key: 'description', label: 'Descrição'},
    {key: 'payer_name', label: 'Comprador'},
    {key: 'entry_date', label: 'Data'},
    {key: 'amount', label: 'Valor'},
    {key: 'balance', label: 'Saldo'},
    {key: 'type', label: 'Tipo'},
]

export function FinancialTable({financialExtract}) {

    const financialExtractSanitized = financialExtract.map(financialDataFormatter)

    return (
        <>
            <header>
                <h4>Extrato financeiro</h4>
            </header>

            <Table
                data={financialExtractSanitized}
                dataList={dataList}
            />
        </>
    )
}
