import React, {useState} from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { firestoreDatabase } from 'services/firebase';
import { firebaseStorage } from 'services/firebase'


export function Modal({ service, onRequestClose, onEdit, onDelete }) {
	const [thisService, setThisService] = useState({...service})
	const [selectedNewImage, setSelectedNewImage] = useState()


	async function handleEdit(e) {
		e.preventDefault();

		const imageUrl = selectedNewImage ? await saveNewImage() : thisService.imageUrl

		const newServiceData = {
			...thisService, 
			imageUrl,
			order: Number(thisService.order), 
			splitsPercentage: Number(thisService.splitsPercentage)
		}

		await firestoreDatabase.collection('configurations').doc('services').collection('servicesList').doc(newServiceData.id).update({
            ...newServiceData
        })

		onEdit(newServiceData)
	}

	function handleCancel(e) {
		e.preventDefault();
		onRequestClose()
	}


	async function handleSelectStoreImage(event) {
		const files = event.target.files
        const selectedStoreImage = Array.from(files)[0]

        if(!selectedStoreImage) return

        const selectedStoreImagePreview = URL.createObjectURL(selectedStoreImage)

		async function getImageSize(url) {
			const img = new Image();
			img.src = url;
		
			const promise = new Promise((resolve, reject) => {
				img.onload = () => {
					const width  = img.naturalWidth;
					const height = img.naturalHeight; 
					resolve({width, height});
				};
			
				img.onerror = reject;
			});
		
		
			return promise;
		}

		const imageSize = await getImageSize(selectedStoreImagePreview)

		if (imageSize.width > 80 || imageSize.height > 80) {
			alert('Tamanho de imagem não suportado')
			return
		}
		
        setSelectedNewImage(selectedStoreImagePreview)
	}


	async function saveNewImage() {
		async function asyncUploadImg(imageToUpload, pathToUpload, imageName) {
            if (!imageToUpload) return null
            if (!pathToUpload) return null
            if (!imageName) return null
    
            const response = await fetch(imageToUpload)
            const blob = await response.blob()
    
            const childPath = `/${pathToUpload}/${imageName}`
    
            const task = await firebaseStorage.ref('configurations')
                .child(childPath)
                .put(blob)
    
            return task.ref.getDownloadURL()
        }

		if (service.id) {
            const imageUrl = await asyncUploadImg(selectedNewImage, 'servicesList', service.id)
            return imageUrl
        }
	}


	return (
		<form onSubmit={handleEdit}>

			<h5>Por aqui você pode editar as informações do seu serviço</h5>

			<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
				<TextField
					defaultValue={thisService.label}
					floatingLabelText="Nome"
					onChange={(event, newValue) => setThisService((oldState) => ({...oldState, label: newValue}))}
				/>

				<TextField
					defaultValue={thisService.splitsPercentage}
					value={thisService.splitsPercentage}
					floatingLabelText="Split (0 a 100)%"
					type="number" 
					min="1"
					step="0.1"
					onChange={(event, newValue) => setThisService((oldState) => ({...oldState, splitsPercentage: newValue}))}
				/>
			</div>

			<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
				<TextField
					defaultValue={thisService.order}
					value={thisService.order}
					floatingLabelText="Order"
					type="number" 
					min="0"
					step="1"
					onChange={(event, newValue) => setThisService((oldState) => ({...oldState, order: newValue}))}
				/>

				<SelectField
					value={thisService.active}
					floatingLabelText="Serviço ativado?"
					onChange={(event, index, value) => setThisService((oldState) => ({...oldState, active: value}))}
					multiple={false}
				>
					<MenuItem value={true} primaryText='Sim' />
					<MenuItem value={false} primaryText='Não' />
				</SelectField>
			</div>

			<br/>

			<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>

				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
					<label htmlFor={'image-picker' + service.id} style={{width: '100%', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', cursor: 'pointer' }} >
						{(service.imageUrl || selectedNewImage)
							? <img src={selectedNewImage ? selectedNewImage : service.imageUrl} style={{width: 100,  alignSelf: 'center' }} alt="Imagem do serviço" /> 
							: <p>Clique para adicionar</p>
						}
						<p style={{fontSize: 10, marginTop: 3}}> 70x70 até 80x80 </p>
					</label>
					<input type="file" id={'image-picker' + service.id} style={{display: 'none'}} onChange={handleSelectStoreImage} />
				</div>

				<div style={{width: 255, display: 'flex', flexDirection: 'column'}}>
					<label htmlFor="color-picker" style={{margin: 0, width: 120}}>Cor: {thisService.color}</label>
					<input 
						type="color"
						id="color-picker"
						name="color-picker"
						value={thisService.color}
						onChange={(event) => setThisService((oldState) => ({...oldState, color: event.target.value}))}
						style={{border: 'none', width: 110, height: 30, cursor: 'pointer'}}
					/>
				</div>
			</div>

			<br/>

			<div>
				<RaisedButton
					className={'mr-3 my-3'}
					label="Atualizar"
					primary={true}
					type="submit"
				/>

				{/* <RaisedButton
					className={'mr-3 my-3'}
					label="Deletar"
					secondary={true}
					type="Cancel"
					// onClick={handleDeleteService}
				/> */}

				<RaisedButton
					className={'mr-3 my-3'}
					label="Cancel"
					type="Cancel"
					onClick={handleCancel}
				/>
			</div>

		</form>
	)
}
