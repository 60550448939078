import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/firebase-storage'
import 'firebase/functions'

import firebase from 'firebase/app'

const productionFirebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
}

const developmentFirebaseConfig = {
	apiKey: process.env.REACT_APP_DEV_API_KEY,
	authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_DEV_PROJECT_ID,
	storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_DEV_APP_ID,
}


const firebaseConfig = process.env.NODE_ENV === 'development' ? developmentFirebaseConfig : productionFirebaseConfig 

console.log(' ==>> process.env.NODE_ENV ==>>> ', process.env.NODE_ENV)


if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig)
} else {
	firebase.app()
}

firebase.firestore().settings({ experimentalForceLongPolling: true })

const auth = firebase.auth()
const database = firebase.database()
const firestoreDatabase = firebase.firestore()
const firebaseStorage = firebase.storage()
const firebaseFunctions = firebase.functions()

export {
	firebase, auth, database, firestoreDatabase, firebaseStorage, firebaseFunctions
}
