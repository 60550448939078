import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { Dialog, LinearProgress } from 'material-ui'

import { auth, firestoreDatabase } from '../../../services/firebase'
import { Iugu } from '../../../services/iugu'
import { useIugu } from '../../../services/iuguCreditCard'

import './styles.scss'


function useQuery() {
    const { search } = useLocation()
  
    return React.useMemo(() => new URLSearchParams(search), [search])
}

// const IUGU_ACCOUNT_TOKEN = 'B0A630ACDEB542A7A53A7213E79177C1' // Jonas
const IUGU_ACCOUNT_TOKEN = '2AE52D935A1C4DA59A823E68FFC763D4' // Priscila

export function FinishPurchase() {
    const IuguHook = useIugu(IUGU_ACCOUNT_TOKEN);
    const query = useQuery()

    const [showModal, setShowModal] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [cardName, setCardName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [cardSecurityCode, setCardSecurityCode] = useState('')

    const [event, setEvent] = useState({})

    const [step, setStep] = useState(0)
    const [service, setService] = useState()
    const [serviceConfig, setServiceConfig] = useState()
    const [uid, setUid] = useState()
    const [userData, setUserData] = useState()

    const [purchaseStatus, setPurchaseStatus] = useState('')

    async function handleNextStep() {
        try {
            const authResult = await auth.signInWithEmailAndPassword(email, password)
            if (authResult && authResult.user) {

				const {uid} = authResult.user

				if (!uid) {
					return
				}

                const orderId = query.get("orderId")

                const userSnapshot = await firestoreDatabase.collection('user').doc(uid).get()
                const _userData = userSnapshot.data()
                setUserData(_userData)
    
				firestoreDatabase.collection('user').doc(uid).collection('startEvent').doc(orderId).get()
                    .then((eventResponse) => {
                        if (eventResponse.exists) {
                            const eventData = eventResponse.data()
                            console.log('eventData: ', eventData)
                            setEvent(eventData)
                            setStep(1)
                            setUid(uid)
                        }
                    })
            }
        } catch (error) {
            console.log('error: ', error)
            alert('Erro ao identificar usuário. Verifique seus dados e tente novamente.')
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setShowModal(true)
        
        IuguHook.setAccountToken(IUGU_ACCOUNT_TOKEN)
        IuguHook.setTestMode(false)

        try {
            const iuguCreditCard = await IuguHook.createPaymentToken(e.target, true);
            const creditCardToken = iuguCreditCard.id
            console.log('creditCardToken: ', creditCardToken)

            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
    
            const dueDateYear = tomorrow.getFullYear()
            const dueDateMonth = (tomorrow.getMonth() + 1).toString().padStart(2, '0')
            const dueDateDay = tomorrow.getDate()
    
            const dueDate = dueDateYear + '-' + dueDateMonth + '-' + dueDateDay //'AAAA-MM-DD'
    
            const itemsToPurchase = [
                {
                    description: 'My Pups - serviço: ' + event.serviceName,
                    quantity: 1,
                    price_cents: event.price,
                },
            ]

            
		    const cepInCorrectFormat = `${userData.cep.substring(0, 5)}-${userData.cep.substring(5)}`
            
            const payer = {
                cpf_cnpj: userData.cpf,
                name: name,
                phone_prefix: '',
                phone: '',
                email: email,
                address: {
                    zip_code: cepInCorrectFormat,
                    street: userData.street,
                    number: userData.number,
                    district: userData.neighborhood,
                    city: userData.city,
                    state: userData.state,
                    country: userData.country,
                    complement: userData.complement,
                }
            }

            const orderId = query.get("orderId")

            const response = await Iugu.createInvoice({
                email,
                due_date: dueDate,
                items: itemsToPurchase,
                payable_with: 'credit_card',
                order_id: orderId,
                payer,
                splits: [
                    {
                        recipient_account_id: IUGU_ACCOUNT_TOKEN,
                        percent: serviceConfig.splitsPercentage
                    }
                ],
                custom_variables: [
                    { name: 'storeId', value: service.storeId },
                ],
            })

            // const response = {
            //     id: '734EC41D492F49F4A52C8427EB93A5B3'
            // }

            completePurchase(response, creditCardToken, payer, orderId.toString())
        } catch (error) {
            setShowModal(false)
            console.log('error: ', error)

            if (error.errors) {
                if (error.errors.expiration) {
                    return alert('Erro! Data de validade incorreta! Cheque seus dados e tente novamente!')
                }
                if (error.errors.last_name) {
                    return alert('Erro! Nome do titular do cartão incorreto! Cheque seus dados e tente novamente!')
                }
                if (error.errors.verification_value) {
                    return alert('Erro! Código de segurança do cartão incorreto! Cheque seus dados e tente novamente!')
                }
                if (error.errors.number) {
                    return alert('Erro! Número do cartão incorreto! Cheque seus dados e tente novamente!')
                }
            }
            return alert('Erro! Dados incorretos! Cheque suas informações e tente novamente!')
        }
    }

    useEffect(() => {
        if (!event) return
        if (!event.serviceId) return
        
        firestoreDatabase.collection('services').doc(event.serviceId).get()
            .then((serviceResponse) => {
                const serviceData = serviceResponse.data()
                console.log('serviceData: ', serviceData)
                setService(serviceData)

                const {typesOfService} = serviceData

                firestoreDatabase.collection('configurations').doc('services').collection('servicesList').doc(typesOfService).get()
                    .then((serviceConfigResponse) => {
                        const serviceConfigData = serviceConfigResponse.data()
                        console.log('serviceConfigData: ', serviceConfigData)
                        setServiceConfig(serviceConfigData)
                    })
                    
            })

    }, [event])


    async function completePurchase(invoiceResponse, creditCardToken, payer, orderId) {

        const serviceInfo = {
            ...event,
            status: 'payment-in-verification',
			statusInfos: {
				'payment-in-verification-date': new Date().getTime(),
			},
        }


        try {
            await firestoreDatabase.collection('event')
                .doc(service.storeId).collection('nextEvents')
                .doc(invoiceResponse.id).set(serviceInfo)

            console.log('primeiro await')

            await firestoreDatabase.collection('user').doc(uid).collection('startEvent').doc(orderId).update({invoiceId: invoiceResponse.id})
            console.log('segundo await')

            await firestoreDatabase.collection('services').doc(service.id).update({
                // É preciso manter somente uma linha para evitar erros adicionando \n no objeto
                // eslint-disable-next-line max-len
                [`booking.${event.startCorrectServiceDateString}.${event.startCorrectServiceTimeString}`]: true,
            })
            console.log('terceiro await')

            const purchaseResponse = await Iugu.purchaseAInvoice({
                invoice_id: invoiceResponse.id,
                credit_card_token: creditCardToken,
                payer, 
                orderId,
                storeId: service.storeId
            })
            console.log('quarto await', purchaseResponse)

            await firestoreDatabase.collection('user').doc(uid).collection('startEvent').doc(orderId).delete()
            console.log('quinto await')

            await firestoreDatabase.collection('orders').doc(invoiceResponse.id).set({
                ...serviceInfo,
                invoiceResponse,
                purchaseResponse
            })

            // redirecionar para app
            console.log('Compra concluida')
            setPurchaseStatus('finish')
        } catch (error) {
            setShowModal(false)
            console.log('Erro ao concluir compra!', error)
            setPurchaseStatus('error')
            alert('Erro ao concluir compra!\n Tente novamente mais tarde.' + '\n\n' + JSON.stringify(error))
        }
    }

    return (
        <div id="page-finish-purchase">

            <main>
                <form className="finish-purchase-form" onSubmit={handleSubmit}>
                    <fieldset style={step === 0  ? styles.showContent : styles.hiddenContent }>
                        <legend>
                            <h2>Finalizar compra</h2>
                            <span>Parte 1: Confirme quem é você:</span>
                        </legend>

                        <div className="input-block">
                            <label htmlFor="full-name">Seu nome completo</label>
                            <input id="full-name" value={name} onChange={event => setName(event.target.value)} />
                        </div>

                        <div className="input-block">
                            <label htmlFor="email">Email</label>
                            <input id="email" type="email" value={email} onChange={event => setEmail(event.target.value)} />
                        </div>

                        <div className="input-block">
                            <label htmlFor="password">Senha</label>
                            <input id="password" type="password" value={password} onChange={event => setPassword(event.target.value)} />
                        </div>
                    </fieldset>

                    <fieldset  style={ step === 1 ? styles.showContent : styles.hiddenContent }>
                        <legend>
                            <h2>Finalizar compra</h2>
                            <span>Parte 2: Confirme o meio de pagamento:</span>
                        </legend>

                        <div className="input-block">
                            <label htmlFor="name">Nome no cartão</label>
                            <input
                                required
                                data-iugu="full_name"
                                type="text"
                                id="name"
                                name="cc-name"
                                autocomplete="cc-name"
                                value={cardName}
                                onChange={event => setCardName(event.target.value)}
                            />
                        </div>
                        
                        <div className="input-block">
                            <label htmlFor="card-number">Número cartão</label>
                            <input
                                required
                                data-iugu="number"
                                type="text"
                                id="card-number"
                                name="card-number"
                                inputMode="numeric"
                                autocomplete="cc-number"
                                pattern="[0-9]+"
                                value={cardNumber} 
                                onChange={event => setCardNumber(event.target.value)}
                            />
                        </div>

                        <div className="row-input-block">
                            <div className="input-block">
                                <label htmlFor="expiry-date">Data de validade</label>
                                <input
                                    required
                                    data-iugu="expiration"
                                    type="text"
                                    id="expiry-date"
                                    name="expiry-date"
                                    class="expiry-date"
                                    autocomplete="cc-exp"
                                    placeholder="MM/YY" 
                                    minLength="5"
                                    maxLength="5"
                                    // pattern="[0-9/]+"
                                    // pattern="\d{1,2}/\d{1,2}/\d{4}"
                                    pattern="[0-9]{1,2}/[0-9]{1,2}"
                                    value={expiryDate} 
                                    onChange={event => setExpiryDate(event.target.value)}
                                    title="Formato: MM/AA"
                                />
                            </div>

                            <div className="input-block">
                                <label htmlFor="security-code">Código de seg.</label>
                                <input
                                    required
                                    data-iugu="verification_value" 
                                    type="text"
                                    id="security-code"
                                    name="security-code"
                                    inputMode="numeric"
                                    minLength="3"
                                    maxLength="4" 
                                    pattern="[0-9]+"
                                    value={cardSecurityCode} 
                                    onChange={event => setCardSecurityCode(event.target.value)}
                                />
                            </div>
                        </div>
                    </fieldset>

                    {step === 0 && <button className="confirm-button" onClick={handleNextStep}>
                        Prosseguir
                    </button>}

                    {step === 1 && <button className="confirm-button" type="submit">
                        Finalizar pedido
                    </button>}

                    {step === 1 && <button className="back-button" onClick={() => setStep(0)}>
                        Voltar
                    </button>}
                </form>
            </main>

            <Dialog
				title="Compra"
				modal={false}
				open={showModal}
				// autoScrollBodyContent={true}
			>
                {showModal && <BankAccountInVerificationModal status={purchaseStatus} />}
			</Dialog>

        </div>
    )
}


function BankAccountInVerificationModal({status}) {
    return (
        <div>
            {status === 'finish' ? (
                <p>
                    Compra finalizada com sucesso! Você receberá notificações com novas atualizações.<br />Você já pode voltar ao aplicativo.
                </p>
            ) : (
                <p>
                    Sua compra está sendo processada.
                </p>
            )}

            <br />

            {status !== 'finish' && <LinearProgress variant={status === 'finish' ? 'determinate' : undefined} value={status === 'finish' ? 100 : undefined} />}
        </div>
    )
}

const styles = {
    showContent: {
        visibility: 'visible',
        display: 'block',
    },
    hiddenContent: {
        visibility: 'hidden',
        display: 'none',
    },
}
