import React, {useEffect, useState} from 'react';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import RaisedButton from 'material-ui/RaisedButton';


export function Modal({ animalClass, onRequestClose, onEdit, onDelete }) {
	const [thisService, setThisService] = useState({...animalClass})

	function handleSubmit(e) {
		e.preventDefault();
		onRequestClose()
		onEdit({
			...thisService, 
		})
	}

	function handleDeleteService(e) {
		e.preventDefault();
		onDelete(thisService)
		onRequestClose()
	}


	return (
		<form onSubmit={handleSubmit}>

			<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
				<TextField
					defaultValue={thisService.name}
					floatingLabelText="Nome"
					onChange={(event, newValue) => setThisService((oldState) => ({...oldState, name: newValue}))}
				/>

				{(thisService.visible === true || thisService.visible === false) && <SelectField
					value={thisService.visible}
					floatingLabelText="Tornar visível?"
					onChange={(event, index, value) => setThisService((oldState) => ({...oldState, visible: value}))}
					multiple={false}
				>
					<MenuItem value={true} primaryText='Sim' />
					<MenuItem value={false} primaryText='Não' />
				</SelectField>}
			</div>

			<br />

			<div>
				<RaisedButton
					className={'mr-3 my-3'}
					label="Salvar"
					primary={true}
					type="submit"
				/>

				<RaisedButton
					className={'mr-3 my-3'}
					label="Deletar"
					secondary={true}
					primary={false}
					type="cancel"
					onClick={handleDeleteService}
				/>
			</div>

		</form>
	)
}
