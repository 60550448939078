
import Leaflet from 'leaflet';

import Logo from '../../assets/icon/location.svg'

export const MapIcon = Leaflet.icon({
    iconUrl: Logo, 
    iconSize: [27, 43],
    iconAnchor: [13, 43],
    popupAnchor: [170, 2]
  })
