import { firebaseFunctions } from './firebase'

async function createSubConta(nameOfStore) {

    console.log('createSubConta: ', nameOfStore)

    try {
        const createSubContaFunction = firebaseFunctions.httpsCallable('createSubConta')
        const response = await createSubContaFunction({ nameOfStore })
        return response.data.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('createSubConta Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


// Para essa chamada, deve ser alterado a api_token, pela user_token da subconta iugu
async function updateSubConta({iuguUserToken, nameOfStore}) {

    console.log('updateSubConta: ', iuguUserToken, nameOfStore)

    try {
        const updateSubContaFunction = firebaseFunctions.httpsCallable('updateSubConta')
        const response = await updateSubContaFunction({ iuguUserToken, nameOfStore })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('createSubConta Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


// Para essa chamada, deve ser alterado a api_token, pela user_token da subconta iugu
async function sendAccountVerificationRequest(
    account_id, user_token, 
    {price_range, physical_products, business_type, person_type, automatic_transfer, cnpj, cpf, company_name, name, address, cep, city, district, state, telephone, resp_name, resp_cpf, bank, bank_ag, account_type, bank_cc}
    ) {

    const postData = {
        price_range,
        physical_products,
        business_type,
        person_type,
        automatic_transfer,
        cnpj,
        cpf,
        company_name,
        name,
        address,
        cep,
        city,
        district,
        state,
        telephone,
        resp_name,
        resp_cpf,
        bank,
        bank_ag,
        account_type,
        bank_cc,
    }

    console.log('sendAccountVerificationRequest: ', {account_id, user_token, postData})

    try {
        const sendAccountVerificationRequestFunction = firebaseFunctions.httpsCallable('sendAccountVerificationRequest')
        const response = await sendAccountVerificationRequestFunction({ account_id, user_token, postData })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('sendAccountVerificationRequest Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


// Precisamos confirmar qual o token a ser enviado nessa chamada
async function addBankAddress(user_token, {agency, account, account_type, bank}) {

    const postData = {
        agency,
        account,
        account_type,
        bank,
    }

    console.log('addBankAddress: ', user_token, postData)

    try {
        const addBankAddressFunction = firebaseFunctions.httpsCallable('addBankAddress')
        const response = await addBankAddressFunction({ user_token, postData })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('addBankAddress Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


// Para essa chamada, deve ser alterado a api_token, pela user_token da subconta iugu
async function configureAccountToUseCreditCardMethodForPurchase(user_token, storeName) {

    const softDescriptor = `MyPups-${storeName}`.substring(0, 12)

    console.log('configureAccountToUseCreditCardMethodForPurchase: ', user_token, storeName, softDescriptor)

    try {
        const configureAccountFunction = firebaseFunctions.httpsCallable('configureAccountToUseCreditCardMethodForPurchase')
        const response = await configureAccountFunction({ user_token, softDescriptor })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('configureAccountToUseCreditCardMethodForPurchase Error: code: ', code, 'message: ', message, 'details: ', details)
    }

}

// Na api_token deve ser usado o live-token da subconta.
async function addPIXMethodForPurchases(live_token) {

    console.log('addPIXMethodForPurchases: ', live_token)

    try {
        const addPIXMethodForPurchasesFunction = firebaseFunctions.httpsCallable('addPIXMethodForPurchases')
        const response = await addPIXMethodForPurchasesFunction({ live_token })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('addPIXMethodForPurchases Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


async function createInvoice({email, due_date, items, payable_with, payer, splits, order_id, custom_variables}) {
    
    console.log('createInvoice: ', {email, due_date, items, payable_with, payer, splits, order_id, custom_variables})

    try {
        const createInvoiceFunction = firebaseFunctions.httpsCallable('createInvoice')
        const response = await createInvoiceFunction({ email, due_date, items, payable_with, payer, splits, order_id, custom_variables })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('createInvoice Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


async function purchaseAInvoice({invoice_id, credit_card_token, payer, orderId, storeId}) {

    console.log('purchaseAInvoice: ', {invoice_id, credit_card_token, payer, orderId, storeId})

    try {
        const purchaseAInvoiceFunction = firebaseFunctions.httpsCallable('purchaseAInvoice')
        const response = await purchaseAInvoiceFunction({ invoice_id, credit_card_token, payer, orderId, storeId })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('purchaseAInvoice Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


async function refundAInvoice(live_api_token ,invoice_id) {

    console.log('refundAInvoice: ', live_api_token ,invoice_id)

    try {
        const refundAInvoiceFunction = firebaseFunctions.httpsCallable('refundAInvoice')
        const response = await refundAInvoiceFunction({ live_api_token ,invoice_id })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('refundAInvoice Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


async function getFinancialExtract(live_api_token, year, month, day) {

    console.log('getFinancialExtract: ', year, month, day)

    try {
        const getFinancialExtractFunction = firebaseFunctions.httpsCallable('getFinancialExtract')
        const response = await getFinancialExtractFunction({ live_api_token, year, month, day })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('getFinancialExtract Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}


const Iugu = {
    createSubConta,
    updateSubConta,
    sendAccountVerificationRequest,
    addBankAddress,
    addPIXMethodForPurchases,
    configureAccountToUseCreditCardMethodForPurchase,
    createInvoice,
    purchaseAInvoice,
    refundAInvoice,
    getFinancialExtract
}

export {Iugu}
