import React from 'react'

import './styles.scss'

export function CardContainer({size, children, style}) {

    return (
        <div id="CardContainer" style={style} className={size ? size : 'medium'}>
            {children}
        </div>
    )
}
