import { BackgroundAdmin } from "components/BackgroundAdmin";
import { SidebarNavigator } from "components/Sidebar";
import { Redirect } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { routesNames } from "./routesNames";

import { Store } from 'pages/Manager/Store/Store'
import { MyServices } from 'pages/Manager/Store/MyServices'
import { MyStore } from 'pages/Manager/Store/MyStore'
import { Financial } from 'pages/Manager/Store/Financial'
import { Support } from 'pages/Manager/Store/Support'
import { RecipesExtract } from 'pages/Manager/Store/RecipesExtract'


export function ManagerRoutes() {
    
    return (
        <BackgroundAdmin>
            <SidebarNavigator />
            <PrivateRoute path={routesNames.estabelecimento.agenda.path} exact component={Store} />
            <PrivateRoute path={routesNames.estabelecimento.servicos.path} component={MyServices} />
            <PrivateRoute path={routesNames.estabelecimento.loja.path} component={MyStore} />
            <PrivateRoute path={routesNames.estabelecimento.financeiro.path} component={RecipesExtract} />
            <PrivateRoute path={routesNames.estabelecimento.financial.path} component={Financial} />
            <PrivateRoute path={routesNames.estabelecimento.support.path} component={Support} />

            <PrivateRoute exact path={routesNames.estabelecimento.index} component={() => <Redirect to={{ pathname: routesNames.estabelecimento.agenda }} />} />

        </BackgroundAdmin>
)

} 

