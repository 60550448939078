import React, { useState, useEffect, useRef } from 'react'

import { useHistory, useLocation } from 'react-router'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { InputUnForm } from '../../../components/InputUnForm'

import './styles.scss'

export function Login() {
    document.querySelector('html').style.fontSize = '100%'

    const history = useHistory()
    const location = useLocation()
    const formRef = useRef(null)

    const { user, firebaseSignIn} = useAuth()

    useEffect(() => {
        if (user) {
            console.log('user logado:', user)
            if (user.userState === 'creatingNewStore') {
                history.push(routesNames.cadastro.novoEstabelecimento.path)
                return
            }
            if (user.roles.includes('admin')) {
                history.push(routesNames.admin.metrics.path)
                return
            }
            history.push(routesNames.estabelecimento.agenda.path)
        }

    }, [history,  user])

    async function handleSubmit(data) {
        const {email, password} = data

        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                email: Yup.string()
                    .email()
                    .required(),
                password: Yup.string()
                    .min(6)
                    .required(),
            })

            await schema.validate(data, {
                abortEarly: false,
            })

            firebaseSignIn(email, password)
            
        } catch (err) {
            const validationErrors = {}

            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    if (error.path) {
                        validationErrors[error.path] = error.message
                    }

                })
                formRef.current?.setErrors(validationErrors)
            }
        }

    }

    return (
        <div id="login">

            <main>
                <Form ref={formRef} className="form-auth" onSubmit={handleSubmit}>
                    <InputUnForm
                        name="email"
                        label="Digite seu e-mail"
                        type="text"
                        placeholder="tony@stark.com"
                    />

                    <InputUnForm
                        name="password"
                        label="Digite sua senha"
                        type="password"
                        placeholder="******"
                    />

                    <button 
                        className="buttonAuth" 
                        type="submit"
                    >
                        Login
                    </button>

                </Form>
            </main>
            
        </div>
    )
}
