import React from 'react'

import './cards.scss'

export function MetricCard({label, metric, type}) {

    return (
        <div id="metricCard" className={type}>

            <h5>{label}</h5>
            <p>{metric}</p>

        </div>
    )
}

// PositiveCard
