import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog } from 'material-ui'


import { Card } from './Card'
import { useAuth } from 'hooks/useAuth'
import { firebaseStorage, firestoreDatabase } from 'services/firebase'
import { routesNames } from 'routes/routesNames'
import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin'
import { CardContainer } from 'Layouts/CardContainer'
import { Table } from 'components/Table'
import { LIST_OF_SERVICES, TIME_OF_SERVICES } from 'config/constants'


const dataList = [
    {key: 'imageUrl', label: 'Imagem', type: 'image'},
    {key: 'name', label: 'Nome'},
    {key: 'active', label: 'Ativo', type: 'bool'},
    {key: 'price', label: 'Preço'},
    {key: 'description', label: 'Descrição'},
    {key: 'serviceDurationLabel', label: 'Tempo de serviço'},
    {key: 'typesOfService', label: 'Tipo de serviço'},
]

const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
})


function transformServiceData(serviceData) {
    const { id, storeId, imageUrl, name, price, description, serviceDurationTime, typesOfService } = serviceData
    
    const dataFormatted = {}
    
    dataFormatted.id = id.substring(0, 5)
    dataFormatted.storeId = storeId.substring(0, 5)
    dataFormatted.description = description
    dataFormatted.imageUrl = imageUrl
    dataFormatted.name = name
    dataFormatted.price = formatter.format(price/100)
    dataFormatted.serviceDurationLabel = TIME_OF_SERVICES[serviceDurationTime]?.time + ' ' + TIME_OF_SERVICES[serviceDurationTime]?.label
    dataFormatted.typesOfService = LIST_OF_SERVICES.find((_service) => _service.id === typesOfService)?.label
    
    return dataFormatted
}


export function MyServices() {
    const { user } = useAuth()
    const history = useHistory()

    const [services, setServices] = useState([])
    const [accountStatus, setAccountStatus] = useState()

    useEffect(() => {
        if (user) {
            firestoreDatabase.collection('services').where('storeId', '==', user.storeId).get().then(response => {
                const servicesList = []
                response.forEach(doc => {
                    servicesList.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                })
                setServices(servicesList.map(transformServiceData))
            })
        }
    }, [user])
    
    useEffect(() => {
        firestoreDatabase.collection('store').doc(user.storeId).get().then(storeResponse => {
            const storeData = storeResponse.data()
            setAccountStatus(storeData.status)

        })
    }, [user.storeId])

    function handleEdit(data) {
        console.log('handleEdit data: ', data)

        setServices((oldServices) => oldServices.map((oldService) => (
            oldService.id === data.id ? data : oldService
        )))

        firestoreDatabase.collection('services').doc(data.id).update({
            ...data
        })
    }

    async function handleDelete(data) {
        console.log('handleDelete data: ', data)

        setServices((oldServices) => oldServices.filter((oldService) => (
            oldService.id !== data.id
        )))

        await firestoreDatabase.collection('services').doc(data.id).delete()
        await firebaseStorage.ref(`store/${data.storeId}`).child(data.id).delete()
    }

    function handleAddNewService() {

        firestoreDatabase.collection('store').doc(user.storeId).get().then(storeResponse => {
            const storeDocument = storeResponse.data()

            const newServiceData = {
                name: 'Edite para alterar o nome.',
                description: 'Edite para alterar a descrição.',
                city: storeDocument.city,
                state: storeDocument.state,
                lat: storeDocument.lat,
                lng: storeDocument.lng,
                price: 0.0,
                booking: {},
                imageUrl: '',
                storeId: user.storeId,
                typesOfService: 'Edite para alterar o tipo de serviço.',
                serviceDurationTime: 0,
            }

            firestoreDatabase.collection('services').add(newServiceData).then((serviceResponse) => {
                const serviceDocumentId = serviceResponse.id
                console.log('setServices: ', {...newServiceData, id: serviceDocumentId})
                setServices((oldServices => [...oldServices, {...newServiceData, id: serviceDocumentId}]))
            })
        })
    }


    function navigateToFinancial() {
        setAccountStatus(null)
        history.push(routesNames.estabelecimento.financial.path)
    }

    return (
        <LayoutAdmin mainId="MyServices">

            <CardContainer size="large">
                <div>
                    <h2>Serviços</h2>
                    <br />
                    <h4>Total de serviços: {services.length} | ativos: {services.filter(service => service.active).length} </h4>
                    <br />
                </div>

                <Table
                    data={services}
                    dataList={dataList}
                    // onRowCLick={(rowData) => setEditService(rowData)}
                />

                <button className="btn btn-success btn-sm" style={{marginTop: 30, marginBottom: 20}} onClick={handleAddNewService}>
                    Adicionar novo serviço
                </button>
            </CardContainer>

            {/* {services.map((service) => <Card key={service.id} service={service} handleEdit={handleEdit} handleDelete={handleDelete} /> )} */}

            <Dialog
				title="Situação cadastral"
				modal={false}
				open={accountStatus === 'bank-account-in-verification'}
				autoScrollBodyContent={true}
			>
                {accountStatus === 'bank-account-in-verification' && <BankAccountInVerificationModal navigateToFinancial={navigateToFinancial} />}
			</Dialog>
        </LayoutAdmin>


    )
}

function BankAccountInVerificationModal({navigateToFinancial}) {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <br />
            <h5>Seus dados ainda não foram verificados por nós.</h5>
            <h5>Antes de começar a oferecer seus serviços é preciso que você termine a verificação dos dados financeiros.</h5>
            <h5>Você pode acompanhar o progresso de sua análise na seção financeiro.</h5>

            <br />

            <button
                onClick={navigateToFinancial} 
                className="button-verified-email"
            >
                Ir para financeiro
            </button>
        </div>
    )
}
