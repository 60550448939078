import { FaSearch } from 'react-icons/fa';

import './styles.scss'

export function SearchInput({onChange}) {

    return (
        <div className="searchInputContainer">

            <label
                htmlFor="searchInput"
                className="searchInputIconLabel"
            >
                <FaSearch
                    className="searchInputIcon"
                    color="#FFF"
                    size={18}
                />
            </label>

            <input
                id="searchInput"
                className="searchInput"
                type="text"
                onChange={onChange}
                placeholder="Pesquisar"
            />

        </div>
    )
}
