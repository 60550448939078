import { firestoreDatabase } from "../services/firebase"

export async function getUserDataById(userId, dataWanted) {
	const response = await firestoreDatabase.collection('user').doc(userId).get()
	const pupData = response.data()

	const returnedDataWanted = {}

	if (pupData) {
		dataWanted.forEach((dataItem) => {
			if (pupData[dataItem]) {
				returnedDataWanted[dataItem] = pupData[dataItem]
			} else {
				returnedDataWanted[dataItem] = null
			}
		})
	}

	return returnedDataWanted
}
