import React from 'react'

import './styles.scss'

export function Table({data, dataList, onRowCLick}) {

    return (
        <table id="Table">

            <tr>
                {dataList.map(item => (
                    <th key={`tableHeader_${item.key}`}>{item.label}</th>
                ))}
            </tr>

            {data.map(rowObject => (
                <tr key={rowObject.id} className={onRowCLick ? 'hasOnRowClick' : ''} onClick={() => {onRowCLick && onRowCLick(rowObject)}}>
                    {dataList.map(dataListItem => {

                        if (dataListItem.type === 'image') {
                            return <td key={`tableRow_${dataListItem.key}`}> <img src={rowObject[dataListItem.key]} alt="" /> </td> 
                        }

                        if (dataListItem.type === 'bool') {
                            return <td key={`tableRow_${dataListItem.key}`}>{rowObject[dataListItem.key] ? 'Sim' : 'Não'}</td>
                        }

                        if (dataListItem.type === 'color') {
                            return <td key={`tableRow_${dataListItem.key}`}>
                                        <div style={{backgroundColor: rowObject[dataListItem.key], borderRadius: 6, textAlign: 'center', padding: '5px 0'}}>
                                            {rowObject[dataListItem.key]}
                                        </div>
                                    </td>
                        }

                        // Se não tiver tipo definido retorna como String
                        return <td key={`tableRow_${dataListItem.key}`}>{rowObject[dataListItem.key]}</td>

                    })}
                </tr>
            ))}
        </table>
    )
}
