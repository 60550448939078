import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AuthContextProvider } from 'contexts/AuthContext'
import { EventsControllerContextProvider } from 'contexts/EventsController'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

import { routesNames } from './routesNames'
import { Home } from 'pages/Home'
import { TermsOfUse } from 'pages/Terms/termsOfUse'
import { PrivacyPolicy } from 'pages/Terms/PrivacyPolicy'
import { FinishPurchase } from 'pages/purchaseProcess/finishPurchase'

import { Login } from 'pages/Auth/Login'
import { CreateAccount } from 'pages/Auth/CreateAccount'

import { CreateStore } from 'pages/Manager/CreateStore'

import { PrivateRoute } from './PrivateRoute'
import { AdminRoutes } from './AdminRoutes'
import { ManagerRoutes } from './ManagerRoutes'



export function Routes() {

    document.querySelector('html').style.fontSize = '100%'

    return (
        <BrowserRouter>
            <AuthContextProvider>
                <EventsControllerContextProvider>
                    <Switch>
                        <Route path={routesNames.base.path} exact component={Home} />
                        <Route path={routesNames.termos.termosDeUso.path} exact component={TermsOfUse} />
                        <Route path={routesNames.termos.politicaDePrivacidade.path} exact component={PrivacyPolicy} />

                        <MuiThemeProvider>
                            <Switch>
                                <Route path={routesNames.entrar.path} component={Login} />
                                <Route path={routesNames.cadastro.novaConta.path} component={CreateAccount} />

                                <Route path={routesNames.purchaseProcess.finishPurchase.path} component={FinishPurchase} />

                                <PrivateRoute path={routesNames.cadastro.novoEstabelecimento.path} component={CreateStore} />

                                <Route path={routesNames.estabelecimento.index} component={ManagerRoutes} />

                                <Route path={routesNames.admin.index} component={AdminRoutes} />

                            </Switch>
                        </MuiThemeProvider>

                    </Switch>
                </EventsControllerContextProvider>
            </AuthContextProvider>
        </BrowserRouter>
    )
}
