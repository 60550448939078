import React from 'react'

import { LandingPage } from './LandingPage/LandingPage'

import './styles.scss'

export function Home() {
    document.querySelector('html').style.fontSize = '55%'

	return <LandingPage />
}
