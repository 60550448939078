import { firebaseFunctions, firestoreDatabase } from './firebase'

export function notification(userTargetId) {
	const notificationDatabase = firestoreDatabase.collection('notifications').doc(userTargetId).collection('notifications')

	async function sendMessage({
		userWhoPerformedTheAction, messageText, chatId, name,
	}) {
		notificationDatabase.doc(chatId).set({
			userWhoPerformedTheAction,
			messageText,
			createdAt: new Date().getTime(),
			type: 'message',
			name,
		})
	}
	
	async function sendDirectMessage({
		notificationToken, title, bodyMessage
	}) {
		console.log('NOTIFICACAO DIRETA: ', notificationToken, title, bodyMessage)

		const directPushNotification = firebaseFunctions.httpsCallable('directPushNotification')
		directPushNotification({ notificationToken, title, bodyMessage })
		.then(response => console.log(response))
		.catch((error) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;

			console.log('code: ', code)
			console.log('message: ', message)
			console.log('details: ', details)
		  })

	}

	return {
		sendMessage,
		sendDirectMessage,
	}
}
