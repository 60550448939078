
import './styles.scss'

// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Popover } from 'material-ui'
import Menu from 'material-ui/Menu';


export function HeaderAdmin() {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const callText = useMemo(() => {
        const hours = new Date().getHours()

        // Se for mais de 4 da manhã e antes de 12
        if (hours >= 4 && hours <= 12) return 'Bom dia!'  
        if (hours > 12 && hours <= 18) return 'Boa tarde!'
        if (hours > 18 || hours < 4) return 'Boa noite!'
    }, [])

    return (
        <header className="headerAdmin">

            <div>
                <h1>{callText}</h1>
                <h6>Confira o desempenho nos últimos dias</h6>
            </div>

            <div>
                <button class="buttonPopover" > {/* onClick={handleClick} */}
                    {/* <i class="fa fa-calendar"></i> */}
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#000" d="M11.6071 1.39286H10.2143V0.464294C10.2143 0.207867 10.0064 0 9.74999 0C9.49357 0 9.2857 0.207867 9.2857 0.464294V1.39286H3.71427V0.464294C3.71427 0.207867 3.50641 0 3.25001 0C2.99361 0 2.78571 0.207867 2.78571 0.464294V1.39286H1.39286C0.623602 1.39286 0 2.01646 0 2.78571V11.6071C0 12.3764 0.623602 13 1.39286 13H11.6071C12.3764 13 13 12.3764 13 11.6071V2.78571C13 2.01646 12.3764 1.39286 11.6071 1.39286ZM12.0714 11.6071C12.0714 11.8636 11.8635 12.0714 11.6071 12.0714H1.39286C1.13643 12.0714 0.928562 11.8636 0.928562 11.6071V5.57142H12.0714V11.6071ZM12.0714 4.64286H0.928562V2.78571C0.928562 2.52929 1.13643 2.32142 1.39286 2.32142H2.78571V3.24998C2.78571 3.50641 2.99358 3.71427 3.25001 3.71427C3.50643 3.71427 3.7143 3.50641 3.7143 3.24998V2.32142H9.28573V3.24998C9.28573 3.50641 9.49359 3.71427 9.75002 3.71427C10.0064 3.71427 10.2143 3.50641 10.2143 3.24998V2.32142H11.6072C11.8636 2.32142 12.0715 2.52929 12.0715 2.78571V4.64286H12.0714Z" />
                    </svg>
                    <span />
                    Última semana
                </button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onRequestClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Menu>
                        <p onClick={handleClose}>Últimos 3 dias</p>
                        <p onClick={handleClose}>Hoje</p>
                        <p onClick={handleClose}>Ontem</p>
                        <p onClick={handleClose}>última semana</p>

                        <div>
                            <input type="date" />
                        </div>
                    </Menu>
                </Popover>
            </div>

        </header>
    )
}

