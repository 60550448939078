import React, { useState, useEffect } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { auth } from 'services/firebase';

export function ModalEmailConfirm({ onConfirm }) {

	useEffect(() => {
		const subscriber = auth.onAuthStateChanged(function (user) {
			if (user.emailVerified) {
				onConfirm()
				console.log('Email foi verificado 2')
			}
			else {
				console.log('Email não foi verificado')
			}
		})

		return () => subscriber()
	}, [onConfirm])

	async function confirmEmailVerification() {
		window.location.reload()
	}

	return (
		<div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
			<br />
			<h5>Antes de prosseguir, você deve confirmar seu e-mail.</h5>
			<h5>Para isso, basta clicar no link enviado ao seu e-mail.</h5>
			<h5>Após, clique no botão abaixo.</h5>

			<br />

			<button
				onClick={confirmEmailVerification} 
				className="button-verified-email"
			>
				E-mail verificado
			</button>
			<br />
		</div>
	)
}
