import React, { useState, useEffect } from 'react';
// import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import './SchedulerModal.css'
import { firestoreDatabase } from 'services/firebase';

export function SchedulerModal({ event, storeId, onRequestClose }) {

	function handleSubmit(e) {
		e.preventDefault();
		onRequestClose()
	}

	function handleCancel(e) {
		e.preventDefault();
		onRequestClose()
	}

	const daysOffWeekNames = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

	const _id = () => Math.random().toString().replace('0.', '')

	const [daysOffWeek, setDaysOffWeek] = useState({
		'0': [ {id: _id(), start: '00:00', end: '23:59'}, ],
		'1': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '12:00', end: '13:00'}, {id: _id(), start: '18:00', end: '23:59'} ],
		'2': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '12:00', end: '13:00'}, {id: _id(), start: '18:00', end: '23:59'} ],
		'3': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '12:00', end: '13:00'}, {id: _id(), start: '18:00', end: '23:59'} ],
		'4': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '12:00', end: '13:00'}, {id: _id(), start: '18:00', end: '23:59'} ],
		'5': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '12:00', end: '13:00'}, {id: _id(), start: '18:00', end: '23:59'} ],
		'6': [ {id: _id(), start: '00:00', end: '08:00'}, {id: _id(), start: '13:00', end: '23:59'} ],
	})


	function handleAddNewDate(weekDayId) {
		setDaysOffWeek(old => {

			const dayWeek = old[weekDayId]

			dayWeek.push(
				{id: Math.random().toString(), start: 0, end: 0}
			)

			return ({...old, [weekDayId]: dayWeek})
		})
	}

	function handleRemoveDate(weekDayId, dayId) {

		setDaysOffWeek(old => {
			const dayWeek = old[weekDayId]

			const newDates = dayWeek.filter(item => item.id !== dayId)
			
			return {...old, [weekDayId]: newDates}
		})
	}

	function handleChangeDate(weekDayId, dayId, newText, key) {

		setDaysOffWeek(old => {

			const dayWeek = old[weekDayId]

			const newDates = dayWeek.map(item => item.id === dayId ? {...item, [key]: newText} : item)
			
			return {...old, [weekDayId]: newDates}
		})
	}


	async function handleConfirmSchedule() {
		console.log('handleConfirmSchedule', daysOffWeek)

		const resp = await firestoreDatabase.collection('services').where('storeId', '==', storeId).get()
		const servicesIds = resp.docs.map(doc => doc.id)
		console.log('servicesIds', servicesIds)

		const batch = firestoreDatabase.batch()

		const servicesRef = firestoreDatabase.collection('services')

		servicesIds.forEach(serviceId => {
			const serviceDocRef = servicesRef.doc(serviceId)
			batch.update(serviceDocRef, {
				'booking.0': daysOffWeek[0],
				'booking.1': daysOffWeek[1],
				'booking.2': daysOffWeek[2],
				'booking.3': daysOffWeek[3],
				'booking.4': daysOffWeek[4],
				'booking.5': daysOffWeek[5],
				'booking.6': daysOffWeek[6],
			})
		})

		batch.commit()
	}


	useEffect(() => {
		if (!storeId) {
			return
		}
		
		firestoreDatabase.collection('services').where('storeId', '==', storeId).limit(1).get().then(resp => {
			const serviceBooking = resp.docs.map(doc => doc.data().booking)[0]
			
			const bookingDaysOfWeek = {}

			for (let i = 0; i < 7; i+=1) {
				bookingDaysOfWeek[i.toString()] = serviceBooking[i.toString()]
			}

			setDaysOffWeek(bookingDaysOfWeek)
		})

	}, [storeId])

	return (
		<form onSubmit={handleSubmit}>

			{/* <h5>Por aqui você pode adicionar os horários que o estabelecimento não funcionará. Por exemplo: fins de semana, feriados entre outros.</h5> */}

			{/* <br />
			<br /> */}

			<h4>Adicione abaixo os horários que você não funciona:</h4>
			<br />

			<div className="schedule">
				{daysOffWeekNames.map((dayName, index) => (
					<div className="scheduleColumn" key={dayName}>
						<h6>
							{dayName}
						</h6>
						{daysOffWeek[index].map((day) => (
							<div className="scheduleItem">
								<div className="xCloseButton" onClick={() => handleRemoveDate(index, day.id)}>
									<i className="fa fa-close"></i>
								</div>
								<p>
									<small>Fechado das<br /></small>
									{/* {day.start} até {day.end} */}
									<input
										type="time"
										min="00:00"
										max="23:00"
										value={day.start}
										required
										onChange={(event) => handleChangeDate(index, day.id, event.target.value, 'start')}
									/>
									<br />até<br />
									<input
										type="time"
										min="00:00"
										max="23:00"
										value={day.end}
										required
										onChange={(event) => handleChangeDate(index, day.id, event.target.value, 'end')}
									/>
								</p>
							</div>
						))}
						<button onClick={() => handleAddNewDate(index)}>
							Adicionar
						</button>
					</div>
				))}
			</div>

			<br />

			<RaisedButton
				className={'mr-3 my-3'}
				label="Salvar grade de horários"
				primary={true}
				onClick={handleConfirmSchedule}
			/>

			<RaisedButton
				className={'mr-3 my-3'}
				label="Voltar"
				type="Cancel"
				onClick={handleCancel}
			/>

			<br />
			<br />
{/* 
			<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
				<TextField
					defaultValue={event.title}
					floatingLabelText="Título"
					onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, title: newValue }))}
					readOnly={!onCreatEvent}
				/>

				<TextField
					style={{ marginLeft: 40 }}
					defaultValue={event.description}
					floatingLabelText="Descrição"
					onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, description: newValue }))}
					readOnly={!onCreatEvent}
				/>

				<TextField
					defaultValue={event.start ? startDate.toISOString().substring(0, 16) : '.'}
					floatingLabelText="Horário de início"
					onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, start: newValue }))}
					readOnly={!onCreatEvent}
					type='datetime-local'
				/>

				<TextField
					style={{ marginLeft: 40 }}
					defaultValue={event.end ? endDate.toISOString().substring(0, 16) : '.'}
					floatingLabelText="Horário de fim"
					onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, end: newValue }))}
					readOnly={!onCreatEvent}
					type='datetime-local'
				/>
			</div> */}

			{/* <div>
				<RaisedButton
					className={'mr-3 my-3'}
					label="Criar"
					primary={true}
					type="submit"
				/>

				<RaisedButton
					className={'mr-3 my-3'}
					label="Confirmar serviço"
					primary={true}
					onClick={handleConfirmService}
				/>

				<RaisedButton
					className={'mr-3 my-3'}
					label="Voltar"
					type="Cancel"
					onClick={handleCancel}
				/>
			</div> */}

		</form>
	)
}
