import React, { useCallback, useEffect, useState, useContext } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { minTime, maxTime, calendarInitialState } from './constants'

// Components
import BigCalendar from 'react-big-calendar'
import Dialog from 'material-ui/Dialog'
import { Modal } from './Modal'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import ContentAdd from 'material-ui/svg-icons/content/add'

//Styles
import './styles/dragAndDrop/styles.css'
import './styles/less/styles.css'
import './styles/css/react-big-calendar.css'

import { firestoreDatabase } from 'services/firebase'
import { useAuth } from 'hooks/useAuth'
import { ChatCard } from 'components/ChatCard'
import { getUserDataById } from 'utils/getUserDataById'
import { notification } from 'services/pushNotification'
import { SchedulerModal } from './SchedulerModal'
import useSound from 'use-sound';

import beepSound from './micro_bell_notification.mp3'
import { CronJob } from 'services/cronJob'

BigCalendar.momentLocalizer(moment)

export function Calendar({ uid }) {
	const { user } = useAuth()

	const [play] = useSound(beepSound);

	const [lengthOfEventsWithVerifiedPayment, setLengthOfEventsWithVerifiedPayment] = useState(0)

	const [modalOpen, setModalOpen] = useState(false)
	const [modal, setModal] = useState({
		id: null,
		title: null,
		description: null,
		start: new Date(2021, 10, 25, 7, 0, 0),
		end: new Date(2021, 10, 25, 8, 0, 0),
	})
	const [showModalScheduler, setShowModalScheduler] = useState(false)

	const [events, setEvents] = useState([])

	const [openChats, setOpenChats] = useState([])

	useEffect(() => {
		const subscriber = firestoreDatabase
			.collection('event')
			.doc(user.storeId)
			.collection('nextEvents')
			.onSnapshot(responseDocuments => {
				const allNextEvents = []
				responseDocuments.forEach(documents => {
					const docData = documents.data()
					const minutesOffsetTimeZone = new Date().getTimezoneOffset()
					const timeToOffsetTimezone = minutesOffsetTimeZone * 60 * 1000

					allNextEvents.push({
						id: documents.id,
						...docData,
						title: docData.pupName + ' - ' + docData.serviceName,
						start: new Date(docData.start + timeToOffsetTimezone),
						end: new Date(docData.end + timeToOffsetTimezone),
					})
				})

				const eventsToShowStatus = ['payment-in-verification', 'verified-payment', 'order-accepted']
				const eventsToShow = allNextEvents.filter(event => eventsToShowStatus.includes(event.status))
	
				// setEvents(allNextEvents)
				setEvents(eventsToShow)
			})
		return () => subscriber()
	}, [user.storeId])


	useEffect(() => {
		const eventsWithVerifiedPayment = events.filter(event => event.status === 'verified-payment')
		const eventsVerifiedPaymentLength = eventsWithVerifiedPayment.length

		if (eventsVerifiedPaymentLength > lengthOfEventsWithVerifiedPayment) {
			play()
		}

		setLengthOfEventsWithVerifiedPayment(eventsVerifiedPaymentLength)
	}, [events, lengthOfEventsWithVerifiedPayment, play])


	useEffect(() => {
		const todayDate = new Date()
		const todayDateIso = todayDate.toISOString().split('T')[0]
		const todayTimestamp = todayDate.getTime()

		const cacheObjTimes = JSON.parse(localStorage.getItem('@my-pups-cache-obj-times'))

		if (cacheObjTimes && cacheObjTimes[todayDateIso]) return

		const timeOfDay = 24 * 60 * 60 * 1000
		const timeOfSevenDays = 7 * timeOfDay

		firestoreDatabase.collection('event').doc(user.storeId).collection('nextEvents').where('end', '<', todayTimestamp - timeOfSevenDays).get()
			.then(async response => {
				const totalOldEvents = response.size

				if (totalOldEvents >= 10) {
					CronJob.create(user.storeId)

					const obj = {
						[todayDateIso]: true
					}
					localStorage.setItem('@my-pups-cache-obj-times', JSON.stringify(obj))
				}

			})

	}, [user.storeId])


	function deleteBlock({ id }) {
		setEvents((oldEvents) => oldEvents.filter(existingBlock => existingBlock.id !== id))
	}


	function handleClose() {
		setModalOpen(false)
		setModal(calendarInitialState.modal)
	}

	function handleOpen(event) {

		const startDate = event.start

		const date = {}
		date[startDate.getFullYear()] = {
			[startDate.getMonth()]: {
				[startDate.getDate()]: {
					start: event.start.toISOString(),
					end: event.end.toISOString(),
				}
			}
		}
		console.log('date: ', date)

		setModalOpen(true)
		console.log('handleOpen: ', event)
		setModal(event)
	}


	function handleStartChat(data) {
		const alreadyExist = openChats.find(chat => chat.userId === data.userId)

		if (alreadyExist) {
			return
		}

		getUserDataById(data.userId, ['avatarUrl']).then((responseData) => {
			setOpenChats((oldChats) => [
				...oldChats,
				{ userId: data.userId, userName: data.userName, userPhoto: responseData.avatarUrl, lastMessage: '' }
			])
		})
	}

	const setLastMessage = useCallback((id, message) => {
		setOpenChats((oldChats) => oldChats.map(chat => chat.userId === id ? ({ ...chat, lastMessage: message }) : chat))
	}, [])

	function handleDisconnectChat(receiverId) {
		setOpenChats((oldChats) => oldChats.filter((item) => item.userId !== receiverId))
	}

	async function handleCancelService(data) {

		const userSnapshot = await firestoreDatabase.collection('user').doc(data.userId).get()
		const userData = userSnapshot.data()

		notification(data.userId).sendDirectMessage({
			notificationToken: userData.notificationToken,
			title: 'Serviço cancelado pelo estabelecimento.',
			bodyMessage: `O serviço de '${data.serviceName}' foi cancelado pelo estabelecimento. Seu dinheiro será devolvido integralmente.`,
		})

		firestoreDatabase.collection('event').doc(user.storeId).collection('nextEvents').doc(data.id)
			.update({
				status: 'canceled-by-manager',
				'statusInfos.canceled-by-manager-date': new Date().getTime()
			})

		firestoreDatabase.collection('orders').doc(data.id)
			.update({
				status: 'canceled-by-manager',
				'statusInfos.canceled-by-manager-date': new Date().getTime()
			})

		const { startCorrectServiceDateString, startCorrectServiceTimeString } = data

		firestoreDatabase.collection('services').doc(data.serviceId)
			.update({
				[`booking.${startCorrectServiceDateString}.${startCorrectServiceTimeString}`]: false,
			})
	}

	async function handleConfirmService(data) {

		const minutesOffsetTimeZone = new Date().getTimezoneOffset()
		const timeToOffsetTimezone = minutesOffsetTimeZone * 60 * 1000
		const dateStartEvent = new Date(data.start - timeToOffsetTimezone).toISOString()

		const startServiceDateString = dateStartEvent.substring(0, 10).split('-').reverse().join('/')
		const startServiceTimeString = dateStartEvent.substring(11, 16)

		const userSnapshot = await firestoreDatabase.collection('user').doc(data.userId).get()
		const userData = userSnapshot.data()

		notification(data.userId).sendDirectMessage({
			notificationToken: userData.notificationToken,
			title: 'Serviço aceito!',
			bodyMessage: `O serviço de '${data.serviceName}' foi confirmado para o dia ${startServiceDateString} as ${startServiceTimeString}`,
		})

		firestoreDatabase.collection('event').doc(user.storeId).collection('nextEvents').doc(data.id)
			.update({
				status: 'order-accepted',
				'statusInfos.order-accepted-date': new Date().getTime()
			})

		firestoreDatabase.collection('orders').doc(data.id)
			.update({
				status: 'order-accepted',
				'statusInfos.order-accepted-date': new Date().getTime()
			})
	}

	return (
		<div className={'row'}>
			<div style={{ height: 800, minWidth: 640 }} className={'col-9'}>
				<BigCalendar
					components={{
						eventWrapper: EventWrapper,
					}}
					views={{
						work_week: true,
						day: true,
						month: true,
						week: true,
					}}
					messages={{
						month: 'Mês',
						day: 'Dia',
						today: 'Hoje',
						work_week: 'Semana útil',
						week: 'Semana completa',
						previous: 'Anterior',
						next: 'Próximo',
					}}
					events={events}
					defaultView="week"
					defaultDate={new Date()}
					onSelectEvent={handleOpen}
					min={minTime}
					max={maxTime}
				/>
			</div>

			<Dialog
				title="Informações extras"
				modal={false}
				open={modalOpen}
				onRequestClose={handleClose}
				autoScrollBodyContent={true}
			>
				<Modal
					event={modal}
					onRequestClose={handleClose}
					onDeleteEvent={deleteBlock}
					onStartChat={handleStartChat}
					onCancelService={handleCancelService}
					onConfirmService={handleConfirmService}
				/>
			</Dialog>

			<Dialog
				title="Controle de horários"
				modal={false}
				open={showModalScheduler}
				onRequestClose={() => setShowModalScheduler(false)}
				autoScrollBodyContent={true}
			>
				<SchedulerModal
					storeId={user.storeId}
					event={modal}
					onRequestClose={() => setShowModalScheduler(false)}
				/>
			</Dialog>

			<div className={'col-2'}>
				<p>
					Horários fechados
				</p>
				<div>
					<FloatingActionButton
						mini={true}
						className={'m-2'}
						onClick={() => setShowModalScheduler(true)}
					>
						<ContentAdd />
					</FloatingActionButton>
				</div>
			</div>


			<div
				style={{
					display: 'flex',
					flexDirection: 'column-reverse',
					position: 'fixed',
					right: 0,
					bottom: '2%',
					overflow: 'visible',
					width: 300
				}}
			>
				{openChats.map(chat => (
					<ChatCard
						key={chat.userId}
						onLastMessageReceived={setLastMessage}
						managerId={user.storeId}
						handleDisconnectChat={handleDisconnectChat}
						{...chat}
					/>
				))}
			</div>

		</div>
	)

}

const EventWrapper = (props) => {

	const customClass = `${props.children.props.className} rbc-event--status_${props.event.status}`

	return (
		<div
			{...props.children.props}
			className={customClass}
		>
			{props.children.props.children}
		</div>
	)
}
