import React, { useState, useEffect } from 'react'
import TextField from 'material-ui/TextField';

import { firestoreDatabase } from 'services/firebase'
import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin';
import { CardContainer } from 'Layouts/CardContainer';
import { Table } from 'components/Table';
import { SearchInput } from 'components/SearchInput';

import './styles.scss'

const dataList = [
    {key: 'name', label: 'Nome'},
    {key: 'businessType', label: 'Tipo de negócio'},
    {key: 'description', label: 'Descrição'},
    {key: 'state', label: 'Estado'},
    {key: 'city', label: 'Cidade'},
    {key: 'neighborhood', label: 'Bairro'},
    {key: 'ordersCanceledByManager', label: 'Pedidos cancelados'},
    {key: 'telephone', label: 'Telefone'},
]


export function OrdersCanceled() {

    const [stores, setStores] = useState([])
    const [numberToFind, setNumberToFind] = useState(5)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        firestoreDatabase.collection('store').where('ordersCanceledByManager', '>=', numberToFind).get().then(response => {
            setStores(response.docs.map(store => ({...store.data(), id: store.id})).sort((a, b) => b.ordersCanceledByManager - a.ordersCanceledByManager))
        })
        
        const a = document.querySelector('#textFieldAlignCenterId1')
        if (a) {
            if (a.style) {
                a.style.textAlign = 'center'
            }
        }
    }, [numberToFind])

    const storesSearched = stores.filter(service => service.name.includes(searchText))

    return (
        <LayoutAdmin>

            <CardContainer size="large">
                <div>
                    <h2>Pedidos canceladas</h2>

                    <br />

                    <h4>
                        Listar prestadores de serviço com mais de {' '}
                        <TextField
                            id="textFieldAlignCenterId2"
                            style={{width: 40, textAlign: 'right', fontSize: 18}}
                            defaultValue={numberToFind}
                            onBlur={(event) => setNumberToFind(oldValue => Number.isNaN(Number(event.target.value)) ? oldValue : Number(event.target.value))}
                        /> 
                        {' '} ordens canceladas
                    </h4>

                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h4>Total encontrados: {stores.length} </h4>
                        <SearchInput onChange={(event) => setSearchText(event.target.value)} />
                    </div>

                    <br />
                </div>

                <Table
                    data={storesSearched}
                    dataList={dataList}
                    // onRowCLick={(rowData) => setEditService(rowData)}
                />
            </CardContainer>

        </LayoutAdmin>
    )
}
