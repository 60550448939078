import React, { useState, useEffect, useRef, useCallback } from 'react'

// Modules

// Assets
import avatarImg from '../../assets/images/cachorro-caramelo.jpg'
import { firebase, firestoreDatabase } from '../../services/firebase'
import { notification } from '../../services/pushNotification'
import { getUserDataById } from '../../utils/getUserDataById'

// Functions

// Components
import './styles.scss'

export function ChatWindow({senderId, receiverId, showChatWindow, handleLastMessageReceived}) {
    const dummy = useRef()

    const [formValue, setFormValue] = useState('')

	const [messages, setMessages] = useState([])
	const [thisChatId, setThisChatId] = useState()
	const [managerInfos, setManagerInfos] = useState({})
	const [loading, setLoading] = useState(true)
	const [realReceiverId, setRealReceiverId] = useState()


	async function getChatIdIfExist(_senderId, _receiverId) {
		try {
			const chatData = []
			const querySnapshot = await firestoreDatabase.collection('chatList')
				.where('uid1', 'in', [_senderId, _receiverId])
				.get()

			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data()
					if ((data.uid1 === _senderId && data.uid2 === _receiverId) || (data.uid1 === _receiverId && data.uid2 === _senderId)) {
						chatData.push({ ...data, id: doc.id })
					}
				}
			})

			if (chatData.length) {
				return chatData[0].id
			}
			return null
		} catch (error) {
			console.log('getChatIdIfExist - catch error: ', error)
		}

		return null
	}

	async function createChatForUsers(_senderId, _receiverId) {
		try {
			const docRef = await firestoreDatabase.collection('chatList').add({ uid1: _senderId, uid2: _receiverId })
			const createdChatId = docRef.id

			const receiverData = await getUserDataById(_receiverId, ['nickname', 'avatarUrl', 'uid'])
			const senderData = {
				'nickname': managerInfos.name,
				'avatarUrl': managerInfos.imageUrl,
				'uid': _senderId,
			}

			const usersData = [senderData, receiverData]

			const chatData = {
				createdAt: new Date().getTime(),
				usersData,
			}

			await firestoreDatabase.collection('chatMessages').doc(createdChatId).set({ messages: [] })
			await firestoreDatabase.collection('chat').doc(_senderId).update({
				[createdChatId]: {
					...chatData,
				},
			})
			await firestoreDatabase.collection('chat').doc(_receiverId).update({
				[createdChatId]: {
					...chatData,
				},
			})

			return createdChatId
		} catch (error) {
			console.log('createChatForUsers - catch error: ', error)
			return null
		}
	}


	const getChatId = useCallback(async (_senderId, _receiverId) => {
		try {
			const receivedChatIdIfExist = await getChatIdIfExist(_senderId, _receiverId)

            console.log('receivedChatIdIfExist: ', receivedChatIdIfExist)
			if (receivedChatIdIfExist) {
				setThisChatId(receivedChatIdIfExist)
				// navigation.replace(routesPaths.swipeTab.chatsStack.chat, {
				// 	chatId: receivedChatIdIfExist,
				// 	senderId,
				// 	receiverId,
				// })
				return null
			}

			const createdChatId = await createChatForUsers(_senderId, _receiverId)
            console.log('createdChatId: ', createdChatId)
			if (createdChatId) {
				setThisChatId(createdChatId)
			}
			return null
		} catch (error) {
			console.log('getChatId - catch error: ', error)
		}
		return null
	}, [])


    async function sendMessage(e) {
        e.preventDefault()

		const createdAt = new Date().getTime()

		const messageObject = {
			_id: Math.random().toString().substring(4, 12),
			text: formValue,
			createdAt,
			user: {
                _id: senderId,
                avatar: managerInfos.imageUrl,
                name: managerInfos.name,
            },
			viewedAt: 0,
		}

		try {
			await firestoreDatabase
				.collection('chatMessages')
				.doc(thisChatId)
				.collection('messages')
				.doc(`${createdAt}_${senderId}`)
				.set({
					...messageObject,
					createdAtServer: firebase.firestore.FieldValue.serverTimestamp(),
				})

			await firestoreDatabase.collection('chat').doc(senderId).update({
				[`${thisChatId}.lastMessage`]: {
					...messageObject,
					createdAtServer: firebase.firestore.FieldValue.serverTimestamp(),
				},
			})

			await firestoreDatabase.collection('chat').doc(realReceiverId).update({
				[`${thisChatId}.lastMessage`]: {
					...messageObject,
					createdAtServer: firebase.firestore.FieldValue.serverTimestamp(),
				},
			})
			
			const response = await firestoreDatabase.collection('user').doc(realReceiverId).get()
			const data = response.data()
			const { lasTimeVisible } = data

			const twentyMinutes = 1000 * 60 * 20

			const itsBeenTwentyMinutesFromUserReceiverOnline = (new Date().getTime() - lasTimeVisible) > twentyMinutes
			console.log('itsBeenTwentyMinutesFromUserReceiverOnline: ', itsBeenTwentyMinutesFromUserReceiverOnline)

			console.log('itsBeenTwentyMinutesFromUserReceiverOnline: ', realReceiverId, {
				chatId: thisChatId,
				userWhoPerformedTheAction: senderId,
				name: managerInfos.name,
				messageText: messageObject.text,
			})

			if (itsBeenTwentyMinutesFromUserReceiverOnline) {
				notification(realReceiverId).sendMessage({
					chatId: thisChatId,
					userWhoPerformedTheAction: senderId,
					name: managerInfos.name,
					messageText: messageObject.text,
				})
			}
			
			setFormValue('')

			if (dummy.current) {
				dummy.current.scrollIntoView({ behavior: 'smooth' })
			}
	
		} catch (error) {
			console.log('Error: ', error)
		}

    }

	useEffect(() => {
		getChatId(senderId, receiverId)
	}, [getChatId, receiverId, senderId])


	useEffect(() => {
		let subscriber = () => {}

		function listenForMessages() {
			subscriber = firestoreDatabase
				.collection('chatMessages')
				.doc(thisChatId)
				.collection('messages')
				.orderBy('createdAtServer', 'asc')
				.limit(50)
				// .onSnapshot('value', (doc) => {
				// 	if (doc.exists) {
				// 		const data = doc.data()
				// 		if (data.messages) {
				// 			const { messages: chatMessages } = data
				// 			setMessages([...chatMessages])
				// 			const lastMessage = chatMessages[chatMessages.length - 1]

				// 			if (lastMessage) {
				// 				if (lastMessage.text) {
				// 					handleLastMessageReceived(receiverId, lastMessage.text) // errror lastMessage = undefined
				// 				}
				// 			}
				// 		}
				// 	}
				// })
				.onSnapshot((snapshot) => {
					const chatMessages = snapshot.docs.map((doc) => doc.data())
					setMessages([...chatMessages])
					const lastMessage = chatMessages[chatMessages.length - 1]

					if (lastMessage) {
						if (lastMessage.text) {
							handleLastMessageReceived(receiverId, lastMessage.text) // errror lastMessage = undefined
						}
					}
					// setLoadingMoreMessages(snapshot.docs.length !== limitTotalMessages)
				})
		}

		if (thisChatId) {
			listenForMessages()
		}

		return () => subscriber()
	}, [handleLastMessageReceived, thisChatId, receiverId])


	useEffect(() => {
		if (!!thisChatId && !!senderId) {
			firestoreDatabase.collection('chatList').doc(thisChatId).get().then((response) => {
				const data = response.data()
				if (data.uid1 === senderId) {
					setRealReceiverId(data.uid2)
				} else {
					setRealReceiverId(data.uid1)
				}
			})
		}
	}, [thisChatId, senderId])


	useEffect(() => {
		async function fetchStorageData() {
			try {
				const response = await firestoreDatabase.collection('store').where('managerId', '==', senderId).limit(1).get()
				let data = {}
				
				response.forEach(documents => {
					data = {...documents.data()}
				})

				const { name, imageUrl } = data
				setManagerInfos({ name, imageUrl, })

				setTimeout(() => {
					setLoading(false)
				}, 200)
			} catch (error) {
				alert('Ah não! \n Tivemos um erro no servidor! Tente novamente daqui a pouco.')
			}
		}

		setLoading(true)
		fetchStorageData()
	}, [senderId])


    useEffect(() => {
        if (!loading && showChatWindow) {
			setTimeout(() => {
				if (dummy.current) {
					dummy.current.scrollIntoView({ behavior: 'smooth' })
				}
			}, 200);
		}
    }, [loading, showChatWindow])


    return (
        <div id="ChatWindowStyle">
            <main>
                {messages && messages.map(msg => <ChatMessage key={msg._id} message={msg} senderId={senderId} />)}
            
                <span ref={dummy}></span>
            </main>
        
            <form onSubmit={sendMessage} id="chatWindowFooter">
                <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Digite aqui..." />
            
                <button type="submit"><i className="fa fa-paper-plane"></i></button>
            </form>
        </div>
    )
}



function ChatMessage({message, senderId}) {
    const { text, createdAt, user } = message
    const { _id, avatar } = user // name

    const messageClass = senderId === _id ? 'sent' : 'received'

	const date = new Date(createdAt)
	const dateHour = date.toISOString().substring(11, 16)

    return (
        <>
            <div className={`message ${messageClass}`}>
                <img src={avatar || avatarImg} alt="Avatar" />
				<div>
					<p>{text}</p>
					<h6 className={messageClass}>{dateHour}</h6>
				</div>
            </div>
        </>
    )
  }

