import React, {useEffect, useState} from 'react'
import { Dialog } from 'material-ui'

import { useAuth } from '../../../hooks/useAuth'
import { firestoreDatabase } from '../../../services/firebase'
import { CardContainer } from 'Layouts/CardContainer'
import { Table } from 'components/Table'
import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin'
import { Modal } from './Modal'
import { SearchInput } from 'components/SearchInput'


const dataList = [
    {key: 'imageUrl', label: 'Imagem', type: 'image'},
    {key: 'label', label: 'Nome'},
    {key: 'active', label: 'Ativo', type: 'bool'},
    {key: 'color', label: 'Cor', type: 'color'},
    {key: 'splitsPercentage', label: '% de repasse'},
    {key: 'order', label: 'Ordem'},
]

export function ServicesOffered() {
    const { user } = useAuth()

    const [services, setServices] = useState([])
    const [editService, setEditService] = useState({id: undefined})
    const [searchText, setSearchText] = useState('')


    useEffect(() => {
        firestoreDatabase.collection('configurations').doc('services').collection('servicesList').get().then(response => {
            const servicesList = []
            response.forEach(doc => {
                console.log('servicesList: ', doc.data())
                servicesList.push({
                    ...doc.data(),
                    id: doc.id,
                })
            })
            setServices(servicesList)
        })
    }, [user])


    function handleEdit(data) {
        console.log('handleEdit data: ', data)
        setEditService({id: undefined})

        setServices((oldServices) => oldServices.map((oldService) => (
            oldService.id === data.id ? data : oldService
        )))
    }

    async function handleAddNewService() {
        const newServiceData = {
            active : false,
            color : "#000",
            id : Number(services.length).toString().padStart(3, '000'),
            imageUrl : "https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2F001?alt=media&token=66048313-fd53-4bcb-a505-4bd61acb5a2d",
            label : "Edite para alterar o nome.",
            order : services.length,
            splitsPercentage : 10,
        }

        await firestoreDatabase.collection('configurations').doc('services').collection('servicesList').doc(newServiceData.id).set(newServiceData)

        console.log('setServices: ', {...newServiceData})
        setServices((oldServices => [...oldServices, {...newServiceData}]))
    }

    const servicesSearched = services.filter(service => service.label.includes(searchText))

    return (
        <LayoutAdmin mainId="servicesOffered" mainClasses="centerContentMain">

            <CardContainer>
                <div>
                    <h2>Serviços</h2>
                    <br />

                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h4>Total de serviços: {services.length} | ativos: {services.filter(service => service.active).length} </h4>
                        <SearchInput onChange={(event) => setSearchText(event.target.value)} />
                    </div>

                    <br />
                </div>

                <Table
                    data={servicesSearched}
                    dataList={dataList}
                    onRowCLick={(rowData) => setEditService(rowData)}
                />

                <button className="btn btn-success btn-sm" style={{marginTop: 30, marginBottom: 20}} onClick={handleAddNewService}>
                    Adicionar novo serviço
                </button>
            </CardContainer>

            <Dialog
                title="Editar serviço"
                modal={false}
                open={editService.id}
                onRequestClose={() => setEditService({id: undefined})}
                autoScrollBodyContent={true}
            >
                <Modal
                    service={editService}
                    onRequestClose={() => setEditService({id: undefined})}
                    onEdit={handleEdit}
                />
            </Dialog>

            <Dialog
				title="Situação cadastral"
				modal={false}
				open={'a' === 'bank-account-in-verification'}
				autoScrollBodyContent={true}
			>
                <BankAccountInVerificationModal />
			</Dialog>
        </LayoutAdmin>
    )
}


function BankAccountInVerificationModal({navigateToFinancial}) {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <br />
            <h5>Seus dados ainda não foram verificados por nós.</h5>
            <h5>Antes de começar a oferecer seus serviços é preciso que você termine a verificação dos dados financeiros.</h5>
            <h5>Você pode acompanhar o progresso de sua análise na seção financeiro.</h5>

            <br />

            <button
                onClick={navigateToFinancial} 
                className="button-verified-email"
            >
                Ir para financeiro
            </button>
        </div>
    )
}

