import React from 'react'

import './PupCard.scss'

import { FaPen, FaEye, FaEyeSlash } from "react-icons/fa";

export function PupCard({animalClass, handleStartEditAnimalClass}) {


    return (
        <div id="PupCard" onClick={handleStartEditAnimalClass}>
            <p>{animalClass.name}</p>
            <FaPen className="editIcon" />
            {animalClass.visible === true && <FaEye className="eyeIcon" /> }
            {animalClass.visible === false && <FaEyeSlash className="eyeIcon" /> }
        </div>
    )
}
