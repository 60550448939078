import React, { useState, useEffect, useRef } from 'react'

import { useHistory } from 'react-router'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { InputUnForm } from '../../../components/InputUnForm'

import './styles.scss'

export function CreateAccount() {
    document.querySelector('html').style.fontSize = '100%'

    const history = useHistory()
    const formRef = useRef(null)

    const { user, firebaseCreateAccount} = useAuth()

    const [goToCreateStore, setGoToCreateStore] = useState(false)

    useEffect(() => {
        if (user && goToCreateStore) {
            history.push(routesNames.cadastro.novoEstabelecimento.path)
        }
    }, [history, user, goToCreateStore])

    
    async function handleSubmit(data) {
        const {email, password, confirmPassword, name} = data

        if (confirmPassword !== password) {
            alert('Espere! \nSuas senhas não coincidem.')
        }

        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                name: Yup.string()
                    .required(),
                email: Yup.string()
                    .email()
                    .required(),
                password: Yup.string()
                    .min(6)
                    .required(),
                confirmPassword: Yup.string()
                    .min(6)
                    .required(),
            })

            await schema.validate(data, {
                abortEarly: false,
            })

            firebaseCreateAccount(email, password, name)
            setGoToCreateStore(true)

        } catch (err) {
            const validationErrors = {}

            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    if (error.path) {
                        validationErrors[error.path] = error.message
                    }
                })
                formRef.current?.setErrors(validationErrors)
            }
        }

    }


    return (
        <div id="createAccount">

            <main>
                <Form ref={formRef} className="form-auth" onSubmit={handleSubmit}>
                    <h4>Para continuar você precisa ser o gerente do estabelecimento</h4>
                    <br />
                    <br />
                    <InputUnForm
                        name="name"
                        label="Digite seu nome"
                        type="text"
                        placeholder="Lucas Alves Silva"
                    />

                    <InputUnForm
                        name="email"
                        label="Digite seu e-mail"
                        type="text"
                        placeholder="lucas@silva.com"
                    />

                    <InputUnForm
                        name="password"
                        label="Digite sua senha"
                        type="password"
                        placeholder="******"
                    />

                    <InputUnForm
                        name="confirmPassword"
                        label="Digite sua senha"
                        type="password"
                        placeholder="******"
                    />

                    <button 
                        className="buttonAuth" 
                        type="submit"
                    >
                        Criar conta de gestor
                    </button>

                </Form>
            </main>

        </div>
    )
}
