import React, {useEffect, useState} from 'react'

import { PupCard } from './PupCard'
import { useAuth } from '../../../hooks/useAuth'
import { firestoreDatabase } from '../../../services/firebase'
import Dialog from 'material-ui/Dialog'
import { Modal } from './Modal'
import AutoComplete from 'material-ui/AutoComplete'
import TextField from 'material-ui/TextField'
import './index.scss'
import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin'
import { CardContainer } from 'Layouts/CardContainer'


export function Pups() {
    const { user } = useAuth()

    const [services, setServices] = useState([])
    const [changeAnimalClassData, setChangeAnimalClassData] = useState({showModal: false, animalClassData: {}})

    const [selectedAnimalClass, setSelectedAnimalClass] = useState('')
    const [selectedAnimalClassId, setSelectedAnimalClassId] = useState()
    const [allBreeds, setAllBreeds] = useState([])
    const [changeAnimalBreedData, setChangeAnimalBreedData] = useState({showModal: false, animalBreedData: {}})

    useEffect(() => {
        firestoreDatabase.collection('configurations').doc('pups').collection('animalsClasses').get().then(response => {
            const servicesList = response.docs.map(animalClass => ({...animalClass.data(), id: animalClass.id}))
            setServices(servicesList)
        })
    }, [])

    function handleAddNewAnimalClass() {
        const newServiceData = {
            name: 'Adicione um nome',
            visible: false,
        }

        firestoreDatabase.collection('configurations').doc('pups').collection('animalsClasses').add(newServiceData)
            .then((serviceResponse) => {
                const serviceDocumentId = serviceResponse.id
                console.log('setServices: ', {...newServiceData, id: serviceDocumentId})
                setServices((oldServices => [...oldServices, {...newServiceData, id: serviceDocumentId}]))
            })
    }

    function handleEditAnimalClass(data) {
        firestoreDatabase.collection('configurations').doc('pups').collection('animalsClasses').doc(data.id).update(data)
            .then(() => {
                setServices((oldServices) => oldServices.map((oldService) => (
                    oldService.id === data.id ? data : oldService
                )))
            })
    }

    async function handleDeleteAnimalClass(data) {
        console.log('handleDeleteAnimalClass ', data)

        await firestoreDatabase.collection('configurations').doc('pups').collection('animalsClasses').doc(data.id).delete()

        const breedsCollectionRef = firestoreDatabase.collection('configurations').doc('pups').collection('breeds').doc(data.id).collection('breeds')
        
        const snapshot = await breedsCollectionRef.get()
        const breedsListIds = snapshot.docs.map(animalClass => (animalClass.id))

		const batch = firestoreDatabase.batch()
        breedsListIds.forEach((breedId) => {
			batch.delete(breedsCollectionRef.doc(breedId))
		})

        await batch.commit()

        setServices((oldServices) => oldServices.filter((oldService) => (
            oldService.id !== data.id
        )))
    }
    
    useEffect(() => {
        if (!selectedAnimalClass) return

        const animalClassObj = services.find(service => service.name === selectedAnimalClass)
        if (!animalClassObj) return

        const animalClassId = animalClassObj.id

        firestoreDatabase.collection('configurations').doc('pups').collection('breeds').doc(animalClassId).collection('breeds').get()
        .then(response => {
            const breedsList = response.docs.map(animalClass => ({...animalClass.data(), id: animalClass.id}))
            setAllBreeds(breedsList.sort((a, b) => a.name.localeCompare(b.name)))
            setSelectedAnimalClassId(animalClassId)
        })
    }, [services, selectedAnimalClass])


    function handleAddNewAnimalSpecie() {
        const newServiceData = {
            name: 'Adicione um nome',
        }

        firestoreDatabase.collection('configurations').doc('pups').collection('breeds').doc(selectedAnimalClassId).collection('breeds')
            .add(newServiceData)
            .then((serviceResponse) => {
                const serviceDocumentId = serviceResponse.id
                console.log('setServices: ', {...newServiceData, id: serviceDocumentId})
                setAllBreeds((oldServices => [...oldServices, {...newServiceData, id: serviceDocumentId}].sort((a, b) => a.name.localeCompare(b.name))))
            })
    }

    function handleEditAnimalSpecie(data) {
        firestoreDatabase.collection('configurations').doc('pups').collection('breeds').doc(selectedAnimalClassId).collection('breeds')
            .doc(data.id)
            .update(data)
            .then(() => {
                setAllBreeds((oldServices) => oldServices.map((oldService) => (
                    oldService.id === data.id ? data : oldService
                )))
            })
    }

    async function handleDeleteAnimalSpecie(data) {
        await firestoreDatabase.collection('configurations').doc('pups').collection('breeds').doc(selectedAnimalClassId).collection('breeds')
            .doc(data.id)
            .delete()

            setAllBreeds((oldServices) => oldServices.filter((oldService) => (
            oldService.id !== data.id
        )))
    }
    
    return (
        <LayoutAdmin mainId="pupScreen" mainClasses="">
            
            <CardContainer>
                <h3>Classes de animais</h3>
                <div className="animalClassesContainer">
                        {services.map((service) => (
                            <PupCard
                                key={service.id}
                                animalClass={service}
                                handleStartEditAnimalClass={() => setChangeAnimalClassData({showModal: true, animalClassData: service})}
                            />
                        ))}

                    <button className="btn btn-success btn-sm" style={{width: 180, height: 80, margin: 5}} onClick={handleAddNewAnimalClass}>
                        Nova classe de animal
                    </button>
                </div>
            </CardContainer>
            
            <CardContainer>
                <div className="dividerAnimalsSpecies">
                    <h3>Espécies da classe: </h3>
                    <AutoComplete
                        id="autoCompId-classes"
                        dataSource={services.map(service => service.name)}
                        renderInput={(params) => <TextField {...params} label="Espécie" id="autoCompleteAnimalsSpecies" />}
                        value={selectedAnimalClass}
                        maxSearchResults={5}
                        onUpdateInput={(event) => setSelectedAnimalClass(event)}
                        textFieldStyle={{ fontSize: '20px', lineHeight: '20px', width: '200px'}}
                    />
                </div>
                <div className="animalClassesContainer">
                        {allBreeds.map((service) => (
                            <PupCard
                                key={service.id}
                                animalClass={service}
                                handleStartEditAnimalClass={() => setChangeAnimalBreedData({showModal: true, animalBreedData: service})}
                            />
                        ))}

                    {!!allBreeds.length && <button className="btn btn-success btn-sm" style={{width: 180, height: 80, margin: 5}} onClick={handleAddNewAnimalSpecie}>
                        Novo animal
                    </button>}
                </div>
            </CardContainer>


            <Dialog
                title="Editar classe de Animal"
                modal={false}
                open={changeAnimalClassData.showModal}
                onRequestClose={() => setChangeAnimalClassData({showModal: false, animalClassData: {}})}
                autoScrollBodyContent={true}
            >
                <Modal
                    animalClass={changeAnimalClassData.animalClassData}
                    onRequestClose={() => setChangeAnimalClassData({showModal: false, animalClassData: {}})}
                    onEdit={handleEditAnimalClass}
                    onDelete={handleDeleteAnimalClass}
                />
            </Dialog>

            <Dialog
                title="Editar animal"
                open={changeAnimalBreedData.showModal}
                onRequestClose={() => setChangeAnimalBreedData({showModal: false, animalBreedData: {}})}
                contentStyle={{width: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <Modal
                    animalClass={changeAnimalBreedData.animalBreedData}
                    onRequestClose={() => setChangeAnimalBreedData({showModal: false, animalBreedData: {}})}
                    onEdit={handleEditAnimalSpecie}
                    onDelete={handleDeleteAnimalSpecie}
                />
            </Dialog>

        </LayoutAdmin>

    )
}

