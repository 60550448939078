import { BackgroundAdmin } from "components/BackgroundAdmin";
import { SidebarNavigator } from "components/Sidebar";
import { PrivateRoute } from "./PrivateRoute";
import { routesNames } from "./routesNames";

import { AdminPanel } from 'pages/Admin/AdminPanel'
import { ServicesOffered } from 'pages/Admin/ServicesOffered'
import { Pups } from 'pages/Admin/Pups'
import { OrdersCanceled } from 'pages/Admin/OrdersCanceled'
import { OrdersExpired } from 'pages/Admin/OrdersExpired'
import { Redirect } from "react-router-dom";


export function AdminRoutes() {
    
    return (
        <BackgroundAdmin>
            <SidebarNavigator type="admin" />
            <PrivateRoute permissions={['admin']} path={routesNames.admin.metrics.path} component={AdminPanel} />
            <PrivateRoute permissions={['admin']} path={routesNames.admin.services.path} component={ServicesOffered} />
            <PrivateRoute permissions={['admin']} path={routesNames.admin.pups.path} component={Pups} />
            <PrivateRoute permissions={['admin']} path={routesNames.admin.ordersCanceled.path} component={OrdersCanceled} />
            <PrivateRoute permissions={['admin']} path={routesNames.admin.ordersExpired.path} component={OrdersExpired} />

            <PrivateRoute permissions={['admin']} exact path={routesNames.admin.index} component={() => <Redirect to={{ pathname: routesNames.admin.metrics.path }} />} />
            
        </BackgroundAdmin>
    )
} 
