import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import { FiLogOut } from "react-icons/fi";
import { useAuth } from 'hooks/useAuth'
import { routesNames } from 'routes/routesNames';
import myPupsLogo from 'assets/images/pups-logo-branco.png'

import './styles.scss'

export function SidebarNavigator({ type }) {
    const { user } = useAuth()

    const history = useHistory()
    const { logOffUser } = useAuth()
    const [name, setName] = useState('')

    function handleLogoff() {
        logOffUser()
    }

    const SidebarData = useMemo(() => {
        const routeArray = type === 'admin' ? routesNames.admin : routesNames.estabelecimento
        return [
            ...Object.values(routeArray).filter(object => object.path),
        ]
    }, [type])


    useEffect(() => {
        if (type === 'admin') return
        if (user) setName(user.storeName)
    }, [type, user])

    return (
        <aside id="sidebar-navigator">

            <nav className='nav-menu'>
                <ul className='nav-menu-items'>

                    <div>
                        <header>
                            {type === 'admin'
                                ? <img src={myPupsLogo} alt="" /> 
                                : <p> {name} </p>
                            }
                        </header>

                        <br />

                        {SidebarData.map((item, index) => (
                            <li key={index} className="nav-text">
                                <Link
                                    to={item.path}
                                    className={item.path === history.location.pathname ? "selected" : ""}
                                >
                                    {item.icon}&nbsp;&nbsp;{item.title}
                                </Link>
                            </li>
                        ))}
                    </div>

                    <li className="nav-text">
                        <div className="logout-button" onClick={handleLogoff}>
                            <FiLogOut />&nbsp;&nbsp;Sair da conta
                        </div>
                    </li>

                </ul>

            </nav>

        </aside>
    )
}

