
import React from 'react';

import './styles.scss'

export function BackgroundAdmin({children})  {

    return (
        <div className="backgroundAdmin">
            {children}
        </div>
    )
}


