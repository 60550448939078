
export const minTime = new Date();
minTime.setHours(0, 0, 0);

export const maxTime = new Date();
maxTime.setHours(23, 59, 59);

// export const minTime = new Date();
// minTime.setHours(7, 0, 0);

// export const maxTime = new Date();
// maxTime.setHours(20, 0, 0);

export const calendarInitialState = {
    events: [],
    people: [],
    modal: {
        id: null,
        title: null,
        desc: null,
        start: new Date(2021, 10, 25, 7, 0, 0),
        end: new Date(2021, 10, 25, 8, 0, 0),
    },
    modalOpen: false,
    peopleOpen: false,
}

export const eventsStatus = {
    'payment-in-verification': 'Pedido realizado pelo usuário, mas sem confirmação de pagamento ainda.',
    'verified-payment': 'Confirmação de pagamento realizada.',
    'order-accepted': 'Pedido aceito pelo gestor da loja.',
    'canceled-by-manager': 'Pedido cancelado pelo gestor da loja.',
    'order-expired': 'Pedido cancelado pelo gestor da loja.',
}
