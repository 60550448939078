import { createContext, useState, useEffect } from "react"

import { auth, firestoreDatabase } from '../services/firebase'

export const AuthContext = createContext({})

export function AuthContextProvider({ children }) {

	const [user, setUser] = useState(JSON.parse(localStorage.getItem('@my_pups_auth_token')))

	useEffect(() => {
		console.log('new user: ', user)
	}, [user])

	useEffect(() => {
		const userData = localStorage.getItem('@my_pups_auth_token')
		const user = JSON.parse(userData || '{}')

		if (user && user.id) {
			setUser(user)
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			if (user) {
				const { uid } = user
				if (!uid) {
					logOffUser()
				}
			} else {
				logOffUser()
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	async function firebaseSignIn(email, password) {
        try {
            const authResult = await auth.signInWithEmailAndPassword(email, password)
            if (authResult && authResult.user) {

				const {uid} = authResult.user

				if (!uid) {
					logOffUser()
					return
				}

				firestoreDatabase.collection('storeManager').doc(uid).get().then((snapshot) => {
					if (snapshot.exists) {
						const documentData = snapshot.data()
						
						if (documentData.roles.includes('admin')) {
							logInUser({uid, ...documentData})
							return
						}
			
						firestoreDatabase.collection('store').doc(documentData.storeId).get().then(storeResponse => {
							const storeData = storeResponse.data()
							const storeName = storeData.name
							logInUser({uid, ...documentData, storeName})
						})

					} else {
						logOffUser()
						return
					}
				})
            }
        } catch (error) {
            console.log('error: ', error)
        }
    }

	async function firebaseCreateAccount(email, password, name) {
        try {
            const authResult = await auth.createUserWithEmailAndPassword(email, password)
            if (authResult && authResult.user) {

				authResult.user.sendEmailVerification()
					.then((resp) => console.log('resp email ', resp))
					.catch((error) => {console.log('error email ', error)})

				const {uid} = authResult.user

				if (!uid) {
					logOffUser()
				}
				const userState = 'creatingNewStore'
				
				const userObj = {
					uid,
					userState,
					email,
					name,
					roles: ['store_manager'],
				}
				
				await firestoreDatabase.collection('chat').doc(uid).set({})
				
				firestoreDatabase.collection('storeManager').doc(uid).set(userObj)
				.then(() => {
                	logInUser(userObj)
				})
            } 
        } catch (error) {
            console.log('error: ', error)
			logOffUser()
        }
    }

	async function firebaseSendPasswordResetEmail (email) {
		auth.sendPasswordResetEmail(email)
	  }

	async function logInUser({uid, ...rest}) {
		const user = {
			id: uid,
			...rest
		}

		console.log('logInUser: ', user)

		localStorage.setItem('@my_pups_auth_token', JSON.stringify(user))
		setUser(user)
	}

	async function logOffUser() {
		localStorage.setItem('@my_pups_auth_token', null)
		auth.signOut().then(() => {
			setUser(null)
		})
	}

	function updateUser(newInfos) {
		setUser((oldUser) => {
			localStorage.setItem('@my_pups_auth_token', JSON.stringify({...oldUser, ...newInfos}))
			return {...oldUser, ...newInfos}
		})
	}

	return (
		<AuthContext.Provider value={{ user, firebaseSignIn, firebaseCreateAccount, logOffUser, firebaseSendPasswordResetEmail, updateUser }}>
			{children}
		</AuthContext.Provider>
	)
}
