import React, { useState, useEffect } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { timeToManagerConfirmAService } from 'config/constants';
import { firestoreDatabase } from 'services/firebase';

export function Modal({ event, onRequestClose, onEditEvent, onCreatEvent, onDeleteEvent, onStartChat, onCancelService, onConfirmService }) {
	const [thisEvent, setThisEvent] = useState({ ...event })
	const [timeout, setTimeout] = useState(2 * 60 * 60 * 1000)


	function handleSubmit(e) {
		e.preventDefault();
		onRequestClose()

		if (onEditEvent) {
			onEditEvent(event)
			return
		}
		if (onCreatEvent) {
			onCreatEvent({ ...thisEvent })
			return
		}
		if (onStartChat) {
			onStartChat({ ...thisEvent })
		}
	}

	function handleCancel(e) {
		e.preventDefault();
		onRequestClose()
	}

	function handleDeleteEvent(e) {
		e.preventDefault();
		onDeleteEvent(event)
		onRequestClose()
	}

	function handleCancelService(e) {
		e.preventDefault();
		onCancelService(event)
		onRequestClose()
	}

	function handleConfirmService(e) {
		e.preventDefault();
		onConfirmService(event)
		onRequestClose()
	}

	useEffect(() => {
		if (thisEvent.status === 'order-expired') onRequestClose() 
		if (thisEvent.status !== 'verified-payment') return 

		const subscriber = setInterval(() => {
			const verifiedPaymentDate = thisEvent.statusInfos['verified-payment-date']
			const actualDateTime = new Date().getTime()
			const date = timeToManagerConfirmAService - (actualDateTime - verifiedPaymentDate)
		
			setTimeout(date)
		}, 900);

		return () => {
			clearInterval(subscriber)
		}
	}, [onRequestClose, thisEvent.status, thisEvent.statusInfos])

	const minutesOffsetTimeZone = new Date().getTimezoneOffset()
	const timeToOffsetTimezone = minutesOffsetTimeZone * 60 * 1000

	const endDate = new Date(event.end - timeToOffsetTimezone)
	const startDate = new Date(event.start - timeToOffsetTimezone)


	return (
		<form onSubmit={handleSubmit} style={{height: '55vh'}}>

			{onCreatEvent && (
				<h5>Por aqui você pode adicionar os horários que o estabelecimento não funcionará. Por exemplo: fins de semana, feriados entre outros.</h5>
			)}

			{event.pupId ? <ShowPupInfos timeout={timeout} event={event} /> : (
				<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
					<TextField
						defaultValue={event.title}
						floatingLabelText="Título"
						onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, title: newValue }))}
						readOnly={!onCreatEvent}
					/>

					<TextField
						style={{ marginLeft: 40 }}
						defaultValue={event.description}
						floatingLabelText="Descrição"
						onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, description: newValue }))}
						readOnly={!onCreatEvent}
					/>

					<TextField
						defaultValue={event.start ? startDate.toISOString().substr(0, 16) : '.'}
						floatingLabelText="Horário de início"
						onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, start: newValue }))}
						readOnly={!onCreatEvent}
						type='datetime-local'
					/>

					<TextField
						style={{ marginLeft: 40 }}
						defaultValue={event.end ? endDate.toISOString().substr(0, 16) : '.'}
						floatingLabelText="Horário de fim"
						onChange={(event, newValue) => setThisEvent((oldState) => ({ ...oldState, end: newValue }))}
						readOnly={!onCreatEvent}
						type='datetime-local'
					/>
				</div>
			)}

			<div>
				{onCreatEvent ? event.title ? (
					<RaisedButton
						className={'mr-3 my-3'}
						label="Atualizar"
						primary={true}
						type="submit"
					/>
				) : (
					<RaisedButton
						className={'mr-3 my-3'}
						label="Criar"
						primary={true}
						type="submit"
					/>
				) : (
					<RaisedButton
						className={'mr-3 my-3'}
						label="Chat"
						primary={true}
						type="submit"
					/>
				)}

				{event.pupId && event.status === 'verified-payment' && (
					<>
						<RaisedButton
							className={'mr-3 my-3'}
							label="Cancelar serviço"
							secondary={true}
							onClick={handleCancelService}
						/>
						<RaisedButton
							className={'mr-3 my-3'}
							label="Confirmar serviço"
							primary={true}
							onClick={handleConfirmService}
						/>
					</>
				)}

				{event.type === 'block' && <RaisedButton
					className={'mr-3 my-3'}
					label="Deletar"
					secondary={true}
					type="Cancel"
					onClick={handleDeleteEvent}
				/>}

				<RaisedButton
					className={'mr-3 my-3'}
					label="Voltar"
					type="Cancel"
					onClick={handleCancel}
				/>
			</div>

		</form>
	)
}

function ShowPupInfos({event, timeout}) {

	const timeToExpireService = new Date(timeout).toISOString().substring(11, 19)

	const [vacinesCard, setVacinesCard] = useState([])

	async function handleLoadVacineCard() {
		const pupSnapshot = await firestoreDatabase.collection('pup').doc(event.pupId).get()
		const pupData = pupSnapshot.data()
		const pupMoreInfoId = pupData.moreInfoId

		const pupMoreInfoSnapshot = await firestoreDatabase.collection('pupMoreInfo').doc(pupMoreInfoId).get()
		const pupMoreInfoData = pupMoreInfoSnapshot.data()
		const pupVaccines = pupMoreInfoData.vaccines

		setVacinesCard(pupVaccines)
	}


	return (
		<>
			{timeout === 7200000 ? (
				<h5>{event.serviceName} - Pagamento aguardando efetivação.</h5>
			) :(
				<h5>{event.serviceName} - Faltam {timeToExpireService} para o serviço expirar.</h5>
			)}

			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.userName}
				floatingLabelText="Nome do dono"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.pupName}
				floatingLabelText="Nome do pup"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.sex}
				floatingLabelText="Sexo"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.age}
				floatingLabelText="Idade"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.weight}
				floatingLabelText="Peso"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.breed}
				floatingLabelText="Raça"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.pedigree === true ? "Esse pup possui Pedigree" : "Esse pup não possui Pedigree" }
				floatingLabelText="Pedigree"
				readOnly={true}
			/>
			<TextField
				style={{ marginLeft: 40 }}
				defaultValue={event.typeOfAnimal}
				floatingLabelText="Tipo de animal"
				readOnly={true}
			/>

			<br />

			{vacinesCard && !vacinesCard.length && <RaisedButton
				className={'mr-3 my-3'}
				label="Mostrar cartão de vacina"
				onClick={handleLoadVacineCard}
			/>}

			<br />

			<div style={{marginLeft: 40}}>
				{vacinesCard && !!vacinesCard.length && vacinesCard.map((vaccine, index) => (
					<p key={vaccine.description + '_' + index}>
						Vacina de {vaccine.name} - 1º dose em: {vaccine.date} - Próxima dose em: {vaccine.rememberDate}
					</p>
				))}

			{!vacinesCard && (
				<p>Esse pup não possui vacinas cadastradas.</p>
			)}

			</div>

		</>
	)
}
