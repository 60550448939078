import React, { useState, useEffect } from 'react'

import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { firebaseStorage, firestoreDatabase } from 'services/firebase'

import { MapIcon } from 'components/MapIcon'
import { useAuth } from 'hooks/useAuth'

import 'leaflet/dist/leaflet.css'
import { LIST_OF_SERVICES } from 'config/constants'
import { SidebarNavigator } from 'components/Sidebar'
import { Iugu } from 'services/iugu'


export function MyStore() {
    const {user, updateUser} = useAuth()
    const [loading, setLoading] = useState(true)

    const [position, setPosition] = useState({ latitude: -20.6575738, longitude: -43.7919448 })

    function handleMapClick(latlng) {
        const { lat, lng } = latlng
        setPosition({
            latitude: lat,
            longitude: lng
        })
    }

    const [name, setName] = useState('')
    const [about, setAbout] = useState('')
    const [numberForContact, setNumberForContact] = useState('')
    const [storePrice, setStorePrice] = useState('')
    const [storeIugu, setStoreIugu] = useState()

    const [storePreviewImage, setStorePreviewImage] = useState('')
    const [updateImage, setUpdateImage] = useState(false)

    const [cep, setCep] = useState('')
	const [address, setAddress] = useState({
		state: undefined,
		city: undefined,
		neighborhood: undefined,
		street: undefined,
	})

    const [listOfServices, setListOfServices] = useState(LIST_OF_SERVICES)
    const [selectedListOfServices, setSelectedListOfServices] = useState([])

	useEffect(() => {
        if (loading) {
            return
        }

		if (cep?.length !== 8) {
			return
		}

		const validCepRegex = /^[0-9]{8}$/

		if (validCepRegex.test(cep)) {
			const getAFetchServiceUrl = (cepToFetch) => {
				const randomInt = Math.random()
				if (randomInt > 0.5) {
					return `https://ws.apicep.com/cep/${cepToFetch}.json`
				}
				return `https://viacep.com.br/ws/${cepToFetch}/json/`
			}

			const serviceApiUrl = getAFetchServiceUrl(cep)

			fetch(serviceApiUrl)
				.then((response) => response.json())
				.then((response) => {
					setAddress({
						state: response.state || response.uf,
						city: response.city || response.localidade,
						neighborhood: response.district || response.bairro,
						street: response.address || response.logradouro,
					})
				})
				.catch((error) => { console.log(error) })
		}
	}, [cep, loading])

    
	async function asyncUploadImg(imageToUpload, userId, typeOfImage) {
		if (!imageToUpload) return null

		const response = await fetch(imageToUpload)
		const blob = await response.blob()

		const childPath = `/${userId}/${typeOfImage}`

		const task = await firebaseStorage.ref('store')
			.child(childPath)
			.put(blob)

		return task.ref.getDownloadURL()
	}

    async function handleSubmit(event) {
        event.preventDefault()

        if (!user.id) {
            return
        }

        const imageUrl = updateImage ? await asyncUploadImg(storePreviewImage, user.id, 'profile') : storePreviewImage

        const iuguUserToken = storeIugu.user_token
        await Iugu.updateSubConta(iuguUserToken, name)

        const storeData = {
            ...address,
            name,
            description: about,
            lat: position.latitude,
            lng: position.longitude,
            imageUrl,
            numberForContact,
            price: storePrice,
            typesOfServicesOffered: selectedListOfServices,
            managerId: user.id,
            cep
        }

        console.log('storeData: ', storeData)

        firestoreDatabase.collection('store').doc(user.storeId).update({...storeData}).then(() => {
            const storeName = storeData.name
            updateUser({storeName})
            alert('Informações atualizadas com sucesso!')
        })
    }

    function handleSelectItem(id) {
        
        const alreadySelected = selectedListOfServices.findIndex((item)=> item === id)

        if (alreadySelected >= 0) {
            const filteredItems = selectedListOfServices.filter(item => item !== id)
            setSelectedListOfServices(filteredItems)
        } else {
            if (selectedListOfServices.length > 9) return
            setSelectedListOfServices([...selectedListOfServices, id])
        }
    }

    function handleSelectStoreImage(event) {
        const files = event.target.files
        const selectedStoreImage = Array.from(files)[0]

        if(!selectedStoreImage) return

        const selectedStoreImagePreview = URL.createObjectURL(selectedStoreImage)

        setStorePreviewImage(selectedStoreImagePreview)
        setUpdateImage(true)
    }

    useEffect(() => {
        firestoreDatabase.collection('store').doc(user.storeId).get().then(storeResponse => {
            const storeData = storeResponse.data()

            setAddress({
                uf: storeData.uf,
                localidade: storeData.localidade,
                bairro: storeData.bairro,
                logradouro: storeData.logradouro,
            })
            setPosition({latitude: storeData.lat, longitude: storeData.lng})
            setName(storeData.name)
            setAbout(storeData.description)
            setNumberForContact(storeData.numberForContact)
            setStorePrice(storeData.price)
            setStorePreviewImage(storeData.imageUrl)
            setCep(storeData.cep)

            setStoreIugu(storeData.iugu)

            // const selectedListOfServicesValues = listOfServices
                // .filter(service => storeData.typesOfServicesOffered.includes(service.value))
                // .map((service) => service.id)

            setSelectedListOfServices(storeData.typesOfServicesOffered)
            setLoading(false)
        })
    }, [listOfServices, user.storeId])


	useEffect(() => {
		firestoreDatabase
            .collection('configurations')
            .doc('services')
            .collection('servicesList')
            .where('active', '==', true)
            .get()
            .then((documents) => {
                const servicesList = documents.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                const servicesListSorted = servicesList.sort((a, b) => a.order - b.order)
                console.log('servicesListSorted', servicesListSorted);
                setListOfServices(servicesListSorted)
            })
	}, [])

    if (loading) {
        return(
            <div style={{width: '100%'}}>
                <h1>Carregando...</h1>
            </div>
        )
    }

    return (
        <div style={{width: '100%'}}>
            <div id="page-create-store">

                <main onSubmit={handleSubmit}>
                    <form className="create-store-form">
                        <fieldset>
                            <legend>Seu estabelecimento</legend>

                            <MapContainer center={[position.latitude, position.longitude]} zoom={13} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LocationMarker lastPosition={position} handleMapClick={handleMapClick} />
                            </MapContainer>

                            <div className="input-block">
                                <label htmlFor="name">Nome do Estabelecimento</label>
                                <input id="name" value={name} onChange={event => setName(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="phone">Telefone para contato</label>
                                <input id="phone" value={numberForContact} onChange={event => setNumberForContact(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="about">Descrição <span>Máximo de 300 caracteres</span></label>
                                <textarea id="name" maxLength={300} value={about} onChange={event => setAbout(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="price">Valores</label>
                                <select name="price" onChange={(e) => setStorePrice(e.target.value)}>
                                    <option value={undefined} disabled selected>Selecione</option>
                                    <option value="$">Custo baixo - $</option>
                                    <option value="$$">Custo médio - $$</option>
                                    <option value="$$$">Custo alto - $$$</option>
                                </select>
                            </div>

                            <div className="input-block">
                                <label htmlFor="images">Foto de capa</label>

                                <div className="images-container">

                                    {storePreviewImage && (
                                        <img key={storePreviewImage} src={storePreviewImage} alt={name} />
                                    )}

                                    <label htmlFor="image[]" className="new-image">
                                        {storePreviewImage ? 'Trocar' : '+'}
                                    </label>
                                </div>
                                <input type="file" id="image[]" onChange={handleSelectStoreImage} />
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Endereço</legend>

                            <div className="input-block">
                                <label htmlFor="instructions">Digite seu CEP (Somente números)</label>
                                <input id="opening_hours" value={cep} onChange={event => setCep(event.target.value)} />
                            </div>

                            
                            {address?.uf && (
                                <>
                                    <div className="input-block">
                                        <label htmlFor="Estado">Estado</label>
                                        <input id="Estado" readOnly value={address.state} contentEditable={false} />
                                    </div>
                                    
                                    <div className="input-block">
                                        <label htmlFor="Cidade">Cidade</label>
                                        <input id="Cidade" readOnly value={address.city} contentEditable={false} />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="Bairro">Bairro</label>
                                        <input id="Bairro" readOnly value={address.neighborhood} contentEditable={false} />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="Logradouro">Logradouro</label>
                                        <input id="Logradouro" readOnly value={address.street} contentEditable={false} />
                                    </div>

                                </>
                            )}
                        </fieldset>

                        <fieldset>
                            <legend>
                                <h2>Serviços oferecidos</h2>
                                <span>Selecione um ou mais serviços que são oferecidos por você: <br />
                                <p style={{fontSize: 12, marginTop: 10, marginBottom: 0}}>Máximo 10 itens.</p>
                                </span>
                            </legend>

                            <ul className="items-grid">

                                {listOfServices.map((item)=>(
                                    <li
                                        key={item.id.toString()}
                                        onClick={() => handleSelectItem(item.id)}
                                        className={selectedListOfServices.includes(item.id) ? 'selected' : ''}
                                    >
                                        <img 
                                            // src={item.image_url}
                                            src={item.imageUrl}
                                            alt={item.label}
                                        />
                                        <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </fieldset>

                        <button className="confirm-button" type="submit">
                            Salvar
                        </button>
                    </form>
                </main>
            </div>
        </div>
    );
}

function LocationMarker({lastPosition, handleMapClick}) {
    const [position, setPosition] = useState(null)

    useEffect(() => {
        const latlng = {
            lat: lastPosition.latitude,
            lng: lastPosition.longitude
        }

        setPosition(latlng)
        map.flyTo(latlng, map.getZoom())

    }, [lastPosition, map])


    const map = useMapEvents({
        click(event) {
            setPosition(event.latlng)
            map.flyTo(event.latlng, map.getZoom())
            handleMapClick(event.latlng)
        },
    })

    return position === null ? null : (
        <Marker position={position} icon={MapIcon}>
        </Marker>
    )
}
