import React, { useState, useEffect } from 'react'

import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { firebaseStorage, firestoreDatabase } from 'services/firebase'

import { MapIcon } from 'components/MapIcon'
import { useHistory } from 'react-router'
import { useAuth } from 'hooks/useAuth'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Dialog from 'material-ui/Dialog'
import { ModalEmailConfirm } from './ModalEmailConfirm'

import ImageEx from 'assets/images/cachorro-caramelo.jpg'

import './styles.css'
import 'leaflet/dist/leaflet.css'
import { routesNames } from 'routes/routesNames'
import { LIST_OF_SERVICES } from 'config/constants'
import { Iugu } from 'services/iugu'
import { ModalTermsToConfirm } from './ModalTermsToConfirm'


export function CreateStore() {
    document.querySelector('html').style.fontSize = '100%'

    const history = useHistory()
    const {user, updateUser} = useAuth()

    const [confirmedEmail, setConfirmedEmail] = useState(false)
    const [confirmedTermsOfUse, setConfirmedTermsOfUse] = useState(false)


    const [position, setPosition] = useState({ latitude: -20.6575738, longitude: -43.7919448 })

    function handleMapClick(latlng) {
        const { lat, lng } = latlng
        setPosition({
            latitude: lat,
            longitude: lng
        })
    }

    const [name, setName] = useState('')
    const [about, setAbout] = useState('')
    const [numberForContact, setNumberForContact] = useState('')
    const [storePrice, setStorePrice] = useState('')

    const [storePreviewImage, setStorePreviewImage] = useState('')

    const [cep, setCep] = useState('')
	const [address, setAddress] = useState({
        state: undefined,
		city: undefined,
		neighborhood: undefined,
		street: undefined,
	})

    const [listOfServices] = useState(LIST_OF_SERVICES)
    const [selectedListOfServices, setSelectedListOfServices] = useState([])

	useEffect(() => {
		if (cep?.length !== 8) {
			return
		}

		const validCepRegex = /^[0-9]{8}$/

		if (validCepRegex.test(cep)) {
			const getAFetchServiceUrl = (cepToFetch) => {
				const randomInt = Math.random()
				if (randomInt > 0.5) {
					return `https://ws.apicep.com/cep/${cepToFetch}.json`
				}
				return `https://viacep.com.br/ws/${cepToFetch}/json/`
			}

			const serviceApiUrl = getAFetchServiceUrl(cep)

			fetch(serviceApiUrl)
				.then((response) => response.json())
				.then((response) => {
					setAddress({
						state: response.state || response.uf,
						city: response.city || response.localidade,
						neighborhood: response.district || response.bairro,
						street: response.address || response.logradouro,
                        cep: cep
					})
				})
				.catch((error) => { console.log(error) })
		}
	}, [cep])

    
	async function asyncUploadImg(imageToUpload, userId, typeOfImage) {
		if (!imageToUpload) return null

		const response = await fetch(imageToUpload)
		const blob = await response.blob()

		const childPath = `/${userId}/${typeOfImage}`

		const task = await firebaseStorage.ref('store')
			.child(childPath)
			.put(blob)

		return task.ref.getDownloadURL()
	}

    async function handleSubmit(event) {
        event.preventDefault()

        if (!user.id) {
            return
        }

        const imageUrl = await asyncUploadImg(storePreviewImage, user.id, 'profile')

        const iuguCreateSubConta = await Iugu.createSubConta(name)

        const storeData = {
            ...address,
            name,
            description: about,
            lat: position.latitude,
            lng: position.longitude,
            imageUrl,
            numberForContact,
            price: storePrice,
            typesOfServicesOffered: selectedListOfServices,
            managerId: user.id,
            createdAt: new Date().getTime(),
            ordersExpired: 0,
            ordersCanceledByManager: 0,
            iugu: {
                ...iuguCreateSubConta
            }
        }

        console.log('adicionando estabelecimento: ', storeData)

        firestoreDatabase.collection('store').add(storeData).then((docRef) => {
            updateUser({userState: null, storeId: docRef.id})
            Promise.all([
                firestoreDatabase.collection('event').doc(docRef.id).set({}),
                firestoreDatabase.collection('storeManager').doc(user.id).update({
                    storeId: docRef.id
                })
            ]).then(() => {
                alert('Conta criada com sucesso! Adicione suas informações financeiras para oferecer seus serviços.')
                history.push(routesNames.estabelecimento.financial.path)
            })

        })
    }
    
    function handleSelectItem(id) {
        
        const alreadySelected = selectedListOfServices.findIndex((item)=> item === id)

        if (alreadySelected >= 0) {
            const filteredItems = selectedListOfServices.filter(item => item !== id)
            setSelectedListOfServices(filteredItems)
        } else {
            if (selectedListOfServices.length > 9) return
            setSelectedListOfServices([...selectedListOfServices, id])
        }
    }

    function handleSelectStoreImage(event) {
        const files = event.target.files
        const selectedStoreImage = Array.from(files)[0]

        if(!selectedStoreImage) return

        const selectedStoreImagePreview = URL.createObjectURL(selectedStoreImage)

        setStorePreviewImage(selectedStoreImagePreview)
    }

    return (
        <MuiThemeProvider>
            <div id="page-create-store">

                <main onSubmit={handleSubmit}>
                    <form className="create-store-form">
                        <fieldset>
                            <legend>Seu estabelecimento</legend>

                            <MapContainer center={[position.latitude, position.longitude]} zoom={13} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LocationMarker handleMapClick={handleMapClick} />
                            </MapContainer>

                            <div className="input-block">
                                <label htmlFor="name">Nome do Estabelecimento</label>
                                <input id="name" value={name} onChange={event => setName(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="phone">Telefone para contato</label>
                                <input id="phone" value={numberForContact} onChange={event => setNumberForContact(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="about">Descrição <span>Máximo de 300 caracteres</span></label>
                                <textarea id="name" maxLength={300} value={about} onChange={event => setAbout(event.target.value)} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="price">Valores</label>
                                <select name="price" onChange={(e) => setStorePrice(e.target.value)}>
                                    <option value={undefined} disabled selected>Selecione</option>
                                    <option value="$">Custo baixo - $</option>
                                    <option value="$$">Custo médio - $$</option>
                                    <option value="$$$">Custo alto - $$$</option>
                                </select>
                            </div>

                            <div className="input-block">
                                <label htmlFor="images">Foto de capa</label>

                                <div className="images-container">

                                    {storePreviewImage && (
                                        <img key={storePreviewImage} src={storePreviewImage} alt={name} />
                                    )}

                                    <label htmlFor="image[]" className="new-image">
                                        {storePreviewImage ? 'Trocar' : '+'}
                                    </label>
                                </div>
                                <input type="file" id="image[]" onChange={handleSelectStoreImage} />
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Endereço</legend>

                            <div className="input-block">
                                <label htmlFor="instructions">Digite seu CEP (Somente números)</label>
                                <input id="opening_hours" value={cep} onChange={event => setCep(event.target.value)} />
                            </div>

                            
                            {address?.state && (
                                <>
                                    <div className="input-block">
                                        <label htmlFor="Estado">Estado</label>
                                        <input id="Estado" readOnly value={address.state} contentEditable={false} />
                                    </div>
                                    
                                    <div className="input-block">
                                        <label htmlFor="Cidade">Cidade</label>
                                        <input id="Cidade" readOnly value={address.city} contentEditable={false} />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="Bairro">Bairro</label>
                                        <input id="Bairro" readOnly value={address.neighborhood} contentEditable={false} />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="Logradouro">Logradouro</label>
                                        <input id="Logradouro" readOnly value={address.street} contentEditable={false} />
                                    </div>

                                </>
                            )}
                        </fieldset>


                        <fieldset>
                            <legend>
                                <h2>Serviços oferecidos</h2>
                                <span>
                                    Selecione um ou mais serviços que são oferecidos por você: <br />
                                    Lembre-se de selecionar somente serviços que você oferece. <br />
                                    Máximo 10
                                </span>
                            </legend>

                            <ul className="items-grid">

                                {listOfServices.map((item)=>(
                                    <li
                                        key={item.id.toString()}
                                        onClick={() => handleSelectItem(item.id)}
                                        className={selectedListOfServices.includes(item.id) ? 'selected' : ''}
                                    >
                                        <img 
                                            src={item.img}
                                            alt={item.label}
                                        />
                                        <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </fieldset>

                        <button className="confirm-button" type="submit">
                            Confirmar
                        </button>
                    </form>
                </main>
            </div>

			<Dialog
				title="Termos de uso"
				modal={false}
				open={!confirmedTermsOfUse}
				onRequestClose={() => {}}
				autoScrollBodyContent={true}
			>
				<ModalTermsToConfirm
					onConfirm={() => setConfirmedTermsOfUse(true)}
				/>
			</Dialog>

			<Dialog
				title="Antes de continuar você deve confirmar seu email."
				modal={false}
				open={!confirmedEmail}
				onRequestClose={() => {}}
				autoScrollBodyContent={true}
                scroll={'paper'}
			>
				<ModalEmailConfirm
					onConfirm={() => {
                        setConfirmedEmail(true)
                        setConfirmedTermsOfUse(false)
                    }}
				/>
			</Dialog>

        </MuiThemeProvider>
    );
}

function LocationMarker({handleMapClick}) {
    const [position, setPosition] = useState(null)

    const map = useMapEvents({
        click(event) {
            setPosition(event.latlng)
            map.flyTo(event.latlng, map.getZoom())
            handleMapClick(event.latlng)
        },
    })
  
    return position === null ? null : (
        <Marker position={position} icon={MapIcon}>
        </Marker>
    )
}
