import React, { useState } from 'react'

// Modules

// Assets
import avatarImg from '../../assets/images/cachorro-caramelo.jpg'

// Functions

// Components
import {ChatWindow} from '../ChatWindow'

import './styles.scss'


export function ChatCard({userId, userName, userPhoto, lastMessage, managerId, onLastMessageReceived, handleDisconnectChat}) {
    const hide = { display: 'none' }
    const show = { display: 'block' }

    const [showChatWindow, setShowChatWindow] = useState(false)

    return (
        <>
            <div id='chatContainer'>
                <div className="chat-box" style={showChatWindow ? show : hide}>
                    <div className="chatHeader" onClick={() => setShowChatWindow(false)}>
                        Chat com {userName} <i className="iconButton fa fa-close" onClick={() => handleDisconnectChat(userId)}></i>
                    </div>
                    <ChatWindow senderId={managerId} showChatWindow={showChatWindow} receiverId={userId} handleLastMessageReceived={onLastMessageReceived} />
                </div>
            </div>

            <div id="ChatCard" onClick={() => setShowChatWindow(true)}>
                <img src={userPhoto || avatarImg} style={{ width: 80, height: 80, alignSelf: 'center', borderRadius: 20 }} alt="Avatar do usuário" />
                <div>
                    <p>Nome: {userName}</p>
                    <p>{lastMessage}</p>
                </div>
            </div>
        </>
    )
}
