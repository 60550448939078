import React from 'react'

import './styles.scss'

export function LayoutAdmin({children, mainId, mainClasses}) {

    return (
        <div className="layoutAdmin">
            <main id={mainId} className={mainClasses}>
                {children}
            </main>
        </div>
    )
}
