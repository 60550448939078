
export const LIST_OF_SERVICES2 = [
	{ id: 0, label: 'Pet Shop', value: 'Pet Shop' },
	{ id: 1, label: 'Alimentação', value: 'Alimentacao' },
	{ id: 2, label: 'Veterinário', value: 'Veterinario' },
	{ id: 3, label: 'Não definido', value: 'undefined' },
	{ id: 4, label: 'Não definido', value: 'undefined' },
]

export const LIST_OF_SERVICES = [
	{
		order: 0, id: '000', color: '#e35c82', label: 'Banhos e Tosas', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 1, id: '001', color: '#f46a22', label: 'Produtos Pet Shop', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 2, id: '002', color: '#33d3c0', label: 'Hospitais e Clínicas', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 3, id: '003', color: '#c9df5d', label: 'Cuidado00res', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 4, id: '004', color: '#f6cc00', label: 'Passeadores', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 5, id: '005', color: '#e35c82', label: 'Creches', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 6, id: '006', color: '#f46a22', label: 'Hotéis', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 7, id: '007', color: '#33d3c0', label: 'Adestradores', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 8, id: '008', color: '#c9df5d', label: 'Farmácias de Manipulação', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 9, id: '009', color: '#f6cc00', label: 'Transportes', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 10, id: '010', color: '#e35c82', label: 'Alimentos Gourmet', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Falimentos%20preview.png?alt=media&token=0b8220c5-0558-4865-acd7-66b731b6ee6c',
	},
	{
		order: 11, id: '011', color: '#f46a22', label: 'Agropecuárias', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 12, id: '012', color: '#33d3c0', label: 'Planos de Saúde', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 13, id: '013', color: '#c9df5d', label: 'Adoção de Animais', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 14, id: '014', color: '#f6cc00', label: 'Venda de Animais', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 15, id: '015', color: '#e35c82', label: 'Massoterapia', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 16, id: '016', color: '#f46a22', label: 'Acupultura', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
	{
		order: 17, id: '017', color: '#33d3c0', label: 'Equoterapia', img: 'https://firebasestorage.googleapis.com/v0/b/my-pups-22b23.appspot.com/o/configurations%2FservicesList%2Fcasa%20preview.png?alt=media&token=287f7342-90f3-46da-8aa9-af7526982824',
	},
]

export const timeToManagerConfirmAService = 1 * 60 * 60 * 1000

export const TIME_OF_SERVICES = [
	// { id: 0, time: 15, label: 'min' },
	{ id: 0, time: 20, label: 'min' },
	{ id: 1, time: 30, label: 'min' },
	{ id: 2, time: 60, label: 'min' },
	{ id: 3, time: 2, label: 'horas' },
	{ id: 4, time: 3, label: 'horas' },
	{ id: 5, time: 4, label: 'horas' },
	// { id: 6, time: 1, label: 'dia' },
	// { id: 7, time: 2, label: 'dias' },
	// { id: 8, time: 3, label: 'dias' },
	// { id: 9, time: 4, label: 'dias' },
	// { id: 10, time: 4, label: 'dias' },
]

export const BANK_NAMES_AND_NUMBERS = [
	{bankNumber: '332', bankName: 'Acesso Soluções de Pagamento'},
	{bankNumber: '121', bankName: 'Agibank'},	// X
	{bankNumber: '752', bankName: 'BNP Paribas Brasil'},
	{bankNumber: '070', bankName: 'BRB'},
	{bankNumber: '173', bankName: 'BRL Trust DTVM'},
	{bankNumber: '218', bankName: 'BS2'},
	{bankNumber: '208', bankName: 'Banco BTG Pactual'},
	{bankNumber: '047', bankName: 'Banco Banese'},
	{bankNumber: '336', bankName: 'Banco C6'},
	{bankNumber: '412-x', bankName: 'Banco Capital S.A'}, 	// X
	{bankNumber: '707', bankName: 'Banco Daycoval'},
	{bankNumber: '125', bankName: 'Banco Genial'},
	{bankNumber: '613', bankName: 'Banco Omni'},
	{bankNumber: '212', bankName: 'Banco Original'},
	{bankNumber: '741', bankName: 'Banco Ribeirão Preto'},
	{bankNumber: '082', bankName: 'Banco Topazio'},
	{bankNumber: '003', bankName: 'Banco da Amazonia'},
	{bankNumber: '001', bankName: 'Banco do Brasil'},
	{bankNumber: '004', bankName: 'Banco do Nordeste'},
	{bankNumber: '021', bankName: 'Banestes'},
	{bankNumber: '037', bankName: 'Banpará'},	// X
	{bankNumber: '041', bankName: 'Banrisul'},
	{bankNumber: '237', bankName: 'Bradesco'},
	{bankNumber: '273', bankName: 'CCR de São Miguel do Oeste'},
	{bankNumber: '104', bankName: 'Caixa Econômica'},
	{bankNumber: '745', bankName: 'Citibank'},
	{bankNumber: '97', bankName: 'Cooperativa Central de Credito Noroeste Brasileiro'},
	{bankNumber: '403', bankName: 'Cora'},
	{bankNumber: '133', bankName: 'Cresol'},
	{bankNumber: '364', bankName: 'Gerencianet Pagamentos do Brasil'},
	{bankNumber: '384', bankName: 'Global SCM'},
	{bankNumber: '077', bankName: 'Inter'},
	{bankNumber: '341', bankName: 'Itaú'},
	{bankNumber: '376', bankName: 'JP Morgan'},
	{bankNumber: '383', bankName: 'Juno'},
	{bankNumber: '323', bankName: 'Mercado Pago'},
	{bankNumber: '389', bankName: 'Mercantil do Brasil'},
	{bankNumber: '746', bankName: 'Modal'},
	{bankNumber: '274', bankName: 'Money Plus'},
	{bankNumber: '655', bankName: 'Neon/Votorantim'},	// X
	{bankNumber: '237', bankName: 'Next'},
	{bankNumber: '260', bankName: 'Nubank'},
	{bankNumber: '301', bankName: 'PJBank'},
	{bankNumber: '290', bankName: 'Pagseguro'},
	{bankNumber: '380', bankName: 'Picpay'},	// X
	{bankNumber: '093', bankName: 'Polocred'},
	{bankNumber: '633', bankName: 'Rendimento'},
	{bankNumber: '422', bankName: 'Safra'},
	{bankNumber: '033', bankName: 'Santander'},
	{bankNumber: '756', bankName: 'Sicoob'},
	{bankNumber: '748', bankName: 'Sicredi'},
	{bankNumber: '197', bankName: 'Stone'},
	{bankNumber: '136', bankName: 'Unicred'},
	{bankNumber: '099', bankName: 'Uniprime'},
	{bankNumber: '084', bankName: 'Uniprime Norte do Paraná'},
	{bankNumber: '085', bankName: 'Via Credi'},
	{bankNumber: '355', bankName: 'Ótimo'},
]

export const nomes = [
	"Acesso Soluções de Pagamento",
	"BNP Paribas Brasil",
	"BRB",
	"BRL Trust DTVM",
	"BS2",
	"Banco BTG Pactual",
	"Banco Banese",
	"Banco C6",
	"Banco Daycoval",
	"Banco Omni",
	"Banco Original",
	"Banco Ribeirão Preto",
	"Banco Topazio",
	"Banco da Amazonia",
	"Banco do Brasil",
	"Banco do Nordeste",
	"Banestes",
	"Banrisul",
	"Bradesco",
	"CCR de São Miguel do Oeste",
	"Caixa Econômica",
	"Citibank",
	"Cooperativa Central de Credito Noroeste Brasileiro",
	"Cora",
	"Cresol",
	"Gerencianet Pagamentos do Brasil",
	"Global SCM",
	"Inter",
	"Itaú",
	"JP Morgan",
	"Juno",
	"Mercado Pago",
	"Mercantil do Brasil",
	"Modal",
	"Money Plus",
	"Neon",
	"Next",
	"Nubank",
	"PJBank",
	"Pagseguro",
	"Polocred",
	"Rendimento",
	"Safra",
	"Santander",
	"Sicoob",
	"Sicredi",
	"Stone",
	"Unicred",
	"Uniprime",
	"Uniprime Norte do Paraná",
	"Via Credi",
	"Votorantim",
	"Ótimo",
]

export const ESTADOS_DO_BRASIL = [
	{ id: 12, sigla: 'AC', nome: 'Acre', },
	{ id: 27, sigla: 'AL', nome: 'Alagoas', },
	{ id: 16, sigla: 'AP', nome: 'Amapá', },
	{ id: 13, sigla: 'AM', nome: 'Amazonas', },
	{ id: 29, sigla: 'BA', nome: 'Bahia', },
	{ id: 23, sigla: 'CE', nome: 'Ceará', },
	{ id: 53, sigla: 'DF', nome: 'Distrito Federal', },
	{ id: 32, sigla: 'ES', nome: 'Espírito Santo', },
	{ id: 52, sigla: 'GO', nome: 'Goiás', },
	{ id: 21, sigla: 'MA', nome: 'Maranhão', },
	{ id: 51, sigla: 'MT', nome: 'Mato Grosso', },
	{ id: 50, sigla: 'MS', nome: 'Mato Grosso do Sul', },
	{ id: 31, sigla: 'MG', nome: 'Minas Gerais', },
	{ id: 15, sigla: 'PA', nome: 'Pará', },
	{ id: 25, sigla: 'PB', nome: 'Paraíba', },
	{ id: 41, sigla: 'PR', nome: 'Paraná', },
	{ id: 26, sigla: 'PE', nome: 'Pernambuco', },
	{ id: 22, sigla: 'PI', nome: 'Piauí', },
	{ id: 33, sigla: 'RJ', nome: 'Rio de Janeiro', },
	{ id: 24, sigla: 'RN', nome: 'Rio Grande do Norte', },
	{ id: 43, sigla: 'RS', nome: 'Rio Grande do Sul', },
	{ id: 11, sigla: 'RO', nome: 'Rondônia', },
	{ id: 14, sigla: 'RR', nome: 'Roraima', },
	{ id: 42, sigla: 'SC', nome: 'Santa Catarina', },
	{ id: 35, sigla: 'SP', nome: 'São Paulo', },
	{ id: 28, sigla: 'SE', nome: 'Sergipe', },
	{ id: 17, sigla: 'TO', nome: 'Tocantins', },
]
