import { firebaseFunctions } from "./firebase";

async function create(storeId) {

    console.log('cronJob create: ', storeId)

    try {
        const addNewCronJobFunction = firebaseFunctions.httpsCallable('addNewCronJob')
        const response = await addNewCronJobFunction({ storeId })
        return response.data
    } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
    
        console.log('addNewCronJob Error: code: ', code, 'message: ', message, 'details: ', details)
    }
}

const CronJob = {
	create,
};

export {CronJob}
