import React from 'react'
import { Calendar } from './Calendar'
import { useAuth } from 'hooks/useAuth'
import { LayoutAdmin } from 'Layouts/LayoutAdmin/LayoutAdmin'
import { CardContainer } from 'Layouts/CardContainer'

export function Store() {

    const { user } = useAuth()

    document.querySelector('html').style.fontSize = '100%'

    return (
        <LayoutAdmin mainId="AdminPanel">
            <CardContainer size="large">
                <h3>Calendário</h3>
                <br />
                <Calendar uid={user.id} />
            </CardContainer>
        </LayoutAdmin>
    )
}
