import React from 'react'

import '../styles.css'
import myPupsLogo from '../../../assets/images/pups-logo-01.png'

export function TermsOfUse() {

	return (
		<div id="terms">
			<header>
				<img src={myPupsLogo} alt="My Pups logo" />
				<h1>
					Termos de uso
				</h1>
			</header>

			<main>
				<h2>
                    Termos de uso - Título
				</h2>
				<p>
                conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo
				</p>

				<h2>
                    Título 2
				</h2>
				<p>
                conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo - conteúdo
				</p>
			</main>

		</div>
	)
}

