import React from 'react'
import { SidebarNavigator } from 'components/Sidebar'

// Modules

// Assets

// Functions

// Components

export function Support() {

    return(
        <div style={{width: '100%'}}>
            <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <h4>Caso tenha algum problema pode entrar em contato através do email: contato@mypups.com.br</h4>
                <h4>Ou através de algumas de nossas redes sociais:</h4>
                <h6>Facebook   Instagram   Youtube</h6>
            </div>
        </div>
    )
}

