import React from 'react'

import { Link } from 'react-router-dom'


// Images
import myPupsLogo from '../../../assets/images/pups-logo-banner-grande.png'
import { routesNames } from '../../../routes/routesNames'
import loadingImg from './assets/images/footer-logo.png'

import homeHeroImg from './assets/images/homeHero.png'
import registerImg from './assets/images/register.png'
import accountImg from './assets/images/account.png'
import feedImg from './assets/images/feed.png'
import notificacoesImg from './assets/images/notificacoes.png'
import createAccountManagerImg from './assets/images/criar_conta_gestor.png'
import addServicesManagerImg from './assets/images/add_servicos_gestor.png'
import financialManagerImg from './assets/images/extrato_financeiro_gestor.png'
import servicosPerguntasImg from './assets/images/servicosPerguntas.png'

// SCSS
import './assets/sass/style.scss'

export function LandingPage() {
	return (
		<div id="lading-page">

			<header className="header header-2">
				<div className="container">
					<div className="header__wrapper">
						<div className="header__logo">
							<a href="#home-hero">
								<img src={myPupsLogo} className="myPups-logo-banner" alt="logo" />
							</a>
						</div>
						<div className="header__nav">
							<ul className="header__nav-primary">
								<li><a href="#home-hero"><i className="fad fa-home-alt"></i></a></li>
								<li className="nav__dropdown dropdown-wrapper" id="menu-1">
									<a href="#home-hero" className="nav__dropdown-info dropdown-info">
										Home
									</a>
								</li>
								<li><a href="#feature">Funcionalidades</a></li>
								<li><a href="#growth">Diferenciais</a></li>
								<li><a href="#preview">Conheça</a></li>
								<li><a href="#market">Parceiros</a></li>
								<li><a href="#faq">FAQ</a></li>
								<li>
									<Link
										to={routesNames.entrar.path}
									>
										Entrar como gestor
									</Link>
								</li>
							</ul>
							<span><i className="fas fa-times"></i></span>
						</div>
						<div className="header__bars">
							<div className="header__bars-bar header__bars-bar-1"></div>
							<div className="header__bars-bar header__bars-bar-2"></div>
							<div className="header__bars-bar header__bars-bar-3"></div>
						</div>
					</div>
				</div>
			</header>
			{/* <!--header section end--> */}


			{/* <!--hero section start--> */}
			<section id="home-hero" className="hero hero-2">
				<div className="hero__wrapper">
					<div className="container">
						<div className="row align-items-lg-center">
							<div className="col-lg-6 order-2 order-lg-1">
								<h1 className="main-heading color-black">Entre no mundo dos Pups</h1>
								<p className="paragraph"><span>My Pups</span> é uma plataforma completa que te ajuda a
									encontrar novos Pups, interagir entre eles e a encontrar serviços próximos a você
									e no horário desejado.</p>
								<div className="download-buttons">
									<a href="#" className="google-play">
										<i className="fab fa-google-play"></i>
										<div className="button-content">
											<h6>Baixe agora <span>Google Play</span></h6>
										</div>
									</a>
									<a href="#" className="apple-store">
										<i className="fab fa-apple"></i>
										<div className="button-content">
											<h6>Baixe agora <span>Apple Store</span></h6>
										</div>
									</a>
								</div>
							</div>
							<div className="col-lg-6 order-1 order-lg-2">
								<div className="questions-img hero-img">
									<img src={homeHeroImg} alt=" " />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--hero section end--> */}


			{/* <!--feature section start--> */}
			<section className="feature" id="feature">
				<div className="container">
					<h2 className="section-heading color-black">Surpreenda-se com todas as possibilidades.</h2>
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="feature__box feature__box--1">
								<div className="icon icon-1">
									<svg className="icon-b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
										<path fill="#FFF" d="M90.01,93.14c53.9,8.48,30.22-76.46,12.47-79.25-17.52-2.71-66.7,70.72-12.47,79.25Z"/>
										<path fill="#FFF" d="M45.67,126.62c47.01-6,6.86-70.65-8.76-68.54-15.72,2.01-38.18,74.57,8.76,68.54Z"/>
										<path fill="#FFF" d="M159.9,93.14c54.38-8.55,4.96-81.96-12.47-79.25-17.77,2.8-41.56,87.76,12.47,79.25Z"/>
										<path fill="#FFF" d="M204.23,126.62c29.68,3.81,33.76-24.96,22.42-52.05-2.44-5.82-7.45-15.7-13.65-16.49-15.58-2.18-55.75,62.55-8.76,68.54Z"/>
										<g>
											<path fill="#FFF" d="M127.28,154.38c-7.46-1.44-14.07,4.27-14.1,11.46-.03,6.52,5.26,11.84,11.75,11.84,7.28,0,13.07-6.68,11.52-14.22-.94-4.55-4.61-8.2-9.17-9.08Z"/>
											<path fill="#FFF" d="M124.96,101.65c-46.28,0-88.43,42.15-88.43,88.43,0,42.77,40.68,46.1,53.15,46.1,11.64,0,25.72-10.58,35.28-10.58s23.78,10.58,35.28,10.58c12.47,0,53.15-3.32,53.15-46.1,0-46.28-42.15-88.43-88.43-88.43Zm47.02,69.57c-.03,1.51-.18,3.03-.56,4.49-1.66,6.44-5.56,10.99-11.99,12.94-6.48,1.96-12.27,.38-17.23-4.2-.44-.41-.86-.85-1.28-1.26-.06,.02-.1,.03-.12,.05-.35,.29-.7,.59-1.06,.88-3.86,3.14-8.27,4.94-13.21,5.28-14.05,.95-25.71-10.29-25.1-24.36,.83-18.9,21.84-28.88,36.79-18.36,1.69,1.19,3.27,1.77,5.28,1.62,1.63-.13,3.28-.03,4.99-.03v1.2c0,7.35,0,14.7,0,22.05,0,3.31,2.26,5.87,5.37,6.14,3.09,.26,5.82-1.8,6.19-5.13,.46-4.2,.37-8.43-.33-12.63-3.19-19.07-21.62-32.09-40.7-28.76-19.27,3.36-32.14,21.55-28.82,40.74,3.75,21.7,26.17,34.71,46.87,27.16,1.88-.69,3.69-1.59,5.62-2.43,1.91,3.3,3.85,6.65,5.8,10.02-.19,.12-.31,.21-.44,.28-10.71,5.93-22.2,7.53-34.43,4.68-6.04-1.41-11.74-4.12-16.66-7.89-9.98-7.65-16.11-17.52-18.31-29.59-.3-1.65-.47-3.33-.7-4.99-.01-2.06-.02-4.09-.04-6.15,.14-1.15,.26-2.3,.43-3.45,1.48-10.26,5.81-19.13,13.07-26.53,7.05-7.17,15.54-11.67,25.46-13.42,1.71-.3,3.44-.48,5.16-.72h5.88c.24,.05,.47,.13,.71,.15,7.17,.54,13.89,2.59,20.04,6.29,12.02,7.23,19.53,17.72,22.46,31.47,1.02,4.78,.96,9.62,.87,14.45Z"/>
										</g>
									</svg>
								</div>
								<div className="feature__box__wrapper">
									<div className="feature__box--content feature__box--content-1">
										<h3>Rede social de Pup para Pup</h3>
										<p className="paragraph dark">Rede social feita com foco na interação entre pups, curta, comente e compartilhe
											as novidades de seus pups.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="feature__box feature__box--2">
								<div className="icon icon-2">
								<svg className="icon-b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
									<path fill="#FFF" d="M41.78,37.15c-13.15,0-30.7,27.32-30.7,37.76s19.3,22.96,27.67,18.89l.22-.63c.16,.7,.25,1.42,.44,2.1-6.83,7.01-11.04,16.53-11.04,27.02,0,21.78,18.11,39.38,40.35,39.1h5.54v-29.25l-9.3-9.3h39.32l-9.3,9.3v29.27h5.54c22.24,.27,40.37-17.34,40.37-39.12,0-10.48-4.24-20.01-11.06-27.02,.1-.38,.14-.77,.24-1.15,8.93,2.29,26.21-9.31,26.21-19.21,0-10.44-17.54-37.76-30.7-37.76-4.4,0-12.65,2.77-14.18,8.29-7.61-5.2-16.82-8.29-26.78-8.29-10.92,0-20.83,3.81-28.8,9.95l.36-.99c-1-5.99-9.82-8.96-14.4-8.96Z"/>
									<path fill="#FFF" d="M229.78,141.86s4.57-4.73,4.57-14.2c0-16.56-9.12-28.38-9.12-28.38l-22.82,18.93s-10.21-4.73-27.39-4.73c-4.78,0-9.02,.36-12.64,.89l-1.29,.85c.28,2.34,.51,4.7,.51,7.08,0,16.05-6.33,31.14-17.86,42.45-8.95,8.78-20.22,14.35-32.42,16.4-.03,1.14-.15,2.11-.16,3.3,0,0,16,28.4,63.87,28.4s63.89-28.4,63.89-28.4c-.23-30.39-9.14-42.6-9.14-42.6Zm-53.3,43.75l-10.27-13.83h20.55l-10.27,13.83Z"/>
									<path fill="#FFF" d="M225.23,67.16c-.13,.44-.22,.89-.41,1.3-.95,2.08-2.59,3.15-4.88,3.16-6.05,.02-12.11,0-18.16,0h-.73c0,.28,0,.5,0,.73,0,6.07,0,12.14,0,18.22,0,2.86-2.18,5.13-4.94,5.22-2.96,.1-5.36-2.2-5.36-5.2-.02-6.07,0-12.14,0-18.22,0-.23,0-.45,0-.75h-.7c-6.07,0-12.14,0-18.22,0-2.86,0-5.15-2.16-5.25-4.91-.11-2.97,2.17-5.38,5.17-5.39,4.39-.02,8.78,0,13.17,0,1.74,0,3.48,0,5.21,0h.61v-.73c0-5.92,0-11.84,0-17.76,0-3.1,1.36-4.85,4.37-5.63,.04,0,.07-.04,.1-.06h1.37s.06,.05,.1,.06c2.99,.76,4.37,2.53,4.37,5.63,0,5.92,0,11.84,0,17.76v.73h.73c6.05,0,12.11,0,18.16,0,1.87,0,3.39,.74,4.41,2.32,.41,.64,.6,1.43,.88,2.15v1.37Z"/>
								</svg>

								</div>
								<div className="feature__box__wrapper">
									<div className="feature__box--content feature__box--content-2">
										<h3>Encontre novos pups</h3>
										<p className="paragraph dark">Descubra novos amigos através do nosso sistema de busca, selecione as características
											que deseja e encontre pups de sua preferência.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="feature__box feature__box--3">
								<div className="icon icon-3">
									<svg className="icon-b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
										<path fill="#FFF" d="M243.45,102.97c-1.73-17.93-9.39-33.21-22.29-45.74-12.7-12.34-27.92-19.38-45.62-20.93-.4-.03-.78-.22-1.17-.33H75.71c-5.06,.97-10.22,1.57-15.16,2.96-17.95,5.06-31.96,15.66-42.24,31.15-6.84,10.3-10.76,21.69-11.82,34.03-.04,.48-.23,.94-.35,1.41v10.36c.61,3.47,.97,7,1.86,10.39,8.33,31.75,28.8,50.66,60.85,57.16,3.75,.76,7.6,.98,11.79,1.5,0,1.85,0,4.03,0,6.21,0,4.85,.03,9.7,0,14.55-.02,3.2,1.02,5.82,3.92,7.42,2.93,1.61,5.68,.99,8.34-.78,13.25-8.85,26.5-17.71,39.81-26.46,1.27-.83,2.99-1.32,4.52-1.35,12.9-.22,25.83,.25,38.69-.56,18.35-1.16,34.05-8.92,46.81-22.09,15.87-16.38,22.93-36.14,20.73-58.89Zm-162.55,6.12c-2.35,0-4.3-1.06-5.95-2.5l-15.35,15.35c1.43,1.64,2.5,3.6,2.5,5.95,0,5.17-4.23,9.4-9.4,9.4s-9.4-4.23-9.4-9.4c-5.17,0-9.4-4.23-9.4-9.4s4.23-9.4,9.4-9.4c2.35,0,4.3,1.06,5.95,2.5l15.35-15.35c-1.43-1.64-2.5-3.6-2.5-5.95,0-5.17,4.23-9.4,9.4-9.4s9.4,4.23,9.4,9.4c5.17,0,9.4,4.23,9.4,9.4s-4.23,9.4-9.4,9.4Zm62.89,0c-2.35,0-4.3-1.06-5.95-2.5l-15.35,15.35c1.43,1.64,2.5,3.6,2.5,5.95,0,5.17-4.23,9.4-9.4,9.4s-9.4-4.23-9.4-9.4c-5.17,0-9.4-4.23-9.4-9.4s4.23-9.4,9.4-9.4c2.35,0,4.3,1.06,5.95,2.5l15.35-15.35c-1.43-1.64-2.5-3.6-2.5-5.95,0-5.17,4.23-9.4,9.4-9.4s9.4,4.23,9.4,9.4c5.17,0,9.4,4.23,9.4,9.4s-4.23,9.4-9.4,9.4Zm62.89,0c-2.35,0-4.3-1.06-5.95-2.5l-15.35,15.35c1.43,1.64,2.5,3.6,2.5,5.95,0,5.17-4.23,9.4-9.4,9.4s-9.4-4.23-9.4-9.4c-5.17,0-9.4-4.23-9.4-9.4s4.23-9.4,9.4-9.4c2.35,0,4.3,1.06,5.95,2.5l15.35-15.35c-1.43-1.64-2.5-3.6-2.5-5.95,0-5.17,4.23-9.4,9.4-9.4s9.4,4.23,9.4,9.4c5.17,0,9.4,4.23,9.4,9.4s-4.23,9.4-9.4,9.4Z"/>
									</svg>
								</div>
								<div className="feature__box__wrapper">
									<div className="feature__box--content feature__box--content-3">
										<h3>Chat integrado</h3>
										<p className="paragraph dark">Converse com seus amigos e compartilhe as novidades de seus filhotes através do
											nosso chat.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="feature__box feature__box--4">
								<div className="icon icon-4">
									<svg className="icon-b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
										<path fill="#FFF" d="M237.79,114.36l-12.54-65.39c-.38-1.97-2.1-3.39-4.1-3.39h-37.62V7.95c0-2.31-1.87-4.18-4.18-4.18H70.66c-2.31,0-4.18,1.87-4.18,4.18V45.58H28.85c-2,0-3.73,1.42-4.1,3.39L12.21,114.36c-.05,.26-.08,.52-.08,.79,0,9.5,5.03,17.83,12.54,22.52v104.37c0,2.31,1.87,4.18,4.18,4.18H221.15c2.31,0,4.18-1.87,4.18-4.18v-104.37c7.52-4.7,12.54-13.03,12.54-22.52,0-.26-.02-.53-.08-.79ZM151.41,36.16c2.61,.33,8.47,16.6-1.95,15.27-10.42-1.33-.66-15.6,1.95-15.27Zm23.75,59.58v19.41c0,10.04-8.17,18.21-18.21,18.21h-9.56c-10.04,0-18.21-8.17-18.21-18.21v-19.41h45.98ZM133,22.84c3.07-.48,15.1,16.04,2.83,17.97-12.27,1.93-5.9-17.49-2.83-17.97Zm-15.99,0c3.07,.48,9.44,19.9-2.83,17.97-12.27-1.93-.24-18.46,2.83-17.97Zm-18.42,13.32c2.61-.33,12.38,13.93,1.95,15.27-10.42,1.33-4.56-14.93-1.95-15.27Zm-50.33,97.2h-9.56c-9.92,0-18.01-7.97-18.21-17.84l11.81-61.59h34.17v61.21c0,10.04-8.17,18.21-18.21,18.21Zm55.83,104.51H58.12v-75.24h45.98v75.24Zm16.72-122.72c0,10.04-8.17,18.21-18.21,18.21h-9.56c-10.04,0-18.21-8.17-18.21-18.21v-19.41h45.98v19.41Zm4.18-42.32c-.39,.06-1.31,.44-1.93,.69-1.51,.62-3.22,1.32-5.1,1.32-8.52,0-14.02-4.8-14.02-12.23,0-10.81,9.84-20.29,21.05-20.29s21.05,9.48,21.05,20.29c0,7.43-5.5,12.23-14.02,12.23-1.88,0-3.59-.7-5.1-1.32-.62-.26-1.55-.63-1.93-.69Zm71.06,131.6c0,2.31-1.87,4.18-4.18,4.18h-58.52c-2.31,0-4.18-1.87-4.18-4.18v-45.98c0-2.31,1.87-4.18,4.18-4.18h58.52c2.31,0,4.18,1.87,4.18,4.18v45.98Zm15.23-71.06h-9.56c-10.04,0-18.21-8.17-18.21-18.21V53.94h34.17l11.81,61.59c-.2,9.87-8.29,17.84-18.21,17.84Z"/>
									</svg>
								</div>
								<div className="feature__box__wrapper">
									<div className="feature__box--content feature__box--content-4">
										<h3>Ache serviços perto de você</h3>
										<p className="paragraph dark">Encontre lojas, Pet Shops, casas de ração, clínicas e tudo que seus pups precisam
											através do nosso sistema de busca.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--feature section end--> */}


			{/* <!--growth section start--> */}
			<section className="growth" id="growth">
				<div className="growth__wrapper">
					<div className="container">
						<h2 className="section-heading color-black">Muito mais em um aplicativo só.</h2>
						<div className="row">
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
										<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
											<path fill="#3890f0" d="M245.03,144.75c-.72,4.14-1.22,8.33-2.2,12.4-7.79,32.42-35.18,56-68.36,58.98-39.11,3.51-74.91-23.19-82.66-61.65-7.98-39.61,15.9-78.71,54.84-89.82,.59-.17,1.17-.36,1.88-.58-4.09-11.2-1.9-20.59,8.19-27.27,7.26-4.82,16.72-4.14,23.71,1.15,7.72,5.84,9.81,14.25,6.37,26.08,1.98,.64,4,1.18,5.95,1.93,30.1,11.57,47.49,33.25,51.84,65.27,.08,.6,.28,1.19,.43,1.79v11.72Zm-70.33-68.41c0,1.89,0,3.59,0,5.3-.01,5-2.73,8.21-6.98,8.24-4.25,.03-7.07-3.2-7.08-8.13,0-1.78,0-3.56,0-5.36-30.29,2.68-53.89,29.21-55.58,55.7,1.95,0,3.89-.02,5.83,0,4.37,.05,7.51,2.96,7.54,6.97,.03,4.03-3.09,7.02-7.43,7.08-2.01,.03-4.03,0-6.06,0,2.68,30.32,29.23,53.86,55.7,55.58,0-1.95-.02-3.89,0-5.83,.05-4.37,2.96-7.51,6.97-7.54,4.02-.03,7.02,3.1,7.08,7.43,.03,2.01,0,4.03,0,6.06,30.02-2.63,53.83-28.92,55.58-55.69-1.79,0-3.58,0-5.36,0-4.77-.02-7.97-2.81-8-6.98-.03-4.18,3.18-7.06,7.9-7.08,1.86,0,3.72,0,5.59,0-2.42-27.77-25.89-52.81-55.69-55.75Zm0-21.7c-.05-3.79-3.26-6.95-7.05-6.94-3.79,.01-6.98,3.2-7,6.99-.02,3.88,3.29,7.14,7.17,7.06,3.79-.08,6.93-3.32,6.88-7.11Z"/>
											<path fill="#3890f0" d="M40.61,188.36c9.21,0,18.42,0,27.63,0,4.77,0,7.96,2.81,7.99,6.98,.03,4.18-3.17,7.07-7.89,7.08-18.5,0-36.99,.01-55.49,0-4.7,0-7.91-2.92-7.87-7.1,.04-4.08,3.22-6.95,7.78-6.96,9.29-.02,18.57,0,27.86,0Z"/>
											<path fill="#3890f0" d="M47.89,146.16c6.87,0,13.73-.01,20.6,0,4.55,.01,7.72,2.9,7.74,6.99,.02,4.1-3.15,7.06-7.67,7.06-13.97,.02-27.93,.02-41.9,0-4.49,0-7.67-3.01-7.62-7.11,.05-4,3.19-6.92,7.55-6.94,7.1-.03,14.2,0,21.3,0Z"/>
											<path fill="#3890f0" d="M54.6,118.03c-4.6,0-9.2,.02-13.8,0-4.52-.02-7.69-2.95-7.69-7.04,0-4.08,3.18-7.01,7.7-7.02,9.28-.02,18.56-.02,27.84,0,4.38,.01,7.51,2.91,7.57,6.91,.06,4.1-3.11,7.11-7.59,7.14-4.68,.03-9.36,0-14.04,0Z"/>
											<path fill="#3890f0" d="M61.8,75.82c-2.49,0-4.99,.05-7.48,0-4.11-.1-7.19-3.18-7.15-7.1,.04-3.81,3.08-6.88,7.06-6.94,4.99-.07,9.97-.08,14.96,0,3.99,.06,7.01,3.14,7.03,6.97,.02,3.83-2.98,6.93-6.95,7.06-2.49,.08-4.99,.02-7.48,.02Z"/>
											<path fill="#3890f0" d="M174.7,132.09c7.2,0,14.07-.06,20.94,.02,5.19,.06,8.56,4.93,6.66,9.58-1.14,2.79-3.33,4.4-6.32,4.43-9.45,.09-18.9,.09-28.36,.01-3.98-.03-6.94-3.04-6.96-7.04-.06-9.37-.07-18.75,0-28.12,.03-3.98,3.19-7.01,7.02-7,3.83,0,6.94,3.03,6.99,7.03,.08,6.09,.02,12.19,.03,18.28,0,.84,0,1.69,0,2.82Z"/>
										</svg>
									</div>
									<div className="content">
										<h3>Começo rápido</h3>
										<p className="paragraph dark">Crie sua conta e já tenha acesso a todos os benefícios.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
									<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
										<path fill="#3890f0" d="M96.79,71.15c-16.75,0-39.1,34.8-39.1,48.09s24.58,29.24,35.24,24.06l.28-.8c.2,.9,.32,1.81,.57,2.68-8.7,8.93-14.07,21.06-14.07,34.42,0,27.74,23.06,50.15,51.39,49.79h7.06v-37.25l-11.85-11.85h50.08l-11.85,11.85v37.28h7.06c28.33,.34,51.42-22.08,51.42-49.82,0-13.35-5.4-25.49-14.09-34.42,.13-.49,.18-.98,.31-1.47,11.37,2.91,33.39-11.86,33.39-24.47,0-13.29-22.34-48.09-39.1-48.09-5.61,0-16.11,3.53-18.06,10.56-9.69-6.62-21.42-10.56-34.11-10.56-13.9,0-26.53,4.85-36.68,12.67l.46-1.26c-1.28-7.62-12.51-11.41-18.34-11.41Z"/>
										<path fill="#3890f0" d="M82.09,58.8c-.17,.55-.28,1.14-.52,1.66-1.21,2.65-3.3,4.02-6.21,4.03-7.71,.02-15.42,0-23.13,0h-.92c0,.36,0,.64,0,.93,0,7.73,.01,15.47,0,23.2,0,3.64-2.77,6.54-6.29,6.65-3.77,.13-6.82-2.8-6.83-6.62-.02-7.73,0-15.47,0-23.2,0-.29,0-.58,0-.96h-.89c-7.73,0-15.47,0-23.2,0-3.65,0-6.56-2.75-6.69-6.25-.14-3.78,2.77-6.85,6.58-6.87,5.59-.03,11.19,0,16.78,0,2.21,0,4.43,0,6.64,0h.78v-.93c0-7.54,0-15.08,0-22.62,0-3.95,1.73-6.17,5.56-7.17,.05-.01,.08-.05,.13-.07h1.75s.08,.06,.13,.07c3.81,.97,5.56,3.23,5.56,7.17,0,7.54,0,15.08,0,22.62v.93h.92c7.71,0,15.42,0,23.13,0,2.39,0,4.32,.95,5.61,2.95,.52,.82,.76,1.82,1.12,2.74v1.75Z"/>
									</svg>
									</div>
									<div className="content">
										<h3>Adicione Pups</h3>
										<p className="paragraph dark">Adicione todos os seus pups de forma rápida e fácil.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
										<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
											<path fill="#3890f0" d="M213.77,61.1c-7.61-7.6-18.22-11.13-28.7-9.78,1.35-10.48-2.18-21.09-9.78-28.7-6.49-6.49-15.12-10.06-24.32-10.06s-17.83,3.57-24.32,10.06c-6.88,6.88-10.54,16.4-10.03,26.11,.44,8.4,3.95,16.32,9.77,22.25L61.96,135.4c-5.93-5.82-13.84-9.33-22.24-9.77-9.71-.51-19.23,3.15-26.12,10.03C.19,149.07,.19,170.89,13.6,184.3c7.61,7.6,18.22,11.13,28.7,9.78-1.35,10.48,2.18,21.09,9.78,28.7,6.7,6.71,15.51,10.06,24.32,10.06s17.61-3.35,24.32-10.06c6.89-6.89,10.54-16.4,10.03-26.11-.44-8.4-3.96-16.32-9.77-22.25l64.43-64.43c5.93,5.82,13.85,9.33,22.25,9.77,9.69,.51,19.23-3.15,26.11-10.03,13.41-13.41,13.41-35.23,0-48.64ZM83.08,175.94c.28,2.22,1.54,4.14,3.47,5.28,5.4,3.18,8.94,8.54,9.72,14.71,.79,6.2-1.29,12.29-5.71,16.7-7.81,7.81-20.52,7.81-28.34,0-7.81-7.81-7.81-20.52,0-28.33,2.8-2.8,2.8-7.35,0-10.15-1.4-1.4-3.24-2.1-5.07-2.1s-3.68,.7-5.08,2.1c-7.81,7.81-20.52,7.81-28.33,0-7.81-7.81-7.81-20.52,0-28.34,4.42-4.42,10.5-6.49,16.69-5.71,6.17,.78,11.53,4.33,14.72,9.73,1.14,1.92,3.06,3.19,5.28,3.47,2.21,.28,4.4-.47,5.97-2.05l75.83-75.82c1.58-1.58,2.32-3.76,2.04-5.98-.28-2.22-1.54-4.14-3.47-5.28-5.4-3.18-8.94-8.54-9.72-14.71-.79-6.2,1.29-12.28,5.71-16.7,7.81-7.81,20.52-7.81,28.33,0,7.81,7.81,7.81,20.52,0,28.33-2.8,2.8-2.8,7.35,0,10.15,2.8,2.8,7.35,2.8,10.15,0,3.78-3.78,8.81-5.86,14.16-5.86s10.39,2.08,14.17,5.86c7.81,7.81,7.81,20.52,0,28.34-4.42,4.42-10.51,6.5-16.72,5.71-6.15-.78-11.51-4.33-14.69-9.72-1.14-1.93-3.07-3.2-5.3-3.48-2.21-.27-4.39,.47-5.96,2.05l-75.83,75.82c-1.58,1.58-2.33,3.76-2.05,5.98Z"/>
											<path fill="#3890f0" d="M246.46,173.42c-.24,.89-.46,1.79-.73,2.67-.74,2.38-2.11,4.37-3.86,6.12-9.25,9.25-18.47,18.53-27.79,27.71-1.38,1.36-3.12,2.52-4.91,3.25-3.26,1.32-6.64-.09-7.98-3.34-.76-1.82-1.05-3.91-1.17-5.9-.19-2.95-.05-5.92-.05-9.08-1.68,.1-3.29,.15-4.88,.31-23.8,2.36-43.97,12.16-60.36,29.61-2.19,2.33-4.17,4.88-6.2,7.35-1.31,1.59-2.74,2.16-4.35,1.63-1.65-.55-2.57-1.93-2.51-4.01,.87-30.2,14.13-53.42,39.57-69.58,11.27-7.16,23.79-10.83,37.12-11.67,.36-.02,.73-.06,1.09-.1,.12-.01,.24-.06,.52-.13,0-.68-.01-1.4,0-2.11,.05-3.21-.05-6.43,.22-9.62,.26-3.07,1.61-5.65,4.79-6.73,2.09-.72,4.13-.3,5.95,.85,1.16,.73,2.26,1.6,3.23,2.56,9.27,9.23,18.51,18.49,27.77,27.73,2.34,2.34,3.87,5.08,4.35,8.38,.02,.15,.13,.29,.19,.43v3.66Z"/>
										</svg>
									</div>
									<div className="content">
										<h3>Compartilhe</h3>
										<p className="paragraph dark">Interaja com seus amigos através de comentários e chat em tempo real.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
										<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
											<path fill="#3890f0" d="M209.19,59.52C187.31,9.54,129.07-10.72,81.01,15.04c-26.02,13.95-41.75,35.11-47.13,62.33-5.37,27.18,1.62,55.35,18.28,77.48,18.24,24.23,36.35,48.57,54.49,72.87,3.83,5.13,7.6,10.31,11.39,15.48,1.37,1.87,3.54,2.97,5.85,2.97h1.2c2.28,0,4.43-1.07,5.8-2.9,9.59-12.78,48.63-64.61,68.43-92.15,20.4-28.36,23.88-59.58,9.87-91.59Zm-74.09-12.37c8.06-1.25,30.91,32.69,5.77,36.65-24.98,3.94-13.98-35.36-5.77-36.65Zm-20.78,0c8.21,1.29,19.16,40.57-5.77,36.65-25.08-3.94-2.34-37.9,5.77-36.65Zm-30.32,20.43c7.22-.97,25.79,28.92,4.05,31.7-21.71,2.79-11.32-30.77-4.05-31.7Zm57.03,82.36c-5.32,0-11.96-4.89-16.31-4.89s-10.93,4.89-16.31,4.89c-5.77,0-24.58-1.54-24.58-21.32,0-21.4,19.49-40.89,40.89-40.89s40.89,19.49,40.89,40.89c0,19.78-18.81,21.32-24.58,21.32Zm20.35-50.67c-21.73-2.77-3.15-32.71,4.05-31.7,2.87,.37,5.19,4.94,6.31,7.63,5.24,12.53,3.36,25.83-10.37,24.07Z"/>
										</svg>
									</div>
									<div className="content">
										<h3>Localize semelhantes</h3>
										<p className="paragraph dark">Use nosso sistema de busca e encontre pups semelhantes ao seu.</p>
										{/* <p className="paragraph dark">Busque novos pups que combinam com o seu com nosso sistema de busca.</p> */}
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
										<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
											<path fill="#3890f0" d="M96.61,5.94c6.01,1.05,12.09,1.77,18,3.23,11.91,2.94,22.5,8.68,31.88,16.52,13.88,11.61,23.36,26.13,28,43.66,4.97,18.74,3.81,37.18-3.75,55.01-2.79,6.58-6.66,12.69-10.2,19.31,1.8,1.85,4.02,4.06,7.15,4.71,1.39,.29,3.17,.15,4.38-.52,2-1.1,3.93-1.27,5.73-.22,2.31,1.35,4.67,2.83,6.52,4.73,18.56,19.03,37.02,38.14,55.49,57.25,5.27,5.45,5.18,11.48-.2,16.73-4.82,4.71-9.67,9.39-14.54,14.06-5.12,4.9-11.28,4.87-16.22-.22-18.78-19.35-37.54-38.72-56.21-58.18-1.72-1.79-2.97-4.13-4.08-6.39-.87-1.77-.59-3.65,.52-5.52,.67-1.13,1.08-2.95,.64-4.11-1.01-2.68-2.6-5.14-3.7-7.22-6.85,3.86-12.98,7.83-19.53,10.88-13.31,6.2-27.44,8.33-42.09,7.07-17.24-1.49-32.61-7.68-46.07-18.46-12.04-9.64-20.85-21.73-26.37-36.16-5.78-15.13-7.13-30.72-4.14-46.59,2.79-14.82,9.15-28.04,18.99-39.5,10.65-12.4,23.7-21.31,39.38-25.99,5.96-1.78,12.24-2.51,18.38-3.71,.75-.15,1.5-.26,2.25-.39,3.26,0,6.51,0,9.77,0Zm54.29,85.65c.08-32.39-26.48-58.98-58.98-59.04-32.44-.06-58.99,26.47-59.01,58.98-.03,32.5,26.42,58.98,58.95,59.02,32.47,.04,58.95-26.4,59.04-58.95Z"/>
											<path fill="#3890f0" d="M134.56,65.01c-3.26-3.26-7.81-4.77-12.31-4.2,.58-4.49-.93-9.05-4.2-12.31-2.78-2.78-6.49-4.31-10.43-4.31s-7.65,1.53-10.43,4.31c-2.95,2.95-4.52,7.03-4.3,11.2,.19,3.6,1.7,7,4.19,9.54l-27.63,27.63c-2.54-2.5-5.94-4-9.54-4.19-4.17-.22-8.25,1.35-11.2,4.3-5.75,5.75-5.75,15.11,0,20.86,3.26,3.26,7.81,4.77,12.31,4.2-.58,4.49,.93,9.05,4.2,12.31,2.88,2.88,6.65,4.31,10.43,4.31s7.55-1.44,10.43-4.31c2.95-2.95,4.52-7.04,4.3-11.2-.19-3.6-1.7-7-4.19-9.54l27.63-27.63c2.54,2.5,5.94,4,9.54,4.19,4.16,.22,8.25-1.35,11.2-4.3,5.75-5.75,5.75-15.11,0-20.86Z"/>
										</svg>
									</div>
									<div className="content">
										<h3>Ache o que precisa</h3>
										<p className="paragraph dark">Descubra novos estabelecimentos perto de você.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="growth__box">
									<div className="icon disp-flex-center">
										<svg className="icon-b-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
											<g>
												<g>
													<circle fill="#3890f0" cx="52.57" cy="201.7" r="6.04" />
													<circle fill="#3890f0" cx="189.39" cy="201.7" r="6.04" />
												</g>
												<g>
													<path fill="#3890f0" d="M221.58,141.34h-.88l-11.31-45.24c-.45-1.79-2.06-3.05-3.9-3.05h-52.31v-16.1c0-2.22-1.8-4.02-4.02-4.02H12.33c-2.22,0-4.02,1.8-4.02,4.02v108.65c0,11.09,9.03,20.12,20.12,20.12h.36c1.92,11.4,11.84,20.12,23.78,20.12s21.86-8.72,23.78-20.12h89.26c1.92,11.4,11.84,20.12,23.78,20.12s21.86-8.72,23.78-20.12h8.41c11.09,0,20.12-9.03,20.12-20.12v-24.14c0-11.09-9.03-20.12-20.12-20.12ZM92.46,105.13c2.95-.46,14.54,15.44,2.72,17.3s-5.68-16.84-2.72-17.3Zm-15.39,0c2.95,.46,9.09,19.16-2.72,17.3s-.23-17.77,2.72-17.3Zm-17.73,12.82c2.51-.32,11.91,13.41,1.88,14.7s-4.39-14.38-1.88-14.7Zm-6.77,99.85c-8.88,0-16.1-7.22-16.1-16.1s7.22-16.1,16.1-16.1,16.1,7.22,16.1,16.1-7.22,16.1-16.1,16.1Zm38.96-62.61c-2.86,0-5.43-1.73-6.77-1.93-1.46,.22-3.64,1.93-6.77,1.93-8.2,0-13.5-4.62-13.5-11.78,0-10.4,9.47-19.53,20.27-19.53s20.27,9.12,20.27,19.53c0,7.15-5.3,11.78-13.5,11.78Zm16.78-22.54c-10.04-1.28-.63-15.02,1.88-14.7,2.51,.32,8.16,15.98-1.88,14.7Zm81.08,85.15c-8.88,0-16.1-7.22-16.1-16.1s7.22-16.1,16.1-16.1,16.1,7.22,16.1,16.1-7.22,16.1-16.1,16.1Zm44.27-32.19c0,6.66-5.42,12.07-12.07,12.07h-8.41c-1.92-11.4-11.84-20.12-23.78-20.12s-21.86,8.72-23.78,20.12h-12.44V101.1h49.17l11.31,45.24c1.07,4.29,6.2,2.76,7.93,3.05,6.66,0,12.07,5.42,12.07,12.07v24.14Z" />
													<path fill="#3890f0" d="M165.24,149.39h35.72c2.62,0,4.54-2.46,3.9-5l-8.05-32.19c-.45-1.79-2.06-3.05-3.9-3.05h-27.67c-2.22,0-4.02,1.8-4.02,4.02v32.19c0,2.22,1.8,4.02,4.02,4.02Z" />
												</g>
											</g>
											<g>
												<path fill="#3890f0" d="M209.34,63.25c-.25,1.45-.43,2.93-.77,4.36-2.74,11.4-12.37,19.69-24.03,20.74-13.75,1.23-26.34-8.15-29.06-21.68-2.8-13.93,5.59-27.67,19.28-31.58,.21-.06,.41-.13,.66-.2-1.44-3.94-.67-7.24,2.88-9.59,2.55-1.69,5.88-1.45,8.34,.4,2.71,2.05,3.45,5.01,2.24,9.17,.7,.22,1.41,.42,2.09,.68,10.58,4.07,16.7,11.69,18.23,22.95,.03,.21,.1,.42,.15,.63v4.12Zm-24.73-24.05c0,.66,0,1.26,0,1.86,0,1.76-.96,2.89-2.45,2.9-1.5,.01-2.49-1.12-2.49-2.86,0-.63,0-1.25,0-1.88-10.65,.94-18.95,10.27-19.54,19.58,.69,0,1.37,0,2.05,0,1.54,.02,2.64,1.04,2.65,2.45,.01,1.42-1.09,2.47-2.61,2.49-.71,.01-1.42,0-2.13,0,.94,10.66,10.28,18.94,19.58,19.54,0-.69,0-1.37,0-2.05,.02-1.54,1.04-2.64,2.45-2.65,1.41-.01,2.47,1.09,2.49,2.61,.01,.71,0,1.42,0,2.13,10.55-.93,18.92-10.17,19.54-19.58-.63,0-1.26,0-1.89,0-1.68,0-2.8-.99-2.81-2.45-.01-1.47,1.12-2.48,2.78-2.49,.65,0,1.31,0,1.97,0-.85-9.76-9.1-18.57-19.58-19.6Zm0-7.63c-.02-1.33-1.15-2.44-2.48-2.44-1.33,0-2.45,1.13-2.46,2.46,0,1.37,1.16,2.51,2.52,2.48,1.33-.03,2.44-1.17,2.42-2.5Z" />
												<path fill="#3890f0" d="M184.62,58.8c2.53,0,4.95-.02,7.36,0,1.83,.02,3.01,1.74,2.34,3.37-.4,.98-1.17,1.55-2.22,1.56-3.32,.03-6.65,.03-9.97,0-1.4-.01-2.44-1.07-2.45-2.48-.02-3.3-.02-6.59,0-9.89,.01-1.4,1.12-2.46,2.47-2.46,1.35,0,2.44,1.07,2.46,2.47,.03,2.14,0,4.28,0,6.43,0,.3,0,.59,0,.99Z" />
											</g>
										</svg>
									</div>
									<div className="content">
										<h3>Agende serviços</h3>
										<p className="paragraph dark">Consulte serviços e marque no horário ideal para você.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="button__wrapper">
								<a href="#home-hero" className="button">
									<span>COMECE AGORA <i className="fad fa-long-arrow-right"></i></span>
								</a>
								{/* <a href="#" className="button">
						<span>LEARN MORE <i className="fad fa-long-arrow-right"></i></span>
					</a> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--growth section end--> */}


			{/* <!--screenshot section start--> */}
			<section className="screenshot" id="preview">
				<div className="screenshot__wrapper">
					<div className="container">
						<div className="screenshot__info">
							<h2 className="section-heading color-black">Você pode conhecer partes do app abaixo:</h2>
							<div className="screenshot-nav">
								<div className="screenshot-nav-prev"><i className="fad fa-long-arrow-left"></i></div>
								<div className="screenshot-nav-next"><i className="fad fa-long-arrow-right"></i></div>
							</div>
						</div>
					</div>
					<div className="swiper-container screenshot-slider">
						<div className="swiper-wrapper">
							<div className="swiper-slide screenshot-slide">
								<img src={registerImg} alt="" />
							</div>
							<div className="swiper-slide screenshot-slide">
								<img src={accountImg} alt="" />
							</div>
							<div className="swiper-slide screenshot-slide">
								<img src={feedImg} alt="" />
							</div>
							<div className="swiper-slide screenshot-slide">
								<img src={notificacoesImg} alt="" />
							</div>
							{/* <div className="swiper-slide screenshot-slide">
								<img src="assets/images/phone-05.png" alt="" />
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!--screenshot section end--> */}


			<div className="divider" id="forStores">
				<div className="divider__line"></div>
				<h2 className="section-heading color-black">Para parceiros</h2>
			</div>


			{/* <!--step section start--> */}
			<section className="step" id="market">
				<div className="step__wrapper">
					<div className="container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<h2 className="section-heading color-black">Cresça seu negócio em poucas etapas.</h2>
						<div className="step__box">
							<div className="image">
								<img src={createAccountManagerImg} alt=" " />
							</div>
							<div className="content">
								<h3>Rápido para<span>Registrar.</span></h3>
								<p className="paragraph dark">Crie, configure sua conta e tenha a verificação em até 24 horas.</p>
							</div>
						</div>
						<div className="step__box">
							<div className="image">
								<img src={addServicesManagerImg} alt=" " />
							</div>
							<div className="content">
								<h3>Ofereça seus<span>Serviços.</span></h3>
								<p className="paragraph dark">Após a verificação, cadastre seus serviços, localização e horário de funcionamento.</p>
							</div>
						</div>
						<div className="step__box">
							<div className="image">
								<img src={financialManagerImg} alt=" " />
							</div>
							<div className="content">
								<h3>Cresça nas<span>Vendas.</span></h3>
								<p className="paragraph dark">Nesse momento fazemos todo o restante, agora é só crescer em número de vendas.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="button__wrapper">
							<Link
								className="button"
								to={routesNames.cadastro.novaConta.path}
							>
								<span>COMECE AGORA <i className="fad fa-long-arrow-right"></i></span>
							</Link>
							<a href="#" className="button">
								<span>LEIA MAIS <i className="fad fa-long-arrow-right"></i></span>
							</a>
						</div>
					</div>
				</div>
			</section>
			{/* <!--step section end--> */}


			{/* <!--questions section start--> */}
			<section className="questions" id="faq">
				<div className="questions__wrapper">
					<div className="container">
						<h2 className="section-heading color-black">Perguntas frequentes.</h2>
						<div className="row align-items-lg-center">
							<div className="col-lg-6 order-2 order-lg-1">
								<div id="accordion">
									<div className="card" id="card-1">
										<div className="card-header" id="heading-1">
											<h5 className="mb-0">
												<button className="btn btn-link" data-toggle="collapse" data-target="#collapse-1" aria-expanded="true"
													aria-controls="collapse-1">
													<i className="fab fa-plus-circle plus-button-icon-faq" />Qual a taxa para começar a vender na plataforma?
												</button>
											</h5>
										</div>

										<div id="collapse-1" className="collapse show" aria-labelledby="heading-1" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Não há nenhuma taxa de inscrição ou cadastro, nossas taxas são descontadas
													em pequenas porcentagens por vendas a depender do tipo de serviço.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-2">
										<div className="card-header" id="heading-2">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-2"
													aria-expanded="false" aria-controls="collapse-2">
													 <i className="fab fa-plus-circle plus-button-icon-faq" />É preciso ser conta PJ para se tornar parceiro?
												</button>
											</h5>
										</div>
										<div id="collapse-2" className="collapse" aria-labelledby="heading-2" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Não é necessário, aceitamos tanto pessoa Física quanto Pessoa Jurídica.
													Entretanto, é preciso que a conta bancária vinculada seja a mesma do responsável.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-3">
										<div className="card-header" id="heading-3">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-3"
													aria-expanded="false" aria-controls="collapse-3">
													<i className="fab fa-plus-circle plus-button-icon-faq" />Só funciono em dias específicos, como faço?
												</button>
											</h5>
										</div>
										<div id="collapse-3" className="collapse" aria-labelledby="heading-3" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Fique tranquilo, você encontrará um painel completo para selecionar
													os dias e horários que irá prestar seus serviços. Além disso, contará com uma agenda única para
													seu estabelecimento com seus serviços já agendados.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-4">
										<div className="card-header" id="heading-4">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-4"
													aria-expanded="false" aria-controls="collapse-4">
													<i className="fab fa-plus-circle plus-button-icon-faq" />Como eu recebo pelas minhas vendas?
												</button>
											</h5>
										</div>
										<div id="collapse-4" className="collapse" aria-labelledby="heading-4" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Após se cadastrar na nossa plataforma, você cadastrará as informações bancárias
													de depósito das vendas. Os depósitos são automáticos e acontecem a cada dois dias após algumas vendas
													serem concluídas.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-5">
										<div className="card-header" id="heading-5">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-5"
													aria-expanded="false" aria-controls="collapse-5">
													<i className="fab fa-plus-circle plus-button-icon-faq" />É preciso seguir algum padrão de normas?
												</button>
											</h5>
										</div>
										<div id="collapse-5" className="collapse" aria-labelledby="heading-5" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Sim! Nós temos nossos termos de uso e políticas de uso da plataforma
													que apresentam informações importantes sobre todas as regras e acordos que devem ser compridos
													ao utilizar o sistema. Você pode encontrar nossos termos de uso clicando aqui.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-6">
										<div className="card-header" id="heading-6">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-6"
													aria-expanded="false" aria-controls="collapse-6">
													<i className="fab fa-plus-circle plus-button-icon-faq" />Eu posso oferecer quantos tipos de serviços?
												</button>
											</h5>
										</div>
										<div id="collapse-6" className="collapse" aria-labelledby="heading-6" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Sim! Desde produtos físicos, até atendimento residencial, nossa plataforma conta
													com mais de 15 tipos de serviços diferentes que seu negócio pode oferecer.</p>
											</div>
										</div>
									</div>
									<div className="card" id="card-7">
										<div className="card-header" id="heading-7">
											<h5 className="mb-0 hidden">
												<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse-7"
													aria-expanded="false" aria-controls="collapse-7">
													<i className="fab fa-plus-circle plus-button-icon-faq" />Como posso controlar meu estoque na plataforma?
												</button>
											</h5>
										</div>
										<div id="collapse-7" className="collapse" aria-labelledby="heading-7" data-parent="#accordion">
											<div className="card-body">
												<p className="paragraph">Antes de ser finalizado o agendamento de um novo serviço, você será notificado dentro
													da agenda da plataforma e terá a opção livre de confirmar o pedido ou recusar, caso não possa realizar o
													atendimento.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 order-1 order-lg-2">
								<div className="questions-img">
									<img src={servicosPerguntasImg} alt=" " />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--questions section end--> */}

 
			{/* <!--footer start--> */}
			<footer className="footer">
				<div className="footer__wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-4">
								<div className="footer__info">
									<div className="footer__info--logo">
										<img src={myPupsLogo} className="myPups-logo-banner" alt="logo" />
									</div> 
									<div className="footer__info--content">
										<p className="paragraph dark">My Pups é uma plataforma completa que te 
										ajuda a encontrar novos Pups, interagir entre eles e a encontrar serviços 
										próximos a você e no horário desejado.</p>
										<div className="social">
											<ul>
												<li className="facebook"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
												<li className="twitter"><a href="#"><i className="fab fa-twitter"></i></a></li>
												<li className="linkedin"><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
												<li className="youtube"><a href="#"><i className="fab fa-youtube"></i></a></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="footer__content-wrapper">
									<div className="footer__list">
										<ul>
											<li>Conheça</li>
											<li><a href="#home-hero">Sobre</a></li>
											<li><a href="#feature">Funcionalidades</a></li>
											<li><a href="#growth">Diferenciais</a></li>
											<li><a href="#preview">Conheça</a></li>
											<li><a href="#market">Parceiros</a></li>
											<li><a href="#faq">FAQ</a></li>
										</ul>
									</div>
									<div className="footer__list">
										<ul>
											<li>Institucional</li>
											<li><a href="#">Sobre a My Pups</a></li>
											<li><a href="#">Quem Somos</a></li>
											<li><a href={routesNames.termos.politicaDePrivacidade.path}>Termos de uso</a></li>
											<li><a href={routesNames.termos.termosDeUso.path}>Políticas de privacidade</a></li>
											<li><a href="#">Contato</a></li>
										</ul>
									</div>
									<div className="footer__list">
										<ul>
											<li>Atendimento</li>
											<li><a href='#'>sac@mypups.com</a></li>
											<li><a href="#">sac-parceiros@mypups.com</a></li>
											<li><a href='#'>sac-usuario@mypups.com</a></li>
										</ul>
									</div>
									<div className="download-buttons">
										<h5>Download</h5>
										<a href="#" className="google-play">
											<i className="fab fa-google-play"></i>
											<div className="button-content">
												<h6>Baixe agora <span>Google Play</span></h6>
											</div>
										</a>
										<a href="#" className="apple-store">
											<i className="fab fa-apple"></i>
											<div className="button-content">
												<h6>Baixe agora <span>Apple Store</span></h6>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="footer__copy">
								<h6>My Pups - Todos os direitos reservados &copy; 2022 - <a href="https://web.mtools.digital/">MTools</a></h6>
							</div>
						</div>
					</div>
				</div>
			</footer>

			{/* <!--footer end--> */}
		</div>
	)
}

<p className="paragraph">
<span>My Pups</span> é uma plataforma completa que te ajuda a encontrar novos Pups, interagir entre eles e a encontrar serviços próximos a você e no horário desejado.
</p>
