import { useAuth } from "hooks/useAuth"
import { Redirect, Route } from "react-router-dom"
import { routesNames } from "./routesNames"


export function PrivateRoute({ component: Component, permissions, ...rest }) {

    const {user} = useAuth()

    if (!Component) return null

    const authState = user && user.id

    const roles = user ? user.roles : []
    
    const wasPermission = permissions ? permissions.some(permission => roles.some((role) => role.includes(permission)) ) : true

    document.querySelector('html').style.fontSize = '100%'

    return (
        <Route
            {...rest}
            render={props =>
                (authState && wasPermission) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: routesNames.entrar.path, state: { from: props.location } }} />
                )
            }
         />
    )
}
