import React, { useState, useEffect } from 'react'
import { Dialog } from 'material-ui'
import { useHistory } from 'react-router'

import { BANK_NAMES_AND_NUMBERS, ESTADOS_DO_BRASIL } from 'config/constants'
import { SidebarNavigator } from 'components/Sidebar'
import { useAuth } from 'hooks/useAuth'
import { firestoreDatabase } from 'services/firebase'
import { Iugu } from 'services/iugu'
import { routesNames } from 'routes/routesNames'

import './styles.scss'

export function Financial() {
    const { user } = useAuth()
    const history = useHistory()

    const [storeData, setStoreData] = useState({
        priceRange: 'Mais que R$ 500,00',
        sellPhysicalProducts: 'null',
        businessDescription: undefined,
        personType: '',
        automaticTransfer: true,
        address: undefined,
        cep: undefined,
        city: undefined,
        neighborhood: undefined,
        state: undefined,
        telephone: undefined,
    })
    const [individualPerson, setIndividualPerson] = useState({
        cpf: undefined,  // ok
        name: undefined,  // ok
    })
    const [legalPerson, setLegalPerson] = useState({
        cnpj: undefined, // ok
        companyName: undefined, // ok
        responsibleName: undefined, // ok
        responsibleCpf: undefined, // ok
    })
    const [bankData, setBankData] = useState({
        bankNumber: undefined, // ok
        name: undefined, // ok
        agency: undefined, // ok
        accountType: '', // ok 'Corrente', 'Poupança'
        accountNumber: undefined, // ok
    })

    const [accountStatus, setAccountStatus] = useState()
    const [iuguStoreData, setIuguStoreData] = useState()
    const [storeName, setStoreName] = useState()

    async function handleSubmit(event) {
        event.preventDefault()
        // console.log('storeData', storeData)
        // console.log('individualPerson', individualPerson)
        // console.log('legalPerson', legalPerson)
        // console.log('bankData', bankData)

        const bankSelected = BANK_NAMES_AND_NUMBERS.find(bankInfo => bankInfo.bankNumber === bankData.bankNumber)

        const stateSelected = ESTADOS_DO_BRASIL.find(stateInfo => stateInfo.sigla === storeData.state)

        const fullStateName = stateSelected.nome

        const sendAccountVerificationRequestData = {
            price_range: storeData.priceRange,
            physical_products: storeData.sellPhysicalProducts === 'true',
            business_type: storeData.businessDescription,
            person_type: storeData.personType,
            automatic_transfer: storeData.automaticTransfer,
            cnpj: legalPerson.cnpj,
            cpf: individualPerson.cpf,
            company_name: legalPerson.companyName,
            name: individualPerson.name,
            address: storeData.street,
            cep: storeData.cep,
            city: storeData.city,
            district: storeData.neighborhood,
            state: fullStateName,
            telephone: storeData.telephone,
            resp_name: legalPerson.responsibleName,
            resp_cpf: legalPerson.responsibleCpf,
            bank: bankSelected.bankName,
            bank_ag: bankData.agency,
            account_type: bankData.accountType,
            bank_cc: bankData.accountNumber, 
        }

        try {
            await Iugu.sendAccountVerificationRequest(
                iuguStoreData.account_id, 
                iuguStoreData.user_token, 
                sendAccountVerificationRequestData
            )
        } catch (error) {
            console.log('Error!', error, error.message)
            alert(`
                Tivemos um erro inesperado ao verificar sua conta!
                \nCaso o erro persista entre em contato com o suporte. \n\n ${JSON.stringify(error)}
            `)
            return
        }

        const addBankAddressData = {
            agency: bankData.agency,
            account: bankData.accountNumber,
            account_type: bankData.accountType === 'Corrente' ? 'cc' : 'cp',
            bank: bankSelected.bankNumber
        }

        try {
            await Iugu.addBankAddress(iuguStoreData.user_token, addBankAddressData)
        } catch (error) {
            console.log('Error!', error, error.message)
            alert(`
                Tivemos um erro inesperado ao verificar seu dados bancários!
                \nCaso o erro persista entre em contato com o suporte. \n\n ${JSON.stringify(error)}
            `)
            return
        }

        try {
            await firestoreDatabase.collection('store').doc(user.storeId).update({
                personType: storeData.personType,
                physicalProducts: storeData.sellPhysicalProducts === 'true',
                businessType: storeData.businessDescription,
                telephone: storeData.telephone,
                status: 'bank-account-in-verification', 
                'statusInfos.bank-account-in-verification-date': new Date().getTime()
            })

            setAccountStatus('bank-account-in-verification')

        } catch (error) {
            console.log('Error!', error, error.message)
            alert(`
                Tivemos um erro inesperado ao conectar-se ao servidor!
                \nCaso o erro persista entre em contato com o suporte. \n\n ${JSON.stringify(error)}
            `)
            return
        }

        alert('Dados enviados para análise com sucesso! Volte dentro de 1 dia útil para checar a confirmação e iniciar suas vendas.')
    }

    useEffect(() => {
        firestoreDatabase.collection('store').doc(user.storeId).get().then(storeResponse => {
            const storeData = storeResponse.data()

            setAccountStatus(storeData.status)

            setStoreName(storeData.name)

            const address = {
                cep: storeData.cep,
                state: storeData.state,
                city: storeData.city,
                neighborhood: storeData.neighborhood,
                street: storeData.street,
            }

            setStoreData(old => ({...old, ...address}))

            setStoreData(old => ({
                ...old, 
                sellPhysicalProducts: storeData.physicalProducts,
                businessDescription: storeData.businessType,
                telephone: storeData.telephone,
                personType: storeData.personType,
            }))

            setIuguStoreData(storeData.iugu)
        })
    }, [user.storeId])


    useEffect(() => {
        if (!storeName) return
        if (!iuguStoreData) return
        if (accountStatus !== 'verified-bank-account') return

        const user_token = iuguStoreData.user_token
        const live_token = iuguStoreData.user_token

        if (!user_token) return
        if (!live_token) return
        
        async function activateCreditCardAndPixMethods() {
            // habilitar método de cartão de crédito e PIX
            await Iugu.configureAccountToUseCreditCardMethodForPurchase(user_token, storeName)
            await Iugu.addPIXMethodForPurchases(live_token)
        }
        
        activateCreditCardAndPixMethods()
    }, [accountStatus, iuguStoreData, storeName])


    async function navigateToServices() {
        setAccountStatus(null)
        history.push(routesNames.estabelecimento.servicos.path)
    }


    return (
        <div style={{ width: '100%' }}>
            <div id="page-bank">
                <main onSubmit={handleSubmit}>
                    <form className="bank-form">
                        <fieldset>
                            <legend>
                                <h2>Dados pessoais</h2>
                                <span>
                                    <span style={{ color: 'red'}}>Importante! </span> 
                                    O titular da conta bancária para transferência dos valores deve ser o mesmo titular do CPF ou
                                    CNPJ do negócio/estabelecimento.  <br />
                                    <span style={{ textDecoration: 'underline' }}>
                                        Não é possível transferências para titulares diferentes.
                                    </span> 
                                </span>
                            </legend>

                            <div className="input-block">
                                <label htmlFor="personType">Você é:</label>
                                <select
                                    id="personType"
                                    name="personType"
                                    onChange={(e) => setStoreData(old => ({...old, personType: e.target.value}))}
                                >
                                    <option value="null" disabled selected={!storeData.personType}>Selecione</option>
                                    <option value="Pessoa Física" selected={storeData.personType === 'Pessoa Física'}>Pessoa Física</option>
                                    <option value="Pessoa Jurídica" selected={storeData.personType === 'Pessoa Jurídica'}>Pessoa Jurídica</option>
                                </select>
                            </div>
                            
                            <div className="input-block">
                                <label htmlFor="sellPhysicalProducts">Você vende produtos físicos?</label>
                                <select
                                    id="sellPhysicalProducts"
                                    name="sellPhysicalProducts"
                                    onChange={(e) => setStoreData(old => ({...old, sellPhysicalProducts: e.target.value}))}
                                >
                                    <option value="null" disabled selected={!storeData.sellPhysicalProducts || storeData.sellPhysicalProducts === 'null'}>Selecione</option>
                                    <option value={true} selected={storeData.sellPhysicalProducts === true}>Sim</option>
                                    <option value={false} selected={storeData.sellPhysicalProducts === false}>Não</option>
                                </select>
                            </div>

                            <div className="input-block">
                                <label htmlFor="businessDescription">Descrição do seu negócio?</label>
                                <textarea
                                    id="businessDescription"
                                    name="businessDescription"
                                    value={storeData.businessDescription}
                                    onChange={(e) => setStoreData(old => ({...old, businessDescription: e.target.value}))}
                                />
                            </div>

                            <div className="input-block">
                                <label htmlFor="telephone">Telefone para contato com DDD:</label>
                                <input
                                    required
                                    type="tel"
                                    aria-autocomplete='list'
                                    autoComplete='tel'
                                    name="telephone"
                                    id="telephone"
                                    value={storeData.telephone}
                                    onChange={(e) => setStoreData(old => ({...old, telephone: e.target.value}))}
                                />
                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>
                                <h2>Confirmação de endereço:</h2>
                            </legend>

                            <div className="input-block">
                                <label htmlFor="instructions">CEP</label>
                                <input id="opening_hours" readOnly value={storeData.cep} contentEditable={false} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="Estado">Estado</label>
                                <input id="Estado" readOnly value={storeData.state} contentEditable={false} />
                            </div>
                            
                            <div className="input-block">
                                <label htmlFor="Cidade">Cidade</label>
                                <input id="Cidade" readOnly value={storeData.city} contentEditable={false} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="Bairro">Bairro</label>
                                <input id="Bairro" readOnly value={storeData.neighborhood} contentEditable={false} />
                            </div>

                            <div className="input-block">
                                <label htmlFor="Logradouro">Logradouro</label>
                                <input id="Logradouro" readOnly value={storeData.street} contentEditable={false} />
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>
                                <h2>Dados bancários:</h2>
                            </legend>

                            {storeData.personType === 'Pessoa Jurídica' && (
                                <>
                                    <div className="input-block">
                                        <label htmlFor="legalPerson-responsibleName">Nome do responsável pelo negócio e titular da conta bancária:</label>
                                        <input
                                            required
                                            id="legalPerson-responsibleName"
                                            value={legalPerson.responsibleName}
                                            onChange={e => setLegalPerson(old => ({...old, responsibleName: e.target.value}))}
                                        />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="legalPerson-responsibleCpf">CPF do responsável pelo negócio e titular da conta bancária:</label>
                                        <input
                                            required
                                            inputMode="numeric"
                                            minLength="11"
                                            maxLength="11"
                                            pattern="[0-9]+"
                                            id="legalPerson-responsibleCpf"
                                            value={legalPerson.responsibleCpf}
                                            onChange={e => setLegalPerson(old => ({...old, responsibleCpf: e.target.value}))}
                                        />
                                    </div>
                                    
                                    <div className="input-block">
                                        <label htmlFor="cnpj">CNPJ do titular da conta</label>
                                        <input
                                            required
                                            id="cnpj"
                                            name="cnpj"
                                            inputMode="numeric"
                                            pattern="[0-9]+"
                                            value={legalPerson.cnpj}
                                            onChange={e => setLegalPerson(old => ({...old, cnpj: e.target.value}))}
                                        />
                                    </div>
                                    
                                    <div className="input-block">
                                        <label htmlFor="companyName">Nome da empresa</label>
                                        <input
                                            required
                                            id="companyName"
                                            autoComplete='organization'
                                            name='organization'
                                            value={legalPerson.companyName}
                                            onChange={e => setLegalPerson(old => ({...old, companyName: e.target.value}))}
                                        />
                                    </div>
                                </>
                            )}
                            
                            {storeData.personType === 'Pessoa Física' && (
                                <>
                                    <div className="input-block">
                                        <label htmlFor="individualPerson-name">Nome do responsável pelo negócio e titular da conta bancária:</label>
                                        <input
                                            required
                                            id="individualPerson-name"
                                            value={individualPerson.name}
                                            onChange={e => setIndividualPerson(old => ({...old, name: e.target.value}))}
                                        />
                                    </div>

                                    <div className="input-block">
                                        <label htmlFor="individualPerson-cpf">CPF do responsável pelo negócio e titular da conta bancária:</label>
                                        <input
                                            required
                                            inputMode="numeric"
                                            minLength="11"
                                            maxLength="11"
                                            pattern="[0-9]+"
                                            id="individualPerson-cpf"
                                            value={individualPerson.cpf}
                                            onChange={e => setIndividualPerson(old => ({...old, cpf: e.target.value}))}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="input-block">
                                <label htmlFor="bankName">Banco:</label>
                                <select
                                    id="bankName"
                                    name="bankName"
                                    onChange={(e) => setBankData(old => ({...old, bankNumber: e.target.value}))}
                                >
                                    <option value="null" disabled selected>Selecione o seu banco</option>
                                    {BANK_NAMES_AND_NUMBERS.map(bankInfo => (
                                        <option value={bankInfo.bankNumber}>{bankInfo.bankNumber + ' - ' + bankInfo.bankName}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="input-block">
                                <label htmlFor="name">Número da conta bancária e dígito (somente números e tracinho):</label>
                                <input
                                    required
                                    minLength="1"
                                    // maxLength="20"
                                    // pattern="[0-9]+"
                                    // pattern="\d+[-]+\d"
                                    id="name"
                                    value={bankData.accountNumber}
                                    onChange={(e) => setBankData(old => ({...old, accountNumber: e.target.value}))}
                                />
                            </div>

                            <div className="input-block">
                                <label htmlFor="bankAgency">Agência da conta bancária e dígito se houver (somente números e tracinho):</label>
                                <input
                                    required
                                    minLength="1"
                                    // maxLength="10"
                                    // pattern="[0-9]+"
                                    // pattern="\d+[-]+\d"
                                    id="bankAgency"
                                    value={bankData.agency}
                                    onChange={(e) => setBankData(old => ({...old, agency: e.target.value}))}
                                />
                            </div>

                            <div className="input-block">
                                <label htmlFor="physicalProducts">Tipo de conta:</label>
                                <select
                                    id="physicalProducts"
                                    name="physicalProducts"
                                    onChange={(e) => setBankData(old => ({...old, accountType: e.target.value}))}
                                >
                                    <option value="null" disabled selected>Selecione seu tipo de conta</option>
                                    <option value='Corrente'>Conta Corrente</option>
                                    <option value='Poupança'>Conta Poupança</option>
                                </select>
                            </div>

                        </fieldset>

                        <button className="confirm-button">
                            Enviar dados para análise
                        </button>
                    </form>
                </main>
            </div>

            <Dialog
				title="Situação cadastral"
				modal={false}
				open={accountStatus === 'bank-account-in-verification' || accountStatus === 'verified-bank-account'}
				autoScrollBodyContent={true}
			>
                {accountStatus === 'bank-account-in-verification' && <BankAccountInVerificationModal />}
                {accountStatus === 'verified-bank-account' && <VerifiedBankAccountModal navigateToServices={navigateToServices}/>}
			</Dialog>
        </div>
    )
}


function BankAccountInVerificationModal() {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <br />
            <h5>Seus dados estão sendo verificados agora por nós.</h5>
            <h5>Dentro de 1 dia útil iremos retornar sobre a validação.</h5>
            <h5>Retorne aqui novamente para acompanhar o progresso de sua análise.</h5>
            <br />
        </div>
    )
}


function VerifiedBankAccountModal({navigateToServices}) {

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <br />
            <h5>Seus dados foram confirmados com sucesso!</h5>
            <h5>Agora você já pode iniciar suas vendas.</h5>

            <br />

            <button
                onClick={navigateToServices} 
                className="button-verified-email"
            >
                Adicionar meus primeiros serviços
            </button>
            <br />
        </div>
    )
}

